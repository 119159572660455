import React, { useState, useEffect } from "react";
import TitlePage from "../../../components/TitlePage";
import { useAccountContext } from "../../../contexts/AccountContext";
import Button from "../../../components/Button";
import { MEDIAS } from "../../../ressources/MEDIAS";
import { COLORS } from "../../../ressources/STYLING";
import Data from "../../../components/Data";
import DataTag from "../../../components/DataTag";
import { CONSTANTS } from "../../../ressources/CONSTANTS";
import ManagePassword from "../../../modules/managers/ManagePassword";
import { privateFetch } from "../../../utils/apiHelper";
import { ProgressSpinner } from "primereact/progressspinner";
import { Dialog } from "primereact/dialog";
import { saveSession } from "../../../utils/sessionHelper";
import { useNavigate } from "react-router-dom";

function AccountPage() {
  const { accountContext } = useAccountContext();
  const navigate = useNavigate();
  const [passwordModuleOpen, setPasswordModuleOpen] = useState(false);
  const [logoutDialogOpen, setLogoutDialogOpen] = useState(false);

  const updatePassword = async (passwordForm) => {
    await privateFetch(
      "PUT",
      `/cu/${accountContext.id}/password/`,
      "Votre mot de passe actuel est erroné. Veuillez réessayer",
      "Le mot de passe a bien été modifié.",
      JSON.stringify(passwordForm)
    ).then((res) => {
      setPasswordModuleOpen(false);
      if (res) {
        setLogoutDialogOpen(true);
        setTimeout(() => {
          saveSession(null);
          navigate("/");
        }, 4000);
      }
    });
  };

  return (
    <div className="page-wrapper">
      {/* Header */}
      <div className="header-container">
        <div className="breadcrumb-container">
          <TitlePage text={"Mon compte"} isActive />
        </div>

        <Button
          text={"Modifier mon mot de passe"}
          bgColor={COLORS.white}
          styleText={"dark"}
          icon={MEDIAS.editorBlack}
          onClick={() => {
            setPasswordModuleOpen(true);
          }}
        />
      </div>

      {/* Content */}
      <div className="content-wrapper content-container bg-white rounded">
        <div className="section-wrapper ">
          <section>
            <h4>Informations</h4>
            <div className="data-section">
              <Data
                key="alias"
                label={"Nom d'affichage"}
                value={accountContext.alias}
                large
              />
              <Data
                key="email"
                label={"Email"}
                value={accountContext.email}
                large
              />
              <DataTag
                key="type"
                label={"Type de compte"}
                value={
                  CONSTANTS.ACCOUNT_TYPES.find(
                    (at) => at.value === accountContext.type
                  ).label
                }
                bgColor={
                  CONSTANTS.ACCOUNT_TYPES.find(
                    (at) => at.value === accountContext.type
                  ).color
                }
              />
              <Data
                key="creationDt"
                label={"Date de création"}
                value={accountContext.creation_date}
                type="date"
              />
            </div>
          </section>
        </div>
      </div>

      <ManagePassword
        isOpen={passwordModuleOpen}
        handleClose={() => {
          setPasswordModuleOpen(false);
        }}
        updatePassword={updatePassword}
      />

      <Dialog
        header="Vous allez être déconnecté..."
        visible={logoutDialogOpen}
        style={{ width: "30vw" }}
        draggable={false}
        closable={false}
      >
        <div className="dialog-container">
          <div style={{ width: 30, marginRight: 15 }}>
            <ProgressSpinner style={{ width: "30px", height: "30px" }} />
          </div>
          <p>Veuillez vous reconnecter avec votre nouveau mot de passe</p>
        </div>
      </Dialog>
    </div>
  );
}

export default AccountPage;
