import React, { useState } from "react";
import { MEDIAS } from "../ressources/MEDIAS";
import "./AppDropdown.scss";

export default function AppDropdown({
  activeScope,
  inactiveScopes,
  handleAppSelection,
}) {
  const [isOpen, setIsOpen] = useState(false);
  if (inactiveScopes) {
    return (
      <div
        className={`app-dropdown ${isOpen && "is-open"}`}
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className={`visible-container ${activeScope.main_color}`}>
          <div className="app" onClick={() => setIsOpen(false)}>
            <img className="logo" src={activeScope.logo} />
            <div className="name">{activeScope.label}</div>
          </div>
          <img className="chevron" src={MEDIAS.chevron} />
        </div>

        {isOpen && (
          <div className="hidden-container">
            {inactiveScopes.map((is) => (
              <div
                key={is.id}
                onClick={() => {
                  setIsOpen(false);
                  handleAppSelection(is);
                }}
                className={`app inactive-app ${is.main_color}`}
              >
                <img className="logo" src={is.logo} />
                <div className="name">{is.label}</div>
              </div>
            ))}
          </div>
        )}
      </div>
    );
  } else {
    return (
      <div className={`app-dropdown`}>
        <div className={`visible-container ${activeScope.main_color}`}>
          <div className="app">
            <img className="logo" src={activeScope.logo} />
            <div className="name">{activeScope.label}</div>
          </div>
        </div>
      </div>
    );
  }
}
