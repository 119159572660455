import React, { createContext, useContext, useState, useEffect } from "react";
import { getNavigation, saveNavigation } from "../utils/navigationHelper";

const NavigationContext = createContext(null);

export const NavigationContextProvider = ({ children }) => {
  const [navigationContext, setNavigationContext] = useState(
    getNavigation() || { currentSubapp: "investor" }
  );

  useEffect(() => {
    if (navigationContext) {
      saveNavigation(navigationContext);
    }
  }, [navigationContext]);

  const changeSubapp = (targetSubapp) => {
    setNavigationContext({ ...navigationContext, currentSubapp: targetSubapp });
  };

  const isPreproduction = window.location.href.includes("demo");

  return (
    <NavigationContext.Provider
      value={{
        navigationContext,
        setNavigationContext,
        changeSubapp,
        isPreproduction,
      }}
    >
      {children}
    </NavigationContext.Provider>
  );
};

export const useNavigationContext = () => {
  return useContext(NavigationContext);
};
