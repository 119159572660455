import React, { useState, useEffect, useRef } from "react";
import { Sidebar } from "primereact/sidebar";
import { Button } from "primereact/button";
import { FileUpload } from "primereact/fileupload";
import TitlePage from "../../components/TitlePage";
import FormInput from "../../components/Form/FormInput";
import { CONSTANTS } from "../../ressources/CONSTANTS";

function ManageImage({
  image,
  loading,
  isOpen,
  onCreate,
  onUpdate,
  onDelete,
  onClose,
}) {
  const fileUploadRef = useRef(null);
  const [file, setFile] = useState(undefined);
  const [name, setName] = useState("");
  const [filePreviewUrl, setFilePreviewUrl] = useState(undefined);

  useEffect(() => {
    if (image) {
      setFilePreviewUrl(image.picture);
      setName(image.name);
    } else {
      setFilePreviewUrl(undefined);
      setFile(undefined);
      setName("");
    }
  }, [image, isOpen]);

  const onValidate = () => {
    const formData = new FormData();
    formData.append("name", name);
    file && formData.append("picture", file);
    image ? onUpdate(formData) : onCreate(formData);
  };

  const onUpload = (event) => {
    if (event.files.length === 1) {
      const file = event.files[0];
      setFilePreviewUrl(file.objectURL);
      setFile(file);
      setName(file.name);
    }
  };

  const handleRemoveImage = () => {
    setFile(undefined);
    setFilePreviewUrl(undefined);
    setName("");
    fileUploadRef.current.clear();
  };

  const formIsValid = file;

  const footerButtons = (
    <div className="manage-footer">
      {image && (
        <Button
          key={"delete"}
          label={`Supprimer l'image`}
          onClick={onDelete}
          severity="danger"
          outlined
        />
      )}

      <Button
        key={"validate"}
        label={`Valider ${image ? "la modification" : "l'ajout"}`}
        loading={loading}
        disabled={!formIsValid}
        onClick={onValidate}
        severity="success"
      />
    </div>
  );

  return (
    <Sidebar
      style={{ width: 600, paddingLeft: 10, paddingRight: 10 }}
      header={
        <TitlePage
          text={`${image ? `Modifier une image` : "Ajouter une image"}`}
        />
      }
      visible={isOpen}
      maskStyle={{ backgroundColor: "#00000080" }}
      onHide={onClose}
      position="right"
      dismissable
    >
      <div className="sidebar-content-container">
        <div className="form-wrapper">
          <FormInput label={"Image"} required isValid={file}>
            <div className="row-gap">
              {file && (
                <Button
                  key={"delete-img"}
                  label={"Changer d'image"}
                  outlined
                  onClick={handleRemoveImage}
                  severity="warning"
                />
              )}
              <FileUpload
                ref={fileUploadRef}
                auto
                disabled={file}
                mode="basic"
                name="file"
                accept="image/*"
                maxFileSize={CONSTANTS.maxImageSize}
                customUpload
                uploadHandler={onUpload}
                chooseLabel="Depuis mon ordinateur"
              />
            </div>
          </FormInput>

          {filePreviewUrl && (
            <FormInput label={"Prévisualisation"}>
              <img
                src={filePreviewUrl}
                className={`object-cover`}
                alt={"Prévisualisation de l'image"}
              />
            </FormInput>
          )}
        </div>
        {footerButtons}
      </div>
    </Sidebar>
  );
}

export default ManageImage;
