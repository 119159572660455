import React from "react";
import { MEDIAS } from "../ressources/MEDIAS";
import { formatDate } from "../utils/dateHelper";

function ToolboxDocumentItem({ document, onClick }) {
  return (
    <div
      className="flex flex-row gap-x-3 items-center rounded border-2 bg-white min-w-64 p-2 pr-5 hover:shadow cursor-pointer "
      onClick={() => onClick(document)}
    >
      <img src={MEDIAS.document} className={`h-10 w-10 opacity-60`} />
      <div className="flex flex-col gap-y-1">
        <p className="font-medium text-sm text-gray-700">{document.name}</p>
        <span className="text-sm text-gray-500 italic">{`${formatDate(document.last_update)}`}</span>
      </div>
    </div>
  );
}

export default ToolboxDocumentItem;
