import React, { useState } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { MEDIAS } from "../../ressources/MEDIAS";
import { CONSTANTS } from "../../ressources/CONSTANTS";
import { publicFetch } from "../../utils/apiHelper";
import "./SignIn.scss";
import { Message } from "primereact/message";
import { useNavigate } from "react-router-dom";
import { validateEmail } from "../../utils/stringHelper";
import FormInput from "../../components/Form/FormInput";

function ForgottenPassword() {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [mailSent, setMailSent] = useState(false);
  const navigate = useNavigate();

  const handleResetPassord = async () => {
    setLoading(true);
    await publicFetch(
      "PUT",
      "/user/password-reset/",
      "Cette adresse mail n'est pas reconnue, veuillez réessayer",
      JSON.stringify({
        email,
      })
    ).then((response) => {
      if (response) {
        setMailSent(true);
      }
      setLoading(false);
    });
  };

  const emailIsValid = validateEmail(email);

  return (
    <div className="h-screen w-screen bg-slate-700 text-slate-50 relative">
      <img
        src={MEDIAS.signinImage}
        className="absolute object-contain top-0 right-0 h-full"
      />

      <div className="h-full flex flex-col justify-between p-5">
        <div className="flex flex-row gap-5 items-center">
          <img
            src={MEDIAS.generalLogo}
            alt="Logo de Crescendo Imvest"
            className="h-12 w-12"
          />
          <span className=" text-2xl font-semibold">Crescendo Imvest</span>
        </div>

        <div className="flex flex-col gap-y-8 ml-36 w-[500px] mb-8">
          <span className="text-5xl font-semibold mb-8">
            Mot de passe oublié
          </span>

          <FormInput
            label={"Adresse mail"}
            required
            isValid={emailIsValid}
            labelClassName={"text-slate-200"}
          >
            <InputText
              id="email"
              type="text"
              placeholder="Entrez votre adresse email"
              className="w-full mb-3"
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </FormInput>

          <div className="flex flex-col gap-4 w-full">
            {mailSent ? (
              <Message
                severity="success"
                text={`Un mail contenant un lien de réinitialisation du mot de passe vous a été envoyé`}
              />
            ) : (
              <Button
                label="Réinitialiser mon mot de passe"
                className="grow"
                icon="pi pi-lock"
                disabled={!emailIsValid}
                loading={loading}
                onClick={handleResetPassord}
              />
            )}
            <Button
              label="Retour à la connexion"
              severity="secondary"
              loading={loading}
              onClick={() => navigate("/login")}
            />
          </div>
        </div>

        <div className="flex flex-row gap-3 text-slate-300">
          <span>{`Version ${CONSTANTS.VERSION}`}</span>
          <span>-</span>
          <span>
            {`Conçu, développé et maintenu par le `}{" "}
            <a
              href="https://collectifnode.fr"
              target="_blank"
              rel="noreferrer"
              className="text-yellow-500 hover:underline ml-1"
            >
              Collectif Node
            </a>
          </span>
        </div>
      </div>
    </div>
  );
}

export default ForgottenPassword;
