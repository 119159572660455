import React, { createContext, useContext, useState, useEffect } from "react";

const AccountContext = createContext(null);

export const AccountContextProvider = ({ children }) => {
  const [accountContext, setAccountContext] = useState(null);

  const isAdmin = () => accountContext && accountContext.type === "ADMIN";
  const isIntermediate = () =>
    accountContext && accountContext.type === "INTERMEDIATE";

  return (
    <AccountContext.Provider
      value={{ accountContext, setAccountContext, isAdmin, isIntermediate }}
    >
      {children}
    </AccountContext.Provider>
  );
};

export const useAccountContext = () => {
  return useContext(AccountContext);
};
