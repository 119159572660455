import React from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { formatDate } from "../../utils/dateHelper";
import { CONSTANTS } from "../../ressources/CONSTANTS";
import { useAuthorizationsContext } from "../../contexts/AuthorizationsContext";
import Tag from "../../components/Tag";

export const IntermediateAccountDatatable = ({ data, selectItem }) => {
  const { getScopeFromName } = useAuthorizationsContext();
  const authorizationBadyTemplate = (authList) => {
    return (
      <div className="flex gap-x-2">
        {authList.map((a) => (
          <Tag
            key={a.id}
            value={getScopeFromName(a.scope).label}
            bgColor={
              a.type !== "FORBIDDEN" && getScopeFromName(a.scope).main_color
            }
            icon={CONSTANTS.AUTH_ICONS[a.type]}
          />
        ))}
      </div>
    );
  };
  if (!data.length) {
    return <div className="empty-section-message">Aucun compte associé</div>;
  }
  return (
    <DataTable
      value={data}
      emptyMessage={"Pas de compte à afficher"}
      selectionMode="single"
      onRowSelect={(e) => selectItem(e.data)}
    >
      <Column
        className="font-bold"
        field="alias"
        header="Nom & prénom"
      ></Column>
      <Column field="email" header="Email"></Column>
      <Column field="phone" header="Téléphone"></Column>

      <Column
        body={(rowData) => formatDate(rowData.creation_date)}
        field="creation_date"
        header="Date de création"
      ></Column>
      <Column
        className="w-64"
        field="scope"
        header="Autorisations"
        body={(rowData) => authorizationBadyTemplate(rowData.authorizations)}
      ></Column>
    </DataTable>
  );
};
