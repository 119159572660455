import React from "react";
export default function NavIconButton(props) {
  return (
    <div
      onClick={props.onClick}
      className={`nav-icon-container ${
        props.isActive ? "nav-icon-button-active" : "nav-icon-button-inactive"
      }`}
    >
      <img src={props.src} className="nav-icon-img" />
      <div className={"nav-icon-text"}>{props.text}</div>
    </div>
  );
}
