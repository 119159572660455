import React from "react";
import ImageItem from "../../components/Image/ImageItem";
import Data from "../../components/Data";
import DataTag from "../../components/DataTag";
import DocumentList from "../../components/DocumentList";

export const ProjectDetail = ({
  project,
  openDocumentModal,
  openImageModal,
  isReadWrite,
}) => {
  const displayImages = () => {
    if (project && project.cartographies && project.cartographies.length) {
      return (
        <div className="row-gap">
          {project.cartographies.map((image) => (
            <ImageItem
              key={image.id}
              image={image}
              onClick={() => isReadWrite && openImageModal(image)}
            />
          ))}
        </div>
      );
    } else
      return <div style={{ fontStyle: "italic" }}>Aucune image disponible</div>;
  };

  return (
    <div className="section-wrapper">
      <section>
        <h4>Informations</h4>
        <div className="data-section">
          <Data
            key="title"
            label={"Titre public"}
            value={project.title}
            large
          />
          <Data key="reference" label={"Référence"} value={project.reference} />
          <DataTag key="state" label={"Etat"} value={project.state} />
          <DataTag key="type" label={"Type"} value={project.type} />

          <DataTag
            key="decision"
            label={"Décision comité"}
            value={
              project.committee_positive_decision != undefined
                ? project.committee_positive_decision
                  ? "Favorable"
                  : "Défavorable"
                : "-"
            }
          />
          <Data
            key="comment"
            label={"Commentaire comité"}
            value={project.committee_advice}
            multiline
            large
          />
        </div>
      </section>

      <section>
        <h4>Surfaces</h4>
        <div className="data-section">
          <Data
            key="aquiredSurface"
            label={"Surface acquise"}
            value={project.acquired_surface}
            type="hectare"
          />
          <Data
            key="resoldSurface"
            label={"Surface revendue"}
            value={project.resold_surface}
            type="hectare"
          />
          <Data
            key="sieSurface"
            label={"Surface d'Interet Ecologique"}
            value={project.sie_surface}
            type="hectare"
            large
          />
          <Data
            key="regenerationSurface"
            label={"Surface sous contrat Régénération"}
            value={project.regeneration_contract_surface}
            type="hectare"
            large
          />
        </div>
      </section>
      <section>
        <h4>Montants</h4>
        <div className="data-section">
          <Data
            key="totalAmount"
            label={"Montant total"}
            value={project.total_amount}
            type={"currency"}
          />
          <Data
            key="netSellerPrice"
            label={"Prix net vendeur"}
            value={project.net_seller_price}
            type={"currency"}
          />
          <Data
            key="renting"
            label={"Fermage"}
            value={project.renting}
            type={"currency"}
          />
          <Data
            key="propertyTaxRate"
            label={"Taux taxe foncière"}
            value={project.property_tax_rate}
          />

          <Data
            key="cdiFee"
            label={"Frais CDI"}
            value={project.cdi_fees}
            type={"currency"}
          />
          <Data
            key="notaryFee"
            label={"Frais notaire"}
            value={project.notary_fees}
            type={"currency"}
          />
          <Data
            key="ciFee"
            label={"Frais Crescendo Invest"}
            value={project.crescendo_imvest_fees}
            type={"currency"}
          />
          <Data
            key="miscFee"
            label={"Frais autres (géomètre...)"}
            value={project.misc_fees}
            type={"currency"}
          />
        </div>
      </section>

      <section>
        <h4>Dates</h4>
        <div className="data-section">
          <Data
            key="certificationDt"
            label={"Dernière certification"}
            value={project.latest_certification_dt}
            type={"date"}
          />
          <Data
            key="signatureDt"
            label={"Date signature notaire"}
            value={project.notary_signature_dt}
            type={"date"}
          />
        </div>
      </section>

      <section>
        <h4>Autres informations</h4>
        <div className="data-section">
          <Data
            key="publicDescription"
            label={"Description publique"}
            value={project.public_description}
            multiline
          />
          <Data
            key="privateDescription"
            label={"Description privée"}
            value={project.private_description}
            multiline
          />
        </div>
      </section>
      <section>
        <h4>Documents</h4>
        <DocumentList
          handleExistingDocument={openDocumentModal}
          requiredDocuments={[]}
          documents={project && project.documents}
        />
      </section>
      <section>
        <h4>Cartographie</h4>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginTop: "1vh",
            overflowX: "auto",
            scrollbarWidth: "thin",
            marginBottom: "3vh",
          }}
        >
          {displayImages()}
        </div>
      </section>
    </div>
  );
};
