import React, { useState, useEffect } from "react";
import TitlePage from "../../../components/TitlePage";
import { useAccountContext } from "../../../contexts/AccountContext";
import { useAuthorizationsContext } from "../../../contexts/AuthorizationsContext";
import { privateFetch } from "../../../utils/apiHelper";
import Tag from "../../../components/Tag";
import ToolboxDocumentItem from "../../../components/ToolboxDocumentItem";

function ToolboxPage() {
  const { authorizationsContext, getActiveScopesNames } =
    useAuthorizationsContext();
  const { accountContext } = useAccountContext();
  const [documentList, setDocumentList] = useState([]);

  useEffect(() => {
    fetchToolboxDocumentList();
  }, [authorizationsContext]);

  const fetchToolboxDocumentList = async () => {
    await privateFetch(
      "GET",
      `/cu/${accountContext.id}/toolbox/?scopes=${getActiveScopesNames()}`
    ).then((res) => {
      if (res) {
        setDocumentList(res);
      }
    });
  };

  const handleDocument = (document) => {
    window.open(document.file, "_blank");
  };

  return (
    <div className="page-wrapper">
      {/* Header */}
      <div className="header-container">
        <div className="breadcrumb-container">
          <TitlePage text={"Boîte à outils"} isActive />
        </div>
      </div>

      {/* Content */}
      <div className="overflow-y-scroll flex flex-col gap-y-5 h-full">
        {documentList.map((dl) => (
          <div
            key={dl.key}
            className="flex flex-col gap-y-5 bg-white rounded p-5"
          >
            {documentList.length > 1 && (
              <Tag value={`Documents ${dl.label}`} bgColor={dl.color}></Tag>
            )}
            <div className="flex flex-col gap-y-5">
              <section>
                <h4>Bibliothèque de documents</h4>
                {dl.library_documents.length ? (
                  <div className="flex flex-row flex-wrap gap-x-5 gap-y-5">
                    {dl.library_documents.map((d, i) => (
                      <ToolboxDocumentItem
                        key={i}
                        document={d}
                        onClick={handleDocument}
                      />
                    ))}
                  </div>
                ) : (
                  <span className="empty-section-message">
                    Aucun document à afficher...
                  </span>
                )}
              </section>

              <section>
                <h4>Parts Sociales</h4>
                {dl.ps_documents.length ? (
                  <div className="flex flex-row flex-wrap gap-x-5 gap-y-5">
                    {dl.ps_documents.map((d, i) => (
                      <ToolboxDocumentItem
                        key={i}
                        document={d}
                        onClick={handleDocument}
                      />
                    ))}
                  </div>
                ) : (
                  <span className="empty-section-message">
                    Aucun document à afficher...
                  </span>
                )}
              </section>

              <section>
                <h4>Titres Participatifs</h4>
                {dl.tp_documents.length ? (
                  <div className="flex flex-row flex-wrap gap-x-5 gap-y-5">
                    {dl.tp_documents.map((d, i) => (
                      <ToolboxDocumentItem
                        key={i}
                        document={d}
                        onClick={handleDocument}
                      />
                    ))}
                  </div>
                ) : (
                  <span className="empty-section-message">
                    Aucun document à afficher...
                  </span>
                )}
              </section>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ToolboxPage;
