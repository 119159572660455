import React from "react";

function Tag({ value, bgColor, icon, onClick }) {
  return (
    <div
      onClick={onClick}
      className={`py-0.5 px-2 rounded flex flex-row items-center gap-x-2 text-sm font-semibold w-fit overflow-hidden  ${
        bgColor || "bg-gray-500"
      } ${onClick ? "cursor-pointer" : ""} text-white`}
    >
      {icon && <img src={icon} className="h-3 w-3" />}
      <p className="whitespace-nowrap text-ellipsis overflow-hidden">
        {" "}
        {value}
      </p>
    </div>
  );
}

export default Tag;
