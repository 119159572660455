import React, { useEffect, useState } from "react";
import { useAuthorizationsContext } from "../../../contexts/AuthorizationsContext";
import { useAccountContext } from "../../../contexts/AccountContext";
import TitlePage from "../../../components/TitlePage";
import KeyIndicatorButton from "../../../components/KeyIndicatorButton";
import DashboardDetail from "../../../modules/dashboard/DashboardDetail";
import { privateFetch } from "../../../utils/apiHelper";
import "../../Pages.scss";
import "../../Dashboard.scss";

function DashboardPage() {
  const { authorizationsContext, getActiveScopesNames } =
    useAuthorizationsContext();
  const { accountContext } = useAccountContext();

  const [indicatorList, setIndicatorList] = useState([]);
  const [indicator, setIndicator] = useState(null);
  const [indicatorData, setIndicatorData] = useState(null);

  useEffect(() => {
    fetchDashboardOverview();
  }, [authorizationsContext]);

  useEffect(() => {
    indicator && fetchSelectedIndicatorData();
  }, [indicator]);

  const fetchDashboardOverview = async () => {
    await privateFetch(
      "GET",
      `/cu/${
        accountContext.id
      }/dashboard/overview/?scopes=${getActiveScopesNames()}`
    ).then((response) => {
      if (response) {
        setIndicatorList(response);
        response.length && setIndicator(response[0]);
      }
    });
  };

  const fetchSelectedIndicatorData = async () => {
    await privateFetch(
      "GET",
      `/cu/${accountContext.id}/dashboard/${
        indicator.endpoint
      }/?scopes=${getActiveScopesNames()}`
    ).then((response) => {
      if (response) setIndicatorData(response);
    });
  };

  return (
    <div className="page-wrapper">
      {/* Header */}
      <div className="header-container">
        <div className="breadcrumb-container">
          <TitlePage
            text={"Dashboard"}
            isActive={true}
            scrollTarget={"LEFT"}
            allowNavigation={false}
            handleNavigation={() => console.log("forbidden")}
          />
        </div>
      </div>
      {/* Content */}
      <div className="indicators-container">
        {indicatorList.map((ki) => (
          <KeyIndicatorButton
            key={ki.id}
            ki={ki}
            // onClick={() => this.handleScrollToIndex(index - 1, ki)}
            onClick={() => setIndicator(ki)}
            isSelected={indicator && ki.id === indicator.id}
            scope="residens"
          />
        ))}
      </div>
      <DashboardDetail indicatorData={indicatorData} />
    </div>
  );
}

export default DashboardPage;

/*
  handleScrollToIndex = (
    targetIndex,
    selectedIndicator = this.state.selectedIndicator
  ) => {
    const { indicatorList } = this.state;
    const boudedTargetIndex = Math.min(
      Math.max(0, targetIndex),
      Math.max(indicatorList.length - 5, 5)
    );
    const targetXOffsetAsVW = DISABLE_HORIZONTAL_SCROLL
      ? 0
      : 13 * boudedTargetIndex;
    this.setState(
      {
        currentXOffsetAsVW: targetXOffsetAsVW,
        currentIndex: targetIndex,
        selectedIndicator,
      },
      () => this.fetchSelectedIndicatorData()
    );
  };
  */
