import React, { useState, useEffect } from "react";
import { Sidebar } from "primereact/sidebar";
import TitlePage from "../../components/TitlePage";
import "./ManageItem.scss";
import { InputNumber } from "primereact/inputnumber";
import { CONSTANTS } from "../../ressources/CONSTANTS";
import { Calendar } from "primereact/calendar";
import { RadioButton } from "primereact/radiobutton";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { generateYearList } from "../../utils/dateHelper";

function ManageReport({
  isOpen,
  handleClose,
  handleValidation,
  loading,
  investor,
  investorList,
}) {
  const [type, setType] = useState(CONSTANTS.GENERATED_DOCUMENT_TYPES[0].value);
  const [selectedInvestor, setSelectedInvestor] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null);

  useEffect(() => {
    setSelectedInvestor(investor);
  }, [investor, isOpen]);

  const onValidate = (toDownload) => {
    console.log(type, selectedYear, selectedInvestor, toDownload);
    handleValidation({
      type,
      selectedYear,
      selectedInvestor,
      toDownload,
    });
  };

  const optionTemplate = (option) => {
    return `${option.first_name} ${option.name}`;
  };

  const valueTemplate = (selectedInvestor) => {
    return selectedInvestor
      ? `${selectedInvestor.first_name} ${selectedInvestor.name}`
      : `Tous les investisseurs (${investorList ? investorList.length : "0"})`;
  };

  const footerButtons = () => {
    return (
      <div className="footer-button-container">
        <Button
          key={"validate"}
          label="Envoyer par email"
          icon="pi pi-envelope"
          onClick={() => onValidate(false)}
          severity="success"
        />
        <Button
          key={"close"}
          label={"Télécharger"}
          icon={"pi pi-file-pdf"}
          disabled={!selectedInvestor}
          onClick={() => onValidate(true)}
          severity="success"
        />
      </div>
    );
  };

  return (
    <Sidebar
      style={{ width: 600, paddingLeft: 10, paddingRight: 10 }}
      header={<TitlePage text={`${"Générer des documents"}`} />}
      visible={isOpen}
      maskStyle={{ backgroundColor: "#00000080" }}
      onHide={() => handleClose()}
      position="right"
    >
      <div className="sidebar-content-container">
        <div className="module-form-container">
          <div className="form-item-container">
            <label>Type</label>
            <div className="checkbox-container">
              {CONSTANTS.GENERATED_DOCUMENT_TYPES.map((dt, i) => (
                <div key={i} className="checkbox-input">
                  <RadioButton
                    inputId={dt.value}
                    name={dt.label}
                    value={dt.value}
                    onChange={(e) => setType(e.target.value)}
                    checked={type === dt.value}
                  />
                  <label htmlFor={dt.value}>{dt.label}</label>
                </div>
              ))}
            </div>
          </div>
          <div className="form-item-container">
            <label htmlFor="amount">{"Investisseur(s) concerné(s)"}</label>
            <Dropdown
              id="seletedInvestor"
              value={selectedInvestor}
              onChange={(e) => setSelectedInvestor(e.value)}
              options={investorList}
              valueTemplate={() => valueTemplate(selectedInvestor)}
              itemTemplate={optionTemplate}
              optionLabel="name"
              filterBy="name,first_name"
              placeholder={`Tous les investisseurs (${
                investorList ? investorList.length : "0"
              })`}
              filter
              showClear
            />
          </div>
          {type === "fiscal" && (
            <div className="form-item-container">
              <label htmlFor="amount">{"Année(s) concernée(s)"}</label>
              <Dropdown
                id="selectedYear"
                value={selectedYear}
                onChange={(e) => {
                  setSelectedYear(e.value);
                  console.log("héééééé", e.value);
                }}
                options={generateYearList(2016)}
                placeholder="Toutes les années"
                showClear
              />
            </div>
          )}
        </div>
        {footerButtons()}
      </div>
    </Sidebar>
  );
}

export default ManageReport;
