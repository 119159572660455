import React, { useState, useEffect } from "react";
import TitlePage from "../../../components/TitlePage";
import Tag from "../../../components/Tag";
import { useAccountContext } from "../../../contexts/AccountContext";
import { useAuthorizationsContext } from "../../../contexts/AuthorizationsContext";
import { privateFetch } from "../../../utils/apiHelper";
import ToolboxDocumentItem from "../../../components/ToolboxDocumentItem";
import ManageDocument from "../../../modules/managers/ManageDocument";
import { confirmDialog } from "primereact/confirmdialog";
import { CONSTANTS } from "../../../ressources/CONSTANTS";
import Button from "../../../components/Button";
import { MEDIAS } from "../../../ressources/MEDIAS";
import { COLORS } from "../../../ressources/STYLING";

function ToolboxPage() {
  const {
    authorizationsContext,
    getActiveScopesNames,
    getActiveScope,
    isOverviewMode,
  } = useAuthorizationsContext();
  const { accountContext } = useAccountContext();

  const [documentList, setDocumentList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [document, setDocument] = useState(null);
  const [documentModuleOpen, setDocumentModuleOpen] = useState(false);

  useEffect(() => {
    fetchToolboxDocumentList();
  }, [authorizationsContext]);

  const isReadWrite = () => {
    if (isOverviewMode()) return false;
    return true;
  };

  const fetchToolboxDocumentList = async () => {
    await privateFetch(
      "GET",
      `/cu/${accountContext.id}/toolbox/?scopes=${getActiveScopesNames()}`
    ).then((res) => {
      if (res) {
        setDocumentList(res);
      }
    });
  };

  const uploadDocument = async (documentForm) => {
    setLoading(true);
    documentForm.append("linked_scope", getActiveScope().id);
    await privateFetch(
      "POST",
      `/cu/${accountContext.id}/toolbox/`,
      "Une erreur est survenue lors de l'ajout de document.",
      "Le document a bien été ajoutée.",
      documentForm,
      false,
      true
    ).then(() => {
      setLoading(false);
      setDocumentModuleOpen(false);
      fetchToolboxDocumentList();
    });
  };

  const updateDocument = async (document, documentForm) => {
    setLoading(true);
    await privateFetch(
      "PUT",
      `/cu/${accountContext.id}/document/${document.id}/`,
      "Une erreur est survenue lors de la modification du document.",
      "Le document a bien été modifié.",
      documentForm,
      false,
      true
    ).then(() => {
      setLoading(false);
      setDocumentModuleOpen(false);
      fetchToolboxDocumentList();
    });
  };

  const deleteDocument = async (document) => {
    setLoading(true);
    await privateFetch(
      "DELETE",
      `/cu/${accountContext.id}/document/${document.id}/`,
      "Une erreur est survenue lors de la suppression du document.",
      "Le document a bien été supprimé."
    ).then(() => {
      setLoading(false);
      setDocumentModuleOpen(false);
      fetchToolboxDocumentList();
    });
  };

  const handleDocument = (document) => {
    setDocument(document);
    setDocumentModuleOpen(document);
  };

  const confirmDocumentDeletion = (document) => {
    setDocumentModuleOpen(false);
    confirmDialog({
      message: `Êtes-vous sûr de vouloir supprimer le document ${document.name} ?`,
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      acceptLabel: "Oui",
      rejectLabel: "Annuler",
      accept: () => deleteDocument(document),
    });
  };

  return (
    <div className="page-wrapper">
      {/* Header */}
      <div className="header-container">
        <div className="breadcrumb-container">
          <TitlePage text={"Boîte à outils"} isActive />
        </div>
        {isReadWrite() && (
          <Button
            text={"Document"}
            bgColor={COLORS.greenButton}
            styleText={"light"}
            icon={MEDIAS.crossAdd}
            onClick={() => {
              setDocument(undefined);
              setDocumentModuleOpen(true);
            }}
          />
        )}
      </div>

      {/* Content */}
      <div className="overflow-y-scroll flex flex-col gap-y-5 h-full">
        {documentList.map((dl) => (
          <div
            key={dl.key}
            className="flex flex-col gap-y-5 bg-white rounded p-5"
          >
            {documentList.length > 1 && (
              <Tag value={`Documents ${dl.label}`} bgColor={dl.color}></Tag>
            )}
            <div className="flex flex-col gap-y-5">
              <section>
                <h4>Bibliothèque de documents</h4>
                {dl.library_documents.length ? (
                  <div className="flex flex-row flex-wrap gap-x-5 gap-y-5">
                    {dl.library_documents.map((d, i) => (
                      <ToolboxDocumentItem
                        key={i}
                        document={d}
                        onClick={handleDocument}
                      />
                    ))}
                  </div>
                ) : (
                  <span className="empty-section-message">
                    Aucun document à afficher...
                  </span>
                )}
              </section>

              <section>
                <h4>Parts Sociales</h4>
                {dl.ps_documents.length ? (
                  <div className="flex flex-row flex-wrap gap-x-5 gap-y-5">
                    {dl.ps_documents.map((d, i) => (
                      <ToolboxDocumentItem
                        key={i}
                        document={d}
                        onClick={handleDocument}
                      />
                    ))}
                  </div>
                ) : (
                  <span className="empty-section-message">
                    Aucun document à afficher...
                  </span>
                )}
              </section>

              <section>
                <h4>Titres Participatifs</h4>
                {dl.tp_documents.length ? (
                  <div className="flex flex-row flex-wrap gap-x-5 gap-y-5">
                    {dl.tp_documents.map((d, i) => (
                      <ToolboxDocumentItem
                        key={i}
                        document={d}
                        onClick={handleDocument}
                      />
                    ))}
                  </div>
                ) : (
                  <span className="empty-section-message">
                    Aucun document à afficher...
                  </span>
                )}
              </section>
            </div>
          </div>
        ))}
      </div>

      <ManageDocument
        loading={loading}
        document={document}
        options={CONSTANTS.TOOLBOX_DOCUMENTS}
        defaultType="toolbox_library"
        isOpen={documentModuleOpen}
        handleClose={() => setDocumentModuleOpen(false)}
        uploadDocument={uploadDocument}
        updateDocument={(documentForm) =>
          updateDocument(document, documentForm)
        }
        deleteDocument={() => confirmDocumentDeletion(document)}
        isReadWrite={isReadWrite()}
      />
    </div>
  );
}

export default ToolboxPage;
