import React, { useState } from "react";
import moment from "moment";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { FilterMatchMode } from "primereact/api";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import {
  applyAreaFormat,
  applyCurrencyFormat,
  applyDateFormat,
} from "../../utils/datatableHelper";
import { CONSTANTS } from "../../ressources/CONSTANTS";
import Tag from "../../components/Tag";

export const BeneficiaryDatatable = ({
  loading,
  data,
  selectItem,
  exportRef,
}) => {
  const [filters, setFilters] = useState(null);

  const projectBodyTemplate = (rowData) => {
    if (!rowData.project)
      return <span style={{ fontStyle: "italic" }}>{"Aucun"}</span>;
    const currentState = CONSTANTS.PROJECT_STATES.find(
      (ps) => ps.label === rowData.project.state
    );
    return (
      <div className="flex gap-x-2">
        <Tag value={rowData.project.reference} />
        <Tag value={currentState.label} bgColor={currentState.color} />
      </div>
    );
  };

  const footerGroup = (
    <ColumnGroup>
      <Row>
        <Column footer="Totaux" />
        <Column footer={`${data.total_beneficiary} bénéficiaires`} />
        <Column footer={`(${data.total_project} projets)`} />
        <Column footer={`${applyAreaFormat(data.total_surface)}`} />
        <Column footer={`${applyCurrencyFormat(data.total_project_amount)}`} />
        <Column />
        <Column />
      </Row>
    </ColumnGroup>
  );

  return (
    <DataTable
      ref={exportRef}
      value={data.beneficiary_list}
      removableSort
      emptyMessage={"Pas de bénéficiaire à afficher"}
      scrollable={true}
      loading={loading}
      paginator
      rows={25}
      rowsPerPageOptions={[25, 50, 100]}
      filters={filters}
      onFilter={(e) => setFilters(e.filters)}
      filterDisplay="row"
      selectionMode="single"
      onRowSelect={(e) => selectItem(e.data)}
      exportFilename={`export_beneficiaire_${moment().format("DD/MM/YYYY")}`}
      csvSeparator=";"
      footerColumnGroup={footerGroup}
    >
      <Column
        sortable
        className="font-bold"
        field="name"
        header="Nom"
        filter
        filterMatchMode={FilterMatchMode.CONTAINS}
        showFilterMenu={false}
      ></Column>
      <Column
        sortable
        className="font-bold"
        field="first_name"
        header="Prénom"
        filter
        filterMatchMode={FilterMatchMode.CONTAINS}
        showFilterMenu={false}
      ></Column>
      <Column
        field="project"
        header="Projet"
        body={projectBodyTemplate}
      ></Column>
      <Column
        dataType="numeric"
        sortable
        field="total_surface"
        header="Surface totale"
        body={(rowData) => applyAreaFormat(rowData.total_surface)}
      ></Column>
      <Column sortable field="city" header="Ville"></Column>
      <Column sortable field="region" header="Région"></Column>
      <Column
        sortable
        field="creation_date"
        header="Date de création"
        body={(rowData) => applyDateFormat(rowData.creation_date)}
      ></Column>
    </DataTable>
  );
};
