import React from "react";
import "./Image.scss";

function ImageItem({ image, onClick }) {
  return (
    <img
      onClick={onClick}
      src={image.picture}
      className="image-container"
    ></img>
  );
}

export default ImageItem;
