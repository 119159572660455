import React, { useEffect, useState } from "react";
import { DIMENSIONS } from "../../../ressources/STYLING";
import { MEDIAS } from "../../../ressources/MEDIAS";
import { CONSTANTS } from "../../../ressources/CONSTANTS";
import { useNavigate } from "react-router-dom";
import InvestorsPage from "../common/InvestorsPage";
import IntermediatePage from "./IntermediatePage";
import DashboardPage from "./DashboardPage";
import ResidencePage from "./ResidencePage";
import ProjectPage from "./ProjectPage";
import UserPage from "./UserPage";
import ToolboxPage from "./ToolboxPage";
import AccountPage from "../common/AccountPage";
import ArchivePage from "../intermediate/ArchivePage";
import NavIconButton from "../../../components/NavIconButton";
import AppDropdown from "../../../components/AppDropdown";
import { useAuthorizationsContext } from "../../../contexts/AuthorizationsContext";
import { useNavigationContext } from "../../../contexts/NavigationContext";
import { useAccountContext } from "../../../contexts/AccountContext";
import { saveSession } from "../../../utils/sessionHelper";

import "../../Home.scss";
import "../../Pages.scss";
import "../../Datatables.scss";
import Tag from "../../../components/Tag";

function InternalRouter() {
  const {
    authorizationsContext,
    getAuthorizedScopeList,
    isOverviewMode,
    getSubappAuthorizationType,
    getActiveScope,
    getActiveScopesNames,
    selectScope,
    selectAllScopes,
  } = useAuthorizationsContext();
  const { navigationContext, changeSubapp, isPreproduction } =
    useNavigationContext();
  const { isAdmin, isIntermediate } = useAccountContext();
  const navigate = useNavigate();
  const [activeScope, setActiveScope] = useState(null);
  const [inactiveScopes, setInactiveScopes] = useState(null);

  useEffect(() => {
    computeActiveScopes();
  }, [authorizationsContext]);

  const computeActiveScopes = () => {
    const authorizedScopeList = getAuthorizedScopeList();
    // User has more than one scope
    if (authorizedScopeList.length > 1) {
      const generalScope = {
        id: 0,
        label: "Général",
        logo: MEDIAS.generalLogo,
        main_color: "bg-custom-blue",
      };
      const allScopes = [generalScope, ...authorizedScopeList];
      const activeScope = !authorizedScopeList.some((scope) => !scope.isActive)
        ? generalScope
        : authorizedScopeList.find((scope) => scope.isActive);
      setActiveScope(activeScope);
      setInactiveScopes(allScopes.filter((s) => s !== activeScope));
    } else {
      setActiveScope(authorizedScopeList[0]);
      setInactiveScopes(null);
    }
  };

  const handleLogout = () => {
    saveSession(null);
    navigate("/");
  };

  const handleAppSelection = (scope) => {
    // Terrafine, Residens
    if (scope.id) selectScope(scope);
    // General
    else selectAllScopes();
  };

  const showInvestorSubapp = () => {
    if (isOverviewMode()) {
      return (
        ["READ_ONLY", "READ_WRITE"].includes(
          getSubappAuthorizationType("terrafine", "INVESTOR")
        ) ||
        ["READ_ONLY", "READ_WRITE"].includes(
          getSubappAuthorizationType("residens", "INVESTOR")
        )
      );
    } else {
      return ["READ_ONLY", "READ_WRITE"].includes(
        getSubappAuthorizationType(getActiveScope().name, "INVESTOR")
      );
    }
  };

  const showIntermediateSubapp = () => {
    if (isOverviewMode()) {
      return (
        ["READ_ONLY", "READ_WRITE"].includes(
          getSubappAuthorizationType("terrafine", "INTERMEDIATE")
        ) ||
        ["READ_ONLY", "READ_WRITE"].includes(
          getSubappAuthorizationType("residens", "INTERMEDIATE")
        )
      );
    } else {
      return ["READ_ONLY", "READ_WRITE"].includes(
        getSubappAuthorizationType(getActiveScope().name, "INTERMEDIATE")
      );
    }
  };

  // Terrafine
  const showProjectSubapp = () => {
    return (
      getActiveScopesNames().includes("terrafine") &&
      ["READ_ONLY", "READ_WRITE"].includes(
        getSubappAuthorizationType("terrafine", "PROJECT")
      )
    );
  };

  // Residens
  const showResidenceSubapp = () => {
    return (
      getActiveScopesNames().includes("residens") &&
      ["READ_ONLY", "READ_WRITE"].includes(
        getSubappAuthorizationType("residens", "PROJECT")
      )
    );
  };

  const unauthorizedDisplay = (
    <div className="app-container">
      <div className="unauthorized-page bg-custom-violet">
        {
          "Votre compte existe mais ne possède pas les autorisations suffisantes pour utiliser cet outil. Merci de contacter les administrateurs."
        }
      </div>
    </div>
  );

  const subappMenu = () => (
    <div className="navbar-container">
      <div className="navbar-menu-container">
        {activeScope && (
          <AppDropdown
            activeScope={activeScope}
            inactiveScopes={inactiveScopes}
            handleAppSelection={handleAppSelection}
          />
        )}
        <div className="navbar-submenu-container">
          {isAdmin() && (
            <NavIconButton
              key={"dashboard"}
              index={"dashboard"}
              text={"Dashboard"}
              src={MEDIAS.dashboard}
              onClick={() => changeSubapp("dashboard")}
              isActive={navigationContext.currentSubapp === "dashboard"}
            />
          )}
          {showInvestorSubapp() && (
            <NavIconButton
              key={"investor"}
              index={"investor"}
              text={"Investisseurs"}
              src={MEDIAS.thirdParty}
              onClick={() => changeSubapp("investor")}
              isActive={navigationContext.currentSubapp === "investor"}
            />
          )}
          {showIntermediateSubapp() && (
            <NavIconButton
              key={"intermediate"}
              index={"intermediate"}
              text={"Intermédiaires"}
              src={MEDIAS.briefcase}
              onClick={() => changeSubapp("intermediate")}
              isActive={navigationContext.currentSubapp === "intermediate"}
            />
          )}
          <NavIconButton
            key={"toolbox"}
            index={"toolbox"}
            text={"Boîte à outils"}
            src={MEDIAS.toolbox}
            onClick={() => changeSubapp("toolbox")}
            isActive={navigationContext.currentSubapp === "toolbox"}
          />
        </div>
        {(showProjectSubapp() || showResidenceSubapp()) && (
          <div className="navbar-submenu-container">
            <div className="navbar-submenu-label">Sous applications</div>
            {showProjectSubapp() && (
              <NavIconButton
                key={"beneficiary"}
                index={"beneficiary"}
                text={"Projets"}
                src={MEDIAS.navIconProject}
                onClick={() => changeSubapp("beneficiary")}
                isActive={navigationContext.currentSubapp === "beneficiary"}
              />
            )}
            {showResidenceSubapp() && (
              <NavIconButton
                key={"residence"}
                index={"residence"}
                text={"Résidences"}
                src={MEDIAS.residence}
                onClick={() => changeSubapp("residence")}
                isActive={navigationContext.currentSubapp === "residence"}
              />
            )}
          </div>
        )}

        <div className="navbar-submenu-container">
          <div className="navbar-submenu-label">Paramètres</div>
          {isAdmin() && (
            <NavIconButton
              key={"user"}
              index={"user"}
              text={"Utilisateurs"}
              src={MEDIAS.users}
              onClick={() => changeSubapp("user")}
              isActive={navigationContext.currentSubapp === "user"}
            />
          )}
          <NavIconButton
            key={"account"}
            index={"account"}
            text={"Mon compte"}
            src={MEDIAS.account}
            onClick={() => changeSubapp("account")}
            isActive={navigationContext.currentSubapp === "account"}
          />
        </div>
      </div>
    </div>
  );

  const displaySubapp = () => {
    if (navigationContext.currentSubapp === "dashboard" && isAdmin())
      return <DashboardPage scopes={getActiveScopesNames()} />;
    else if (
      navigationContext.currentSubapp === "investor" &&
      showInvestorSubapp()
    )
      return <InvestorsPage />;
    else if (
      navigationContext.currentSubapp === "intermediate" &&
      showIntermediateSubapp()
    )
      return <IntermediatePage scopes={getActiveScopesNames()} />;
    else if (navigationContext.currentSubapp === "toolbox") {
      return <ToolboxPage />;
    } else if (
      navigationContext.currentSubapp === "residence" &&
      showResidenceSubapp()
    ) {
      return <ResidencePage />;
    } else if (
      navigationContext.currentSubapp === "beneficiary" &&
      showProjectSubapp()
    )
      return <ProjectPage />;
    else if (navigationContext.currentSubapp === "account")
      return <AccountPage />;
    else if (navigationContext.currentSubapp === "archive" && isIntermediate())
      return <ArchivePage />;
    else if (navigationContext.currentSubapp === "user" && isAdmin())
      return <UserPage />;
    else
      return (
        <div className="unauthorized-page">
          <p> {"Veuillez sélectionner un onglet dans le menu à gauche."}</p>
        </div>
      );
  };

  // Account has no authorizations
  if (!getAuthorizedScopeList().length) return unauthorizedDisplay;

  return (
    <div className="app-container ">
      <div
        className="navbar-vertical-container"
        style={{
          width: DIMENSIONS.navBarWidth,
        }}
      >
        <div className="navbar-container">
          {subappMenu()}
          <div className="bottom-container">
            {isPreproduction && (
              <Tag value={"Env. démo"} bgColor={"bg-orange-700 mb-2"} />
            )}
            <div
              onClick={handleLogout}
              className="logout"
            >{`Se déconnecter`}</div>
            <div className="version">{`Version ${CONSTANTS.VERSION}`}</div>
          </div>
        </div>
      </div>
      {/* Page content */}
      <div
        style={{
          height: DIMENSIONS.pageHeight,
          width: DIMENSIONS.pageWidth,
        }}
      >
        {displaySubapp()}
      </div>
    </div>
  );
}

export default InternalRouter;
