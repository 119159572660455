import React, { useState, useEffect, useRef, useMemo } from "react";
import { Sidebar } from "primereact/sidebar";
import { InputText } from "primereact/inputtext";
import TitlePage from "../../components/TitlePage";
import { COLORS } from "../../ressources/STYLING";
import { MEDIAS } from "../../ressources/MEDIAS";
import "./ManageItem.scss";
import { CONSTANTS } from "../../ressources/CONSTANTS";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import { Button } from "primereact/button";
import FormInput from "../../components/Form/FormInput";
import { AutoComplete } from "primereact/autocomplete";
import { privateFetch } from "../../utils/apiHelper";
import { useAuthorizationsContext } from "../../contexts/AuthorizationsContext";
import { useAccountContext } from "../../contexts/AccountContext";
import { Message } from "primereact/message";
import Tag from "../../components/Tag";

function ManageInvestor({
  isOpen,
  handleClose,
  handleCreation,
  handleUpdate,
  handleScopeUpdate,
  loading,
  investor,
}) {
  const { getActiveScope } = useAuthorizationsContext();
  const { accountContext, isIntermediate } = useAccountContext();

  const autocompleteRef = useRef(null);

  const [filteredInvestors, setFilteredInvestors] = useState([]);
  const [query, setQuery] = useState("");
  const [existingInvestor, setExistingInvestor] = useState(undefined);
  const [newInvestor, setNewInvestor] = useState(false);

  const [intermediateList, setIntermediateList] = useState([]);

  // Mandatory
  const [name, setName] = useState("");
  const [firstName, setFirstName] = useState("");
  // Optionnal
  const [gender, setGender] = useState(undefined);
  const [city, setCity] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [address, setAddress] = useState("");
  const [birthPlace, setBirthPlace] = useState("");
  const [birthDate, setBirthDate] = useState(undefined);
  const [email, setEmail] = useState("");
  const [profile, setProfile] = useState(undefined);
  const [phone, setPhone] = useState("");
  const [college, setCollege] = useState(undefined);
  const [intermediate, setIntermediate] = useState(undefined);

  useEffect(() => {
    if (!investor) {
      fetchInvestorList();
    }
  }, [isOpen]);

  useEffect(() => {
    fetchIntermediateList();
  }, []);

  useEffect(() => {
    if (investor) {
      // Form
      setName(investor.name);
      setFirstName(investor.first_name);
      setGender(investor.gender);
      setCity(investor.city);
      setZipCode(investor.zip_code);
      setAddress(investor.address);
      setBirthPlace(investor.birth_place);
      setBirthDate(investor.birth_dt);
      setEmail(investor.email);
      setProfile(investor.profile);
      setPhone(investor.phone);
      setCollege(investor.college);
      setIntermediate(investor.intermediate);
    } else {
      // Form
      setName("");
      setFirstName("");
      setGender(undefined);
      setCity("");
      setZipCode("");
      setAddress("");
      setBirthPlace("");
      setBirthDate(undefined);
      setEmail("");
      setProfile(undefined);
      setPhone("");
      setCollege(undefined);
      setIntermediate(undefined);
      // Autocomplete
      setExistingInvestor(undefined);
      setQuery("");
      setNewInvestor(isIntermediate);
    }
  }, [investor, isOpen]);

  const fetchIntermediateList = async () => {
    await privateFetch(
      "GET",
      `/cu/${accountContext.id}/intermediate/autocomplete/`
    ).then((res) => {
      if (res) setIntermediateList(res);
    });
  };

  const fetchInvestorList = async (q = "") => {
    await privateFetch(
      "GET",
      `/cu/${accountContext.id}/investor/autocomplete/?query=${q}`
    ).then((res) => {
      setFilteredInvestors(res);
    });
  };

  const onValidate = () => {
    if (existingInvestor) {
      handleScopeUpdate(existingInvestor);
    } else {
      const investorForm = {
        name,
        first_name: firstName,
        gender,
        city,
        zip_code: zipCode,
        address,
        birth_place: birthPlace,
        birth_dt: birthDate
          ? moment(birthDate).format("YYYY-MM-DD")
          : undefined,
        email,
        profile,
        phone,
        college,
        intermediate: intermediate?.id,
      };
      investor ? handleUpdate(investorForm) : handleCreation(investorForm);
    }
  };

  const handleInvestorSelection = (investor) => {
    if (!investor.id) {
      // Create new investor
      setNewInvestor(true);
      setName(query);
    } else {
      // Existing investor
      setExistingInvestor(investor);
    }
  };

  const formIsValid = name.length && firstName.length;

  const footerButtons = (
    <div className="manage-footer">
      <Button
        key={"validate"}
        label={`Valider ${investor ? "la modification" : "l'ajout"}`}
        loading={loading}
        disabled={existingInvestor ? investorExistInScope() : !formIsValid}
        onClick={onValidate}
        severity="success"
      />
    </div>
  );

  const investorTemplate = (item) => {
    if (!item.id) {
      return (
        <div className="row-gap">
          <i className="pi pi-user-plus"></i>
          <span className="autocomplete-new-item">
            Ajouter un nouvel investisseur
          </span>
        </div>
      );
    } else {
      return (
        <div className="autocomplete-item-container">
          <span>{`${item.first_name} ${item.name}`}</span>
          <div className="flex gap-x-1">
            {item.linked_scopes.map((ls) => (
              <Tag key={ls.id} value={ls.label} />
            ))}
          </div>
        </div>
      );
    }
  };

  const investorFullName = (value) => {
    return `${value.first_name} ${value.name}`;
  };

  function investorExistInScope() {
    return existingInvestor.linked_scopes.some(
      (ls) => ls.id === getActiveScope().id
    );
  }

  const intermediateTemplate = (item) => {
    if (!item) {
      return (
        <div className="row-gap">
          <span className="autocomplete-new-item">
            Sélectionner un intermédiaire
          </span>
        </div>
      );
    } else {
      return (
        <div className="autocomplete-item-container">
          <Tag key={item.id} value={item.name} />
        </div>
      );
    }
  };

  const investorAutocompletion = () => {
    return (
      <div className="module-form-container">
        <FormInput label={"Nom de l'investisseur"}>
          <AutoComplete
            ref={autocompleteRef}
            field={investorFullName}
            inputClassName="autocomplete-input"
            value={query}
            suggestions={[...filteredInvestors, { id: 0 }]}
            completeMethod={(e) => fetchInvestorList(e.query)}
            onChange={(e) => {
              setQuery(e.value);
              setExistingInvestor(undefined);
            }}
            onSelect={(e) => handleInvestorSelection(e.value)}
            itemTemplate={investorTemplate}
            dropdown
            placeholder="Veuillez saisir le nom de l'investisseur"
          />
        </FormInput>
        {existingInvestor && investorExistInScope() && (
          <Message
            severity="warn"
            text={`Cet investisseur existe déjà dans ${getActiveScope().label}`}
          />
        )}
        {existingInvestor && !investorExistInScope() && (
          <Message
            severity="success"
            text={`Cet investisseur sera ajouté à ${getActiveScope().label}`}
          />
        )}
      </div>
    );
  };

  return (
    <Sidebar
      style={{ width: 600, paddingLeft: 10, paddingRight: 10 }}
      header={
        <TitlePage
          text={`${
            investor ? `${name} ${firstName}` : "Ajouter un investisseur"
          }`}
        />
      }
      visible={isOpen}
      maskStyle={{ backgroundColor: "#00000080" }}
      onHide={() => handleClose()}
      position="right"
    >
      <div className="sidebar-content-container">
        {newInvestor || investor ? (
          <div className="form-wrapper">
            <FormInput label={"Nom"} required isValid={name.length}>
              <InputText
                id="name"
                value={name}
                placeholder="Veuillez saisir le nom de l'investisseur"
                onChange={(e) => setName(e.target.value)}
              />
            </FormInput>
            <FormInput label={"Prénom"} required isValid={firstName.length}>
              <InputText
                id="first-name"
                value={firstName}
                placeholder="Veuillez saisir le prénom de l'investisseur"
                onChange={(e) => setFirstName(e.target.value)}
              />
            </FormInput>
            {!isIntermediate() && (
              <FormInput label={"Intermediaire"}>
                <Dropdown
                  id="intermediate"
                  value={intermediate}
                  onChange={(e) => setIntermediate(e.value)}
                  itemTemplate={intermediateTemplate}
                  valueTemplate={intermediateTemplate}
                  options={intermediateList}
                  optionLabel="name"
                  placeholder="Veuillez sélectionner l'intermédiaire"
                />
              </FormInput>
            )}
            <FormInput label={"Civilité"}>
              <Dropdown
                id="gender"
                value={gender}
                onChange={(e) => setGender(e.value)}
                options={CONSTANTS.GENDER_TYPES}
                optionLabel="label"
                placeholder="Veuillez sélectionner la civilité"
              />
            </FormInput>
            <FormInput label={"Ville"}>
              <InputText
                id="city"
                value={city}
                placeholder="Veuillez saisir la ville"
                onChange={(e) => setCity(e.target.value)}
              />
            </FormInput>
            <FormInput label={"Code postal"}>
              <InputText
                id="zip-code"
                value={zipCode}
                placeholder="Veuillez saisir le code postal"
                onChange={(e) => setZipCode(e.target.value)}
              />
            </FormInput>
            <FormInput label={"Adresse"}>
              <InputText
                id="adress"
                value={address}
                placeholder="Veuillez saisir l'adresse"
                onChange={(e) => setAddress(e.target.value)}
              />
            </FormInput>
            <FormInput label={"Lieu de naissance"}>
              <InputText
                id="birthPlace"
                value={birthPlace}
                placeholder="Veuillez saisir le lieu de naissance'"
                onChange={(e) => setBirthPlace(e.target.value)}
              />
            </FormInput>
            <FormInput label={"Date de naissance"}>
              <Calendar
                id="birth-date"
                value={birthDate}
                onChange={(e) => setBirthDate(e.value)}
                dateFormat="dd/mm/yy"
                placeholder="Veuillez selectionner la date de naissance"
                maxDate={new Date()}
                locale="fr"
              />
            </FormInput>

            <FormInput label={"Profil"}>
              <Dropdown
                id="profile"
                value={profile}
                onChange={(e) => setProfile(e.value)}
                options={CONSTANTS.PROFILE_TYPES}
                optionLabel="label"
                placeholder="Veuillez sélectionner le profil"
              />
            </FormInput>
            <FormInput label={"Téléphone"}>
              <InputText
                id="phone"
                value={phone}
                placeholder="Veuillez saisir le numéro de téléphone"
                onChange={(e) => setPhone(e.target.value)}
              />
            </FormInput>
            <FormInput label={"Email"}>
              <InputText
                id="email"
                value={email}
                placeholder="Veuillez saisir l'adresse email"
                onChange={(e) => setEmail(e.target.value)}
              />
            </FormInput>
            <FormInput label={"Collège"}>
              <Dropdown
                id="college"
                value={college}
                onChange={(e) => setCollege(e.value)}
                options={CONSTANTS.COLLEGE_TYPES}
                optionLabel="label"
                placeholder="Veuillez sélectionner le collège"
              />
            </FormInput>
          </div>
        ) : (
          investorAutocompletion()
        )}
        {footerButtons}
      </div>
    </Sidebar>
  );
}

export default ManageInvestor;
