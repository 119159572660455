import moment from "moment";

export const applyCurrencyFormat = (value) => {
  if (value === 0)
    return value.toLocaleString("fr-FR", {
      style: "currency",
      currency: "EUR",
      minimumFractionDigits: 0,
    });
  if (!value) return "";
  return value.toLocaleString("fr-FR", {
    style: "currency",
    currency: "EUR",
    minimumFractionDigits: 0,
  });
};

export const applyAreaFormat = (value) => {
  if (value === 0) return "0 m²";
  if (!value) return "";
  return `${value.toLocaleString("fr-FR", {
    minimumFractionDigits: 0,
  })} m²`;
};

export const applyHectareFormatFromArea = (value) => {
  if (value === 0) return "0 ha";
  if (!value) return "";
  return `${(value / 10000).toLocaleString("fr-FR", {
    minimumFractionDigits: 2,
  })} ha`;
};

export const applyDateFormat = (value) => {
  if (!value) return "";
  return moment(value).format("DD/MM/YYYY");
};

export const applyPercentage = (value) => {
  if (value === 0) return "0 %";
  if (!value) return " - ";
  return `${(value * 100).toLocaleString("fr-FR")} %`;
};
