import React from "react";
import moment from "moment";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useAuthorizationsContext } from "../../contexts/AuthorizationsContext";
import { applyCurrencyFormat } from "../../utils/datatableHelper";
import { CONSTANTS } from "../../ressources/CONSTANTS";
import Tag from "../../components/Tag";
import { MEDIAS } from "../../ressources/MEDIAS";

export const IntermediateTransactionDatatable = ({ data }) => {
  const { getScopeFromId } = useAuthorizationsContext();

  const executionDateTemplate = (rowData) => {
    return moment(rowData.execution_date).format("DD/MM/YYYY");
  };

  const scopesBodyTemplate = (rowData) => {
    return (
      <Tag
        value={getScopeFromId(rowData.linked_scope).label}
        bgColor={getScopeFromId(rowData.linked_scope).main_color}
      />
    );
  };

  const stateBodyTemplate = (rowData) => {
    return (
      <Tag
        value={
          CONSTANTS.TRANSACTION_STATUS.find((ts) => ts.value === rowData.status)
            .label
        }
        bgColor={
          CONSTANTS.TRANSACTION_STATUS.find((ts) => ts.value === rowData.status)
            .color
        }
      />
    );
  };

  const intermediateAccountBodyTemplate = (rowData) => {
    if (!rowData.intermediate_account)
      return <span className="italic text-gray-400">Aucun</span>;
    return (
      <Tag icon={MEDIAS.userWhite} value={rowData.intermediate_account.alias} />
    );
  };

  if (!data.length) {
    return (
      <div className="empty-section-message">Aucun investisseur associé</div>
    );
  }
  return (
    <DataTable value={data} emptyMessage={"Pas de transaction à afficher"}>
      <Column
        field="investor"
        className="font-bold"
        header="Investisseur"
        body={(rowData) =>
          `${rowData.investor.first_name} ${rowData.investor.name}`
        }
      ></Column>
      <Column
        field="intermediate_account.alias"
        header="Compte"
        body={intermediateAccountBodyTemplate}
      ></Column>
      <Column field="state" header="État" body={stateBodyTemplate}></Column>
      <Column field="transaction_type.label" header="Type"></Column>
      <Column
        field="amount"
        header="Montant"
        body={(rowData) => applyCurrencyFormat(rowData.amount)}
      ></Column>
      <Column
        body={executionDateTemplate}
        field="execution_date"
        header="Date"
        dataType="date"
      ></Column>
      <Column
        field="linked_scope"
        header="Périmètre"
        className="w-64"
        body={scopesBodyTemplate}
      ></Column>
    </DataTable>
  );
};
