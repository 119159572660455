import React, { Component } from "react";

//props
//light : text is light, else is dark

function TitlePage(props) {
  return (
    <div
      className={
        props.allowNavigation
          ? props.isActive
            ? "breadcrumb-active"
            : "breadcrumb-inactive"
          : ""
      }
      onClick={() =>
        props.allowNavigation
          ? props.handleNavigation(props.scrollTarget)
          : console.log("TitlePage : Navigation Not Allowed")
      }
      style={{
        fontSize: "2.7vh",
        fontWeight: props.isEmpty ? 400 : 500,
        color: props.light ? "#FFFFFF" : "#2B2B2B",
        fontStyle: props.isEmpty ? "italic" : "normal",
        opacity: props.isEmpty ? 0.7 : "",
      }}
    >
      {props.text}
    </div>
  );
}

export default TitlePage;
