import React, { useState, useEffect } from "react";
import { Sidebar } from "primereact/sidebar";
import TitlePage from "../../components/TitlePage";
import "./ManageItem.scss";
import { InputNumber } from "primereact/inputnumber";
import { CONSTANTS } from "../../ressources/CONSTANTS";
import { Calendar } from "primereact/calendar";
import { RadioButton } from "primereact/radiobutton";
import { Button } from "primereact/button";
import moment from "moment";
import FormInput from "../../components/Form/FormInput";
import { Dropdown } from "primereact/dropdown";
import { Divider } from "primereact/divider";
import { privateFetch } from "../../utils/apiHelper";
import { useAccountContext } from "../../contexts/AccountContext";
import { useAuthorizationsContext } from "../../contexts/AuthorizationsContext";

function ManageTransaction({
  isOpen,
  handleClose,
  handleCreation,
  handleUpdate,
  loading,
  investor,
  transaction,
}) {
  const { accountContext, isIntermediate } = useAccountContext();
  const { getActiveScopesNames } = useAuthorizationsContext();

  const [transactionTypeList, setTransactionTypeList] = useState([]);
  const [intermediateList, setIntermediateList] = useState([]);

  const [amount, setAmount] = useState(0);
  const [transactionType, setTransactionType] = useState(undefined);
  const [nature, setNature] = useState(undefined);
  const [executionDate, setExecutionDate] = useState(undefined);
  const [feeRate, setFeeRate] = useState(0);
  const [intermediateId, setIntermediateId] = useState(undefined);
  const [intermediateAccountId, setIntermediateAccountId] = useState(undefined);

  // Update only
  const [status, setStatus] = useState(undefined);

  useEffect(() => {
    fetchTransactionTypeList();
    fetchIntermediateList();
    if (transaction) {
      setAmount(transaction.amount);
      setTransactionType(transaction.transaction_type);
      setNature(transaction.nature);
      setExecutionDate(new Date(transaction.execution_date));
      setStatus(transaction.status);
      setFeeRate(transaction.fee_rate ? transaction.fee_rate * 100 : undefined);
      setIntermediateId(transaction.intermediate?.id);
      setIntermediateAccountId(transaction.intermediate_account?.id);
    } else {
      setAmount(0);
      setNature(CONSTANTS.TRANSACTION_NATURE_TYPES[0].value);
      setTransactionTypeList(undefined);
      setExecutionDate(undefined);
      setFeeRate(0);
      setStatus(
        CONSTANTS.TRANSACTION_STATUS.find((ts) => ts.value === "validated")
          .value
      );
      setIntermediateId(investor?.intermediate?.id);
      setIntermediateAccountId(undefined);
    }
  }, [transaction]);

  const fetchIntermediateList = async () => {
    await privateFetch(
      "GET",
      `/cu/${accountContext.id}/intermediate/autocomplete?scopes=${getActiveScopesNames()}`
    ).then((res) => {
      if (res) setIntermediateList(res);
    });
  };

  const fetchTransactionTypeList = async () => {
    await privateFetch(
      "GET",
      `/cu/${accountContext.id}/transaction/type/`
    ).then((res) => {
      setTransactionTypeList(res);
    });
  };

  const onValidate = () => {
    const transactionForm = {
      amount,
      transaction_type: transactionType.id,
      nature,
      execution_date: moment(executionDate).format("YYYY-MM-DD"),
      fee_rate: feeRate / 100,
      status,
      intermediate: intermediateId,
      intermediate_account: intermediateAccountId,
    };
    transaction
      ? handleUpdate(transactionForm)
      : handleCreation(transactionForm);
  };

  const computeShareNumber = () => {
    if (!amount) return undefined;
    if (!transactionType) return undefined;
    if (!transactionType.share_price) return undefined;
    return amount / transactionType.share_price;
  };

  const dropdownTemplate = (option, props) => {
    if (option) {
      return (
        <div className="flex items-center gap-x-3 px-1">
          <div className={`h-2 w-2 rounded-full ${option.color}`} />
          <div className="font-medium">{option.label}</div>
        </div>
      );
    }
    return <span>{props.placeholder}</span>;
  };

  const amountIsValid = amount !== undefined;
  const executionDateIsValid = executionDate !== undefined;
  const typeIsValid = transactionType !== undefined;
  const natureIsValid = nature !== undefined;
  const formIsValid =
    amountIsValid && executionDateIsValid && typeIsValid && natureIsValid;

  const footerButtons = (
    <div className="manage-footer">
      <Button
        key={"validate"}
        label={`Valider ${transaction ? "la modification" : "l'ajout"}`}
        loading={loading}
        disabled={!formIsValid}
        onClick={onValidate}
        severity="success"
      />
    </div>
  );

  return (
    <Sidebar
      style={{ width: 600, paddingLeft: 10, paddingRight: 10 }}
      header={
        <TitlePage
          text={`${
            transaction ? `Modifier une transaction` : "Ajouter une transaction"
          }`}
        />
      }
      visible={isOpen}
      maskStyle={{ backgroundColor: "#00000080" }}
      onHide={() => handleClose()}
      position="right"
    >
      <div className="sidebar-content-container">
        <div className="form-wrapper">
          {!isIntermediate() && (
            <FormInput label={"Nature"} required isValid={natureIsValid}>
              <div className="flex flex-row gap-x-5">
                {CONSTANTS.TRANSACTION_NATURE_TYPES.map((nt, i) => (
                  <div key={i} className="flex flex-row gap-x-2">
                    <RadioButton
                      inputId={nt.value}
                      name={nt.label}
                      value={nt.value}
                      onChange={(e) => setNature(e.target.value)}
                      checked={nature === nt.value}
                    />
                    <label htmlFor={nt.value}>{nt.label}</label>
                  </div>
                ))}
              </div>
            </FormInput>
          )}

          <FormInput label={"Type"} required isValid={typeIsValid}>
            <Dropdown
              value={transactionType}
              onChange={(e) => setTransactionType(e.value)}
              options={transactionTypeList}
              optionLabel="label"
              placeholder="Sélectionner un type de transaction"
            />
          </FormInput>
          {!isIntermediate() && (
            <FormInput label={"Intermediaire"}>
              <Dropdown
                id="intermediate"
                value={intermediateId}
                onChange={(e) => {
                  setIntermediateId(e.value);
                  setIntermediateAccountId(undefined);
                }}
                options={intermediateList}
                optionValue="id"
                optionLabel="name"
                placeholder="Veuillez sélectionner l'intermédiaire"
              />
            </FormInput>
          )}

          {!isIntermediate() && intermediateId && (
            <FormInput label={"Compte intermédiaire"}>
              <Dropdown
                id="intermediateAccount"
                value={intermediateAccountId}
                onChange={(e) => setIntermediateAccountId(e.value)}
                options={
                  intermediateList.find((i) => i.id === intermediateId)
                    ?.accounts
                }
                optionValue="id"
                optionLabel="alias"
                placeholder="Veuillez sélectionner le compte intermédiaire"
              />
            </FormInput>
          )}

          {!isIntermediate() && (
            <FormInput label={"Statut"}>
              <Dropdown
                value={status}
                onChange={(e) => setStatus(e.value)}
                options={CONSTANTS.TRANSACTION_STATUS}
                disabled={!transaction}
                optionLabel="label"
                valueTemplate={dropdownTemplate}
                itemTemplate={dropdownTemplate}
              />
            </FormInput>
          )}

          <FormInput label={"Montant"} required isValid={amountIsValid}>
            <InputNumber
              id="amount"
              value={amount}
              placeholder="Veuillez saisir le montant de la transaction"
              onValueChange={(e) => setAmount(e.value)}
              min={0}
              mode="currency"
              currency="EUR"
              locale="fr-FR"
            />
          </FormInput>

          <FormInput
            label={"Frais (en %)"}
            annotation={
              investor?.intermediate?.max_fee_rate
                ? `Max ${investor.intermediate.max_fee_rate * 100}%`
                : undefined
            }
          >
            <InputNumber
              value={feeRate}
              placeholder="Veuillez saisir le taux"
              onValueChange={(e) => setFeeRate(e.value)}
              minFractionDigits={1}
              maxFractionDigits={1}
              min={0}
              max={investor?.intermediate?.max_fee_rate * 100 || 100}
              suffix=" %"
              locale="fr-FR"
            />
          </FormInput>

          <FormInput
            label={"Date d'éxecution"}
            required
            isValid={executionDateIsValid}
          >
            <Calendar
              id="execution-date"
              onChange={(e) => setExecutionDate(e.value)}
              dateFormat="dd/mm/yy"
              placeholder="Veuillez selectionner la date d'éxecution"
              maxDate={new Date()}
              value={executionDate}
              locale="fr"
            />{" "}
          </FormInput>
          <h4 className="mt-3">Champs calculés automatiquement</h4>
          <FormInput label={"Frais (en €)"}>
            <InputNumber
              id="feeAmount"
              value={feeRate ? (amount * feeRate) / 100 : undefined}
              mode="currency"
              currency="EUR"
              locale="fr-FR"
              placeholder="Taux non renseigné"
              readOnly
            />
          </FormInput>
          <FormInput label={"Nombre de parts"}>
            <InputNumber
              id="shareNb"
              value={computeShareNumber()}
              placeholder='Pas de calcul de part pour "Compte Courant"'
              readOnly
            />
          </FormInput>
        </div>
        {footerButtons}
      </div>
    </Sidebar>
  );
}

export default ManageTransaction;
