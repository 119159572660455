import React, { useState, useEffect, useRef } from "react";
import { Sidebar } from "primereact/sidebar";
import { InputText } from "primereact/inputtext";
import TitlePage from "../../components/TitlePage";
import "./ManageItem.scss";
import { CONSTANTS } from "../../ressources/CONSTANTS";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { Button } from "primereact/button";
import { InputNumber } from "primereact/inputnumber";
import { AutoComplete } from "primereact/autocomplete";
import { publicFetch } from "../../utils/apiHelper";
import FormInput from "../../components/Form/FormInput";
import { InputTextarea } from "primereact/inputtextarea";
import {
  getDateOrUndefined,
  getDateStringOrUndefined,
} from "../../utils/dateHelper";

function ManageResidence({
  isOpen,
  handleClose,
  handleCreation,
  handleUpdate,
  handleDelete,
  loading,
  residence,
}) {
  const autocompleteRef = useRef(null);
  // Autocomplete
  const [filteredCities, setFilteredCities] = useState([]);
  const [query, setQuery] = useState("");
  // Mandatory
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [collectedFund, setCollectedFund] = useState(0);
  const [selectedCity, setSelectedCity] = useState(undefined);
  const [state, setState] = useState(CONSTANTS.RESIDENCE_STATES[0].label);
  const [type, setType] = useState(CONSTANTS.RESIDENCE_TYPES[0].label);
  // Optionnal
  const [totalBudget, setTotalBudget] = useState(0);
  const [totalSeniorHousingNb, setTotalSeniorHousingNb] = useState(undefined);
  const [totalJuniorHousingNb, setTotalJuniorHousingNb] = useState(undefined);
  const [occupiedSeniorHousingNb, setOccupiedSeniorHousingNb] =
    useState(undefined);
  const [occupiedJuniorHousingNb, setOccupiedJuniorHousingNb] =
    useState(undefined);
  const [totalSeniorNb, setTotalSeniorNb] = useState(undefined);
  const [totalJuniorNb, setTotalJuniorNb] = useState(undefined);
  const [currentSeniorNb, setCurrentSeniorNb] = useState(undefined);
  const [currentJuniorNb, setCurrentJuniorNb] = useState(undefined);
  const [fundingEndDt, setFundingEndDt] = useState(undefined);
  const [landSignatureDt, setLandSignatureDt] = useState(undefined);
  const [buildingPermitDepositDt, setBuildingPermitDepositDt] =
    useState(undefined);
  const [constructionStartDt, setConstructionStartDt] = useState(undefined);
  const [constructionEndDt, setConstructionEndDt] = useState(undefined);
  const [inaugurationDt, setInaugurationDt] = useState(undefined);
  const [internalNotes, setInternalNotes] = useState("");
  const [description, setDescription] = useState("");

  useEffect(() => {
    if (residence) {
      setName(residence.name);
      setAddress(residence.address);
      setCollectedFund(residence.collected_fund);
      setSelectedCity(residence.city);
      setQuery(residence.city.name);
      setState(residence.state);
      setType(residence.type);
      setDescription(residence.description);
      setInternalNotes(residence.internal_notes);
      setTotalBudget(residence.total_budget);
      setTotalSeniorHousingNb(residence.total_senior_housing_nb);
      setTotalJuniorHousingNb(residence.total_junior_housing_nb);
      setOccupiedSeniorHousingNb(residence.occupied_senior_housing_nb);
      setOccupiedJuniorHousingNb(residence.occupied_junior_housing_nb);
      setTotalSeniorNb(residence.total_senior_inhabitant_nb);
      setTotalJuniorNb(residence.total_junior_inhabitant_nb);
      setCurrentSeniorNb(residence.occupied_senior_inhabitant_nb);
      setCurrentJuniorNb(residence.occupied_junior_inhabitant_nb);
      setFundingEndDt(getDateOrUndefined(residence.funding_end_dt));
      setLandSignatureDt(getDateOrUndefined(residence.land_signature_dt));
      setBuildingPermitDepositDt(
        getDateOrUndefined(residence.building_permit_deposit_dt)
      );
      setConstructionStartDt(
        getDateOrUndefined(residence.construction_start_dt)
      );
      setConstructionEndDt(getDateOrUndefined(residence.construction_end_dt));
      setInaugurationDt(getDateOrUndefined(residence.inauguration_dt));
    } else {
      setName("");
      setAddress("");
      setCollectedFund(undefined);
      setSelectedCity(undefined);
      setQuery("");
      setState(CONSTANTS.RESIDENCE_STATES[0].label);
      setType(CONSTANTS.RESIDENCE_TYPES[0].label);
      setDescription("");
      setInternalNotes("");
      setTotalBudget(undefined);
      setTotalSeniorHousingNb(undefined);
      setTotalJuniorHousingNb(undefined);
      setOccupiedSeniorHousingNb(undefined);
      setOccupiedJuniorHousingNb(undefined);
      setTotalSeniorNb(undefined);
      setTotalJuniorNb(undefined);
      setCurrentSeniorNb(undefined);
      setCurrentJuniorNb(undefined);
      setFundingEndDt(undefined);
      setLandSignatureDt(undefined);
      setBuildingPermitDepositDt(undefined);
      setConstructionStartDt(undefined);
      setConstructionEndDt(undefined);
      setInaugurationDt(undefined);
    }
  }, [residence, isOpen]);

  const fetchCityList = async (q) => {
    await publicFetch("GET", `/city/autocomplete/google/?query=${q}`).then(
      (res) => {
        setFilteredCities(res);
      }
    );
  };

  const onValidate = () => {
    const residenceForm = {
      name,
      address,
      collected_fund: collectedFund,
      city: selectedCity,
      description,
      internal_notes: internalNotes,
      state,
      type,
      total_budget: totalBudget,
      total_senior_housing_nb: totalSeniorHousingNb,
      total_junior_housing_nb: totalJuniorHousingNb,
      occupied_senior_housing_nb: occupiedSeniorHousingNb,
      occupied_junior_housing_nb: occupiedJuniorHousingNb,
      total_senior_inhabitant_nb: totalSeniorNb,
      total_junior_inhabitant_nb: totalJuniorNb,
      occupied_senior_inhabitant_nb: currentSeniorNb,
      occupied_junior_inhabitant_nb: currentJuniorNb,
      land_signature_dt: getDateStringOrUndefined(landSignatureDt),
      funding_end_dt: getDateStringOrUndefined(fundingEndDt),
      building_permit_deposit_dt: getDateStringOrUndefined(
        buildingPermitDepositDt
      ),
      construction_start_dt: getDateStringOrUndefined(constructionStartDt),
      construction_end_dt: getDateStringOrUndefined(constructionEndDt),
      inauguration_dt: getDateStringOrUndefined(inaugurationDt),
    };
    residence ? handleUpdate(residenceForm) : handleCreation(residenceForm);
  };

  const formIsValid =
    name.length > 2 &&
    address.length > 2 &&
    collectedFund !== undefined &&
    selectedCity;

  const cityTemplate = (item) => {
    return <div>{`${item.name}`}</div>;
  };

  const footerButtons = (
    <div className="manage-footer">
      {/*
      residence && (
        <Button
          key={"delete"}
          label={`Supprimer la résidence`}
          onClick={handleDelete}
          severity="danger"
          outlined
        />
      )
      */}
      <Button
        key={"validate"}
        label={`Valider ${residence ? "les modifications" : "la création"}`}
        loading={loading}
        disabled={!formIsValid}
        onClick={onValidate}
        severity="success"
      />
    </div>
  );
  return (
    <Sidebar
      style={{ width: 600, paddingLeft: 10, paddingRight: 10 }}
      header={
        <TitlePage
          text={`${
            residence ? `Modifier une résidence` : "Ajouter une résidence"
          }`}
        />
      }
      visible={isOpen}
      maskStyle={{ backgroundColor: "#00000080" }}
      onHide={() => handleClose()}
      position="right"
    >
      <div className="sidebar-content-container">
        <div className="form-wrapper">
          <FormInput label={"Nom"} required isValid={name.length > 2}>
            <InputText
              value={name}
              placeholder="Veuillez saisir le nom de la résidence"
              onChange={(e) => setName(e.target.value)}
            />
          </FormInput>
          <FormInput label={"Ville"} required isValid={selectedCity}>
            <AutoComplete
              ref={autocompleteRef}
              field="name"
              inputClassName="autocomplete-input"
              value={query}
              suggestions={filteredCities}
              completeMethod={(e) => fetchCityList(e.query)}
              onChange={(e) => {
                setQuery(e.value);
                setSelectedCity(undefined);
              }}
              onSelect={(e) => setSelectedCity(e.value)}
              itemTemplate={cityTemplate}
              placeholder="Veuillez sélectionner une ville"
            />
          </FormInput>

          <FormInput label={"Adresse"} required isValid={address.length > 2}>
            <InputText
              value={address}
              placeholder="Veuillez saisir l'adresse de la résidence"
              onChange={(e) => setAddress(e.target.value)}
            />
          </FormInput>

          <FormInput
            label={"Fonds collectés"}
            required
            isValid={collectedFund !== undefined}
          >
            <InputNumber
              value={collectedFund}
              placeholder="Veuillez saisir le montant des fonds collectés"
              onChange={(e) => setCollectedFund(e.value)}
              min={0}
              mode="currency"
              currency="EUR"
              locale="fr-FR"
            />
          </FormInput>

          <FormInput label={"Budget total"}>
            <InputNumber
              value={totalBudget}
              placeholder="Veuillez saisir le budget total"
              onChange={(e) => setTotalBudget(e.value)}
              min={0}
              mode="currency"
              currency="EUR"
              locale="fr-FR"
            />
          </FormInput>

          <FormInput label={"Etat"}>
            <Dropdown
              value={state}
              onChange={(e) => setState(e.value)}
              options={CONSTANTS.RESIDENCE_STATES.map((rs) => rs.label)}
              placeholder="Veuillez sélectionner l'état"
            />
          </FormInput>

          <FormInput label={"Type"}>
            <Dropdown
              value={type}
              onChange={(e) => setType(e.value)}
              options={CONSTANTS.RESIDENCE_TYPES.map((rs) => rs.label)}
              placeholder="Veuillez sélectionner le type de projet"
            />
          </FormInput>

          <FormInput label={"Description publique"}>
            <InputTextarea
              rows={3}
              autoResize
              value={description}
              placeholder="Veuillez saisir la description du projet"
              onChange={(e) => setDescription(e.target.value)}
            />
          </FormInput>

          <FormInput label={"Notes internes"}>
            <InputTextarea
              rows={3}
              autoResize
              value={internalNotes}
              placeholder="Veuillez saisir les notes internes"
              onChange={(e) => setInternalNotes(e.target.value)}
            />
          </FormInput>

          <FormInput label={"Nb total de logements seniors"}>
            <InputNumber
              value={totalSeniorHousingNb}
              placeholder="Veuillez saisir nombre de logements"
              onChange={(e) => setTotalSeniorHousingNb(e.value)}
              min={0}
            />
          </FormInput>

          <FormInput label={"Nb total de logements jeunes"}>
            <InputNumber
              value={totalJuniorHousingNb}
              placeholder="Veuillez saisir nombre de logements"
              onChange={(e) => setTotalJuniorHousingNb(e.value)}
              min={0}
            />
          </FormInput>

          <FormInput label={"Nb de logements seniors occupés"}>
            <InputNumber
              value={occupiedSeniorHousingNb}
              placeholder="Veuillez saisir nombre de logements occupés"
              onChange={(e) => setOccupiedSeniorHousingNb(e.value)}
              min={0}
            />
          </FormInput>

          <FormInput label={"Nb de logements jeunes occupés"}>
            <InputNumber
              value={occupiedJuniorHousingNb}
              placeholder="Veuillez saisir nombre de logements occupés"
              onChange={(e) => setOccupiedJuniorHousingNb(e.value)}
              min={0}
            />
          </FormInput>

          <FormInput label={"Nb total de résidents seniors"}>
            <InputNumber
              value={totalSeniorNb}
              placeholder="Veuillez saisir nombre de seniors"
              onChange={(e) => setTotalSeniorNb(e.value)}
              min={0}
            />
          </FormInput>

          <FormInput label={"Nb total de résidents jeunes"}>
            <InputNumber
              value={totalJuniorNb}
              placeholder="Veuillez saisir nombre de jeunes"
              onChange={(e) => setTotalJuniorNb(e.value)}
              min={0}
            />
          </FormInput>

          <FormInput label={"Nb de seniors actuel"}>
            <InputNumber
              value={currentSeniorNb}
              placeholder="Veuillez saisir nombre de seniors"
              onChange={(e) => setCurrentSeniorNb(e.value)}
              min={0}
            />
          </FormInput>

          <FormInput label={"Nb de jeunes actuels"}>
            <InputNumber
              value={currentJuniorNb}
              placeholder="Veuillez saisir nombre de juniors"
              onChange={(e) => setCurrentJuniorNb(e.value)}
              min={0}
            />
          </FormInput>

          <FormInput label={"Date de fin du financement"}>
            <Calendar
              value={fundingEndDt}
              onChange={(e) => setFundingEndDt(e.value)}
              dateFormat="dd/mm/yy"
              placeholder="Veuillez selectionner la date"
              locale="fr"
            />
          </FormInput>

          <FormInput label={"Signature terrain"}>
            <Calendar
              value={landSignatureDt}
              onChange={(e) => setLandSignatureDt(e.value)}
              dateFormat="dd/mm/yy"
              placeholder="Veuillez selectionner la date"
              locale="fr"
            />
          </FormInput>

          <FormInput label={"Dépôt permis de construire"}>
            <Calendar
              value={buildingPermitDepositDt}
              onChange={(e) => setBuildingPermitDepositDt(e.value)}
              dateFormat="dd/mm/yy"
              placeholder="Veuillez selectionner la date"
              locale="fr"
            />
          </FormInput>

          <FormInput label={"Début du chantier"}>
            <Calendar
              value={constructionStartDt}
              onChange={(e) => setConstructionStartDt(e.value)}
              dateFormat="dd/mm/yy"
              placeholder="Veuillez selectionner la date"
              locale="fr"
            />
          </FormInput>

          <FormInput label={"Fin du chantier"}>
            <Calendar
              value={constructionEndDt}
              onChange={(e) => setConstructionEndDt(e.value)}
              dateFormat="dd/mm/yy"
              placeholder="Veuillez selectionner la date"
              locale="fr"
            />
          </FormInput>

          <FormInput label={"Date d'inauguration"}>
            <Calendar
              value={inaugurationDt}
              onChange={(e) => setInaugurationDt(e.value)}
              dateFormat="dd/mm/yy"
              placeholder="Veuillez selectionner la date"
              locale="fr"
            />
          </FormInput>
        </div>

        {footerButtons}
      </div>
    </Sidebar>
  );
}

export default ManageResidence;
