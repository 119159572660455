import React, { useRef } from "react";
import { TransactionDatatable } from "../transaction/TransactionDatatable";
import Data from "../../components/Data";
import DataTag from "../../components/DataTag";
import DocumentList from "../../components/DocumentList";
import { CONSTANTS } from "../../ressources/CONSTANTS";
import moment from "moment";

export const InvestorDetail = ({
  investor,
  selectTransaction,
  openDocumentModal,
}) => {
  const exportTransactionRef = useRef(null);

  const handleExistingDocument = (d) => {
    openDocumentModal(d);
  };

  const handleMissingDocument = (d) => {
    openDocumentModal(undefined, d.type);
  };

  return (
    <div className="section-wrapper">
      <section>
        <h4>Informations</h4>
        <div className="data-section">
          <Data key="name" label={"Nom"} value={investor.name} />
          <Data
            key="first_name"
            label={"Prénom"}
            value={investor.first_name}
            large
          />
          <DataTag key="college" label={"Collège"} value={investor.college} />
          <DataTag key="profil" label={"Profil"} value={investor.profile} />
          <DataTag
            key="intermediate"
            label={"Intermédiaire"}
            value={investor.intermediate?.name}
          />
          <Data key="id" label={"ID"} value={investor.id} />
        </div>
      </section>
      <section>
        <h4>Contact</h4>
        <div className="data-section">
          <Data key="phone" label={"Téléphone"} value={investor.phone} />
          <Data key="email" label={"Email"} value={investor.email} large />
          <Data
            key="address"
            label={"Adresse complète"}
            large
            value={
              investor.address + ", " + investor.zip_code + " " + investor.city
            }
          />
        </div>
      </section>

      <section>
        <h4>Autres informations</h4>
        <div className="data-section">
          <Data key="gender" label={"Civilité"} value={investor.gender} />
          <Data
            key="birthPlace"
            label={"Lieu de naissance"}
            value={investor.birth_place}
            large
          />
          <Data
            key="birthDate"
            label={"Date de naissance"}
            value={investor.birth_dt}
            type={"date"}
          />
        </div>
      </section>

      <section>
        <h4>Transactions</h4>
        <TransactionDatatable
          exportRef={exportTransactionRef}
          data={investor}
          exportFileName={`export_transactions_${investor.name}_${
            investor.last_name
          }_${moment().format("DD/MM/YYYY")}`}
          selectTransaction={selectTransaction}
        />
      </section>
      <section>
        <h4>Documents</h4>
        <DocumentList
          handleMissingDocument={handleMissingDocument}
          handleExistingDocument={handleExistingDocument}
          requiredDocuments={CONSTANTS.INVESTOR_DOCUMENTS}
          documents={investor.documents}
        />
      </section>
    </div>
  );
};
