import React from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { FilterMatchMode } from "primereact/api";
import { CONSTANTS } from "../../ressources/CONSTANTS";
import Tag from "../../components/Tag";

export const UserDatatable = ({ data, selectItem }) => {
  const typeBodyTemplate = ({ type }) => {
    const currentType = CONSTANTS.ACCOUNT_TYPES.find((at) => at.value === type);
    return (
      <div className="cell-container">
        <Tag value={currentType.label} bgColor={currentType.color} />
      </div>
    );
  };

  const authorizationBadyTemplate = (scope) => {
    return (
      <div className="cell-container row-gap-xs">
        {scope.authorizations.map((auth) => (
          <Tag
            key={auth.id}
            value={CONSTANTS.SUBAPP_LABELS[scope.name][auth.subapp]}
            bgColor={auth.type !== "FORBIDDEN" && "bg-custom-green"}
            icon={CONSTANTS.AUTH_ICONS[auth.type]}
          />
        ))}
      </div>
    );
  };

  return (
    <DataTable
      value={data}
      removableSort
      scrollable={true}
      emptyMessage={"Pas d'utilisateur à afficher"}
      paginator
      rows={25}
      rowsPerPageOptions={[25, 50, 100]}
      filterDisplay="row"
      selectionMode="single"
      onRowSelect={(e) => selectItem(e.data)}
    >
      <Column
        sortable
        field="type"
        header="Role"
        body={typeBodyTemplate}
      ></Column>
      <Column
        sortable
        className="font-bold"
        field="alias"
        header="Nom & prénom"
        filter
        filterMatchMode={FilterMatchMode.CONTAINS}
        showFilterMenu={false}
      ></Column>
      <Column
        sortable
        field="email"
        header="Email"
        filter
        filterMatchMode={FilterMatchMode.CONTAINS}
        showFilterMenu={false}
      ></Column>
      <Column
        sortable
        field="scope"
        header="Autorisations Terrafine"
        body={(rowData) =>
          authorizationBadyTemplate(
            rowData.scopes.find((s) => s.name === "terrafine")
          )
        }
      ></Column>
      <Column
        sortable
        field="scope"
        header="Autorisations Résid'ens"
        body={(rowData) =>
          authorizationBadyTemplate(
            rowData.scopes.find((s) => s.name === "residens")
          )
        }
      ></Column>
    </DataTable>
  );
};
