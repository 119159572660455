import React from "react";
import ImageItem from "../../components/Image/ImageItem";
import Data from "../../components/Data";
import DataTag from "../../components/DataTag";
import DocumentList from "../../components/DocumentList";

export const ResidenceDetail = ({
  residence,
  openDocumentModal,
  openImageModal,
  isReadWrite,
}) => {
  const displayImages = () => {
    if (residence && residence.photos && residence.photos.length) {
      return (
        <div className="row-gap">
          {residence.photos.map((image) => (
            <ImageItem
              key={image.id}
              image={image}
              onClick={() => isReadWrite && openImageModal(image)}
            />
          ))}
        </div>
      );
    } else
      return <div style={{ fontStyle: "italic" }}>Aucune image disponible</div>;
  };

  return (
    <div className="section-wrapper">
      <section>
        <h4>Informations</h4>
        <div className="data-section">
          <Data key="name" label={"Nom"} value={residence.name} large />
          <DataTag key="state" label={"Etat"} value={residence.state} />
          <DataTag key="type" label={"Type"} value={residence.type} />
        </div>
      </section>

      <section>
        <h4>Localisation</h4>
        <div className="data-section">
          <Data
            key="address"
            label={"Adresse"}
            value={residence.address}
            large
          />
          <Data
            key="city"
            label={"Ville"}
            value={residence.city ? residence.city.name : ""}
          />
          <Data
            key="zip"
            label={"Code postal"}
            value={residence.city ? residence.city.zip_code : ""}
          />
        </div>
      </section>

      <section>
        <h4>Financement</h4>
        <div className="data-section">
          <Data
            key="totalBudget"
            label={"Budget total"}
            value={residence.total_budget}
            type="currency"
          />
          <Data
            key="collectedFund"
            label={"Fonds collectés"}
            value={residence.collected_fund}
            type="currency"
          />
        </div>
      </section>

      <section>
        <h4>Logements</h4>
        <div className="data-section">
          <Data
            key="totalSeniorHousingNb"
            label={"Nb total de logements seniors"}
            value={residence.total_senior_housing_nb}
            large
          />
          <Data
            key="totalJuniorHousingNb"
            label={"Nb total de logements jeunes"}
            value={residence.total_junior_housing_nb}
            large
          />
          <Data
            key="occupiedSeniorHousingNb"
            label={"Nb de logements occupés seniors"}
            value={residence.occupied_senior_housing_nb}
            large
          />
          <Data
            key="occupiedJuniorHousingNb"
            label={"Nb de logements occupés jeunes"}
            value={residence.occupied_junior_housing_nb}
            large
          />
          <Data
            key="totalSeniorInhabitantNb"
            label={"Nb total de résidents seniors"}
            value={residence.total_senior_inhabitant_nb}
            large
          />
          <Data
            key="totalJuniorInhabitantNb"
            label={"Nb total de résidents jeunes"}
            value={residence.total_junior_inhabitant_nb}
            large
          />
          <Data
            key="occupiedSeniorInhabitantNb"
            label={"Nb de résidents actuels seniors"}
            value={residence.occupied_senior_inhabitant_nb}
            large
          />
          <Data
            key="occupiedJuniorInhabitantNb"
            label={"Nb de résidents actuels jeunes"}
            value={residence.occupied_junior_inhabitant_nb}
            large
          />
        </div>
      </section>

      <section>
        <h4>Dates</h4>
        <div className="data-section">
          <Data
            key="closingDate"
            label={"Closing financement"}
            value={residence.funding_end_dt}
            large
            type="date"
          />
          <Data
            key="signatureDate"
            label={"Signature terrain"}
            value={residence.land_signature_dt}
            large
            type="date"
          />
          <Data
            key="permitDate"
            label={"Dépôt permis de construire"}
            value={residence.building_permit_deposit_dt}
            large
            type="date"
          />
          <Data
            key="constructionStartDate"
            label={"Début du chantier"}
            value={residence.construction_start_dt}
            large
            type="date"
          />
          <Data
            key="constructionEndDate"
            label={"Fin du chantier"}
            value={residence.construction_end_dt}
            large
            type="date"
          />
          <Data
            key="openingDate"
            label={"Ouverture"}
            value={residence.inauguration_dt}
            large
            type="date"
          />
        </div>
      </section>

      <section>
        <h4>Autres informations</h4>
        <div className="data-section">
          <Data
            key="internalNotes"
            label={"Notes internes"}
            value={residence.internal_notes}
            large
          />
          <Data
            key="publicDescription"
            label={"Description publique"}
            value={residence.description}
            large
          />
        </div>
      </section>
      <section>
        <h4>Photos</h4>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginTop: "1vh",
            overflowX: "auto",
            scrollbarWidth: "thin",
          }}
        >
          {displayImages()}
        </div>
      </section>

      <section>
        <h4>Documents</h4>
        <DocumentList
          handleExistingDocument={openDocumentModal}
          requiredDocuments={[]}
          documents={residence && residence.documents}
        />
      </section>
    </div>
  );
};
