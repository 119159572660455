import React from "react";
import moment from "moment";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import {
  applyCurrencyFormat,
  applyHectareFormatFromArea,
} from "../../utils/datatableHelper";

export const DashboardProjectDatatable = ({ loading, data }) => {
  const footerGroup = (
    <ColumnGroup>
      <Row>
        <Column footer="Totaux" />
        <Column footer={`${data.total_count} projets`} />
        <Column footer={`${applyCurrencyFormat(data.total_amount)}`} />
        <Column footer={`${applyHectareFormatFromArea(data.total_surface)}`} />
        <Column
          footer={`${applyHectareFormatFromArea(data.total_acquired_surface)}`}
        />
        <Column
          footer={`${applyHectareFormatFromArea(data.total_sie_surface)}`}
        />
      </Row>
    </ColumnGroup>
  );

  return (
    <DataTable
      value={data.data}
      emptyMessage={"Pas de donnée à afficher"}
      loading={loading}
      exportFilename={`export_dashboard_${moment().format("DD/MM/YYYY")}`}
      csvSeparator=";"
      footerColumnGroup={loading ? null : footerGroup}
    >
      <Column className="font-bold" field="year" header="Année"></Column>
      <Column field="count" header="Nb projets"></Column>
      <Column
        dataType="numeric"
        field="amount"
        header="Montant total"
        body={(rowData) => applyCurrencyFormat(rowData.amount)}
      ></Column>
      <Column
        dataType="numeric"
        field="surface"
        header="SAU totale"
        body={(rowData) => applyHectareFormatFromArea(rowData.surface)}
      ></Column>
      <Column
        dataType="numeric"
        field="acquired_surface"
        header="Surface acquise"
        body={(rowData) => applyHectareFormatFromArea(rowData.acquired_surface)}
      ></Column>
      <Column
        dataType="numeric"
        field="sie_surface"
        header="Surface d'Interet Ecologique"
        body={(rowData) => applyHectareFormatFromArea(rowData.sie_surface)}
      ></Column>
    </DataTable>
  );
};
