import React, { Component } from "react";
//props
//styleText : dark, light
//text
//bgColor
//onClickButton
//icon : url icone
//disabled

function Button(props) {
  return (
    <div
      className="app-button"
      onClick={() =>
        props.disabled ? console.log("nothing") : props.onClick()
      }
      style={{
        height: "4vh",
        width: "auto",
        backgroundColor: props.bgColor ? props.bgColor : "#FFFFFF",
        display: "flex",
        alignItems: "center",
        paddingRight: "0.5vw",
        paddingLeft: "0.5vw",
        opacity: props.disabled ? "50%" : "100%",
        cursor: !props.disabled ? "pointer" : "default",
        fontSize: "1.6vh",
        fontWeight: 600,
        color: props.styleText === "light" ? "#FFFFFF" : "#2B2B2B",
      }}
    >
      {props.loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "2.5vh",
            marginRight: props.text ? "0.5vw" : null,
          }}
        >
          {"Chargement..."}
        </div>
      ) : (
        <div
          style={{
            height: "2.1vh",
            width: "2.5vh",
            marginRight: props.text ? "0.5vw" : null,
            backgroundSize: "contain",
            backgroundImage: `url(${props.icon})`,
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        />
      )}

      {props.text}
    </div>
  );
}

export default Button;
