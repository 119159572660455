import React, { Component } from "react";
import { COLORS } from "../ressources/STYLING";
import { MEDIAS } from "../ressources/MEDIAS";

function numberWithSpaces(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

function KeyIndicatorButton(props) {
  return (
    <div
      onClick={props.onClick}
      className="key-indicator-button-container"
      style={{
        outlineWidth: props.isSelected ? 2 : 0,
        outlineColor:
          props.scope === "terrafine" ? COLORS.greenModal : COLORS.purpleModal,
        marginRight: "1vw",
      }}
    >
      {/* ICON */}
      <img
        src={MEDIAS[props.ki.icon]}
        style={{
          height: "5vh",
          width: "5vh",
        }}
      />
      {/* LABEL */}
      <div
        style={{
          height: "auto",
          width: "70%",
          fontSize: "0.8vw",
          fontWeight: 500,
        }}
      >
        {props.ki.label}
      </div>
      {/* VALUE */}
      <div
        style={{
          height: "auto",
          width: "auto",
          fontSize: "1vw",
          fontWeight: 600,
        }}
      >
        {numberWithSpaces(Math.round(props.ki.value)) + " €"}
      </div>
    </div>
  );
}

export default KeyIndicatorButton;
