import React, { useState } from "react";
import moment from "moment";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { FilterMatchMode } from "primereact/api";
import { applyCurrencyFormat } from "../../utils/datatableHelper";
import { CONSTANTS } from "../../ressources/CONSTANTS";
import Tag from "../../components/Tag";

export const ResidenceDatatable = ({ data, selectItem, exportRef }) => {
  const [filters, setFilters] = useState(null);
  const stateBodyTemplate = (rowData) => {
    if (!rowData.state) {
      return null;
    }
    return (
      <div className="cell-container">
        <Tag
          value={rowData.state}
          bgColor={
            CONSTANTS.RESIDENCE_STATES.find((rs) => rs.label === rowData.state)
              .color
          }
        />
      </div>
    );
  };

  return (
    <DataTable
      ref={exportRef}
      value={data}
      removableSort
      emptyMessage={"Pas de résidence à afficher"}
      scrollable={true}
      paginator
      rows={25}
      rowsPerPageOptions={[25, 50, 100]}
      filters={filters}
      onFilter={(e) => setFilters(e.filters)}
      filterDisplay="row"
      selectionMode="single"
      onRowSelect={(e) => selectItem(e.data)}
      exportFilename={`export_residences_${moment().format("DD/MM/YYYY")}`}
      csvSeparator=";"
    >
      <Column
        sortable
        className="font-bold"
        field="name"
        header="Nom"
        filter
        filterMatchMode={FilterMatchMode.CONTAINS}
        showFilterMenu={false}
      ></Column>
      <Column field="state" header="État" body={stateBodyTemplate}></Column>
      <Column
        field="city.name"
        filter
        filterMatchMode={FilterMatchMode.CONTAINS}
        showFilterMenu={false}
        header="Ville"
      ></Column>
      <Column
        field="city.zip_code"
        filter
        filterMatchMode={FilterMatchMode.CONTAINS}
        showFilterMenu={false}
        header="Code postal"
      ></Column>

      <Column
        dataType="numeric"
        sortable
        field="total_budget"
        header="Budget Total"
        body={(rowData) => applyCurrencyFormat(rowData.total_budget)}
      ></Column>

      <Column
        dataType="numeric"
        sortable
        field="collected_fund"
        header="Fonds collectés"
        body={(rowData) => applyCurrencyFormat(rowData.collected_fund)}
      ></Column>
    </DataTable>
  );
};
