import React, { useEffect, useState, useRef } from "react";
import { COLORS } from "../../../ressources/STYLING";
import { MEDIAS } from "../../../ressources/MEDIAS";
import { privateFetch } from "../../../utils/apiHelper";
import TitlePage from "../../../components/TitlePage";
import Button from "../../../components/Button";
import IntermediateDatatable from "../../../modules/intermediate/IntermediateDatatable";
import IntermediateDetail from "../../../modules/intermediate/IntermediateDetail";
import ManageIntermediate from "../../../modules/managers/ManageIntermediate";
import ManageIntermediateAccount from "../../../modules/managers/ManageIntermediateAccount";
import ManageDocument from "../../../modules/managers/ManageDocument";
import "../../Pages.scss";
import { useAccountContext } from "../../../contexts/AccountContext";
import { useAuthorizationsContext } from "../../../contexts/AuthorizationsContext";
import { confirmDialog } from "primereact/confirmdialog";
import { CONSTANTS } from "../../../ressources/CONSTANTS";

function IntermediatePage() {
  const exportRef = useRef(null);
  const datatablelRef = useRef(null);
  const detailRef = useRef(null);
  const {
    authorizationsContext,
    getActiveScopesNames,
    getActiveScope,
    isOverviewMode,
    getSubappAuthorizationType,
  } = useAuthorizationsContext();
  const { accountContext } = useAccountContext();

  const [intermediateList, setIntermediateList] = useState([]);
  const [intermediate, setIntermediate] = useState(null);
  const [intermediateAccount, setIntermediateAccount] = useState(null);
  const [intermediateModuleOpen, setIntermediateModuleOpen] = useState(false);
  const [intermediateAccountModuleOpen, setIntermediateAccountModuleOpen] =
    useState(false);
  const [document, setDocument] = useState(null);
  const [mandatoryDefaultType, setMandatoryDefaultType] = useState(undefined);
  const [documentModuleOpen, setDocumentModuleOpen] = useState(false);
  const [scrollPosition, setScrollPosition] = useState("LEFT");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchIntermediateList();
    intermediate && fetchIntermediateDetail(intermediate);
  }, [authorizationsContext]);

  useEffect(() => {
    if (scrollPosition === "CENTER" && intermediate) {
      detailRef.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "end",
      });
    } else {
      datatablelRef.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "end",
      });
    }
  }, [scrollPosition]);

  const isReadWrite = () => {
    if (isOverviewMode()) return false;
    return (
      getSubappAuthorizationType(getActiveScope().name, "INTERMEDIATE") ===
      "READ_WRITE"
    );
  };

  const isDocumentReadWrite = () => {
    // If User has READ_WRITE auth for at least one scope
    return authorizationsContext.some(
      (s) => getSubappAuthorizationType(s.name, "INTERMEDIATE") === "READ_WRITE"
    );
  };

  const fetchIntermediateList = async () => {
    await privateFetch(
      "GET",
      `/cu/${accountContext.id}/intermediate?scopes=${getActiveScopesNames()}`
    ).then((res) => {
      if (res) setIntermediateList(res);
    });
  };

  const fetchIntermediateDetail = async (intermediate) => {
    await privateFetch(
      "GET",
      `/cu/${accountContext.id}/intermediate/${
        intermediate.id
      }/?scopes=${getActiveScopesNames()}`,
      `Cet intermédiaire n'est pas lié à l'app sélectionnée.`
    ).then((res) => {
      if (res) {
        setIntermediate(res);
      } else {
        setScrollPosition("LEFT");
        setIntermediate(undefined);
      }
    });
  };

  const createIntermediate = async (intermediateForm) => {
    setLoading(true);
    await privateFetch(
      "POST",
      `/cu/${accountContext.id}/intermediate/`,
      "Une erreur est survenue lors de la création de l'intermédiaire",
      "L'intermédiaire a bien été créé.",
      JSON.stringify({
        ...intermediateForm,
        linked_scopes: [getActiveScope().id],
      })
    ).then((response) => {
      if (response) {
        setLoading(false);
        setIntermediateModuleOpen(false);
        fetchIntermediateList();
        fetchIntermediateDetail(response).then(() =>
          setScrollPosition("CENTER")
        );
      }
    });
  };

  const updateIntermediate = async (intermediateForm) => {
    setLoading(true);
    await privateFetch(
      "PUT",
      `/cu/${accountContext.id}/intermediate/${
        intermediate.id
      }/?scopes=${getActiveScopesNames()}`,
      "Une erreur est survenue lors de la modification de l'intermédiaire",
      "L'intermédiaire a bien été modifié.",
      JSON.stringify(intermediateForm)
    ).then((res) => {
      fetchIntermediateDetail(intermediate);
      setIntermediateModuleOpen(false);
      setLoading(false);
    });
  };

  const deleteIntermediate = async () => {
    setLoading(true);
    await privateFetch(
      "DELETE",
      `/cu/${accountContext.id}/intermediate/${
        intermediate.id
      }/?scopes=${getActiveScopesNames()}`,
      "Une erreur est survenue lors de la suppression de l'intermédiaire",
      "L'intermédiaire a bien été supprimé."
    ).then((res) => {
      fetchIntermediateList();
      setScrollPosition("LEFT");
      setIntermediateModuleOpen(false);
      setLoading(false);
    });
  };

  const updateIntermediateScope = async (intermediate) => {
    setLoading(true);
    await privateFetch(
      "PUT",
      `/cu/${accountContext.id}/intermediate/${intermediate.id}/scope/?scope=${
        getActiveScope().name
      }`,
      "Une erreur est survenue lors de la modification de l'intermédiaire",
      "L'intermédiaire a bien été modifié.",
      JSON.stringify({
        ...intermediate,
        linked_scopes: [
          ...intermediate.linked_scopes.map((ls) => ls.id),
          getActiveScope().id,
        ],
      })
    ).then((response) => {
      if (response) {
        setLoading(false);
        setIntermediateModuleOpen(false);
        fetchIntermediateList();
        fetchIntermediateDetail(intermediate).then(() =>
          setScrollPosition("CENTER")
        );
      }
    });
  };

  const createIntermediateAccount = async (accountForm) => {
    setLoading(true);
    await privateFetch(
      "POST",
      `/cu/${accountContext.id}/intermediate/${intermediate.id}/account/`,
      null,
      "Le compte utilisateur a bien été créé.",
      JSON.stringify(accountForm)
    ).then((response) => {
      if (response) {
        setLoading(false);
        setIntermediateAccountModuleOpen(false);
        fetchIntermediateList();
        fetchIntermediateDetail(intermediate);
      }
    });
  };

  const updateIntermediateAccount = async (accountForm) => {
    setLoading(true);
    await privateFetch(
      "PUT",
      `/cu/${accountContext.id}/intermediate/${intermediate.id}/account/${intermediateAccount.id}/`,
      "Une erreur est survenue lors de la modification du compte utilisateur",
      "Le compte utilisateur a bien été modifié.",
      JSON.stringify(accountForm)
    ).then((response) => {
      if (response) {
        setLoading(false);
        setIntermediateAccountModuleOpen(false);
        fetchIntermediateList();
        fetchIntermediateDetail(intermediate);
      }
    });
  };

  const deleteIntermediateAccount = async () => {
    setLoading(true);
    await privateFetch(
      "DELETE",
      `/cu/${accountContext.id}/intermediate/${intermediate.id}/account/${intermediateAccount.id}/`,
      "Une erreur est survenue lors de la suppression du compte intermédiaire",
      "Le compte intermédiaire a bien été supprimé."
    ).then((res) => {
      fetchIntermediateList();
      fetchIntermediateDetail(intermediate);
      setIntermediateAccount(undefined);
      setLoading(false);
    });
  };

  const uploadDocument = async (documentForm) => {
    setLoading(true);
    await privateFetch(
      "POST",
      `/cu/${accountContext.id}/intermediate/${intermediate.id}/document/`,
      "Une erreur est survenue lors de l'ajout de document.",
      "Le document a bien été ajoutée.",
      documentForm,
      false,
      true
    ).then(() => {
      setLoading(false);
      setDocumentModuleOpen(false);
      fetchIntermediateDetail(intermediate);
    });
  };

  const updateDocument = async (document, documentForm) => {
    setLoading(true);
    await privateFetch(
      "PUT",
      `/cu/${accountContext.id}/document/${document.id}/`,
      "Une erreur est survenue lors de la modification du document.",
      "Le document a bien été modifié.",
      documentForm,
      false,
      true
    ).then(() => {
      setLoading(false);
      setDocumentModuleOpen(false);
      fetchIntermediateDetail(intermediate);
    });
  };

  const deleteDocument = async (document) => {
    setLoading(true);
    await privateFetch(
      "DELETE",
      `/cu/${accountContext.id}/document/${document.id}/`,
      "Une erreur est survenue lors de la suppression du document.",
      "Le document a bien été supprimé."
    ).then(() => {
      setLoading(false);
      setDocumentModuleOpen(false);
      fetchIntermediateDetail(intermediate);
    });
  };

  const handleIntermediateSelection = async (targetIntermediate) => {
    if (!intermediate || targetIntermediate.id !== intermediate.id) {
      await fetchIntermediateDetail(targetIntermediate);
    }
    setScrollPosition("CENTER");
  };

  const downloadCsvExport = () => {
    exportRef.current.exportCSV();
  };

  const confirmDocumentDeletion = (document) => {
    setDocumentModuleOpen(false);
    confirmDialog({
      message: `Êtes-vous sûr de vouloir supprimer le document ${document.name} ?`,
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      acceptLabel: "Oui",
      rejectLabel: "Annuler",
      accept: () => deleteDocument(document),
    });
  };

  const confirmIntermediaiteDeletion = (intermediate) => {
    setIntermediateModuleOpen(false);
    confirmDialog({
      message: `Êtes-vous sûr de vouloir supprimer l'intermédiaire ${intermediate.name} et tous les comptes associés ?`,
      header: "Action irrévesrible",
      icon: "pi pi-exclamation-triangle",
      acceptLabel: "Oui",
      rejectLabel: "Annuler",
      accept: () => deleteIntermediate(intermediate),
    });
  };

  const confirmIntermediaiteAccountDeletion = (intermediateAccount) => {
    setIntermediateAccountModuleOpen(false);
    confirmDialog({
      message: `Êtes-vous sûr de vouloir supprimer le compte intermédiaire ${intermediateAccount.alias} ?`,
      header: "Action irrévesrible",
      icon: "pi pi-exclamation-triangle",
      acceptLabel: "Oui",
      rejectLabel: "Annuler",
      accept: () => deleteIntermediateAccount(intermediateAccount),
    });
  };

  return (
    <div className="page-wrapper">
      {/* Header */}
      <div className="header-container">
        <div className="breadcrumb-container">
          <TitlePage
            text={"Intermédiaires"}
            isActive={scrollPosition === "LEFT"}
            scrollTarget={"LEFT"}
            allowNavigation={true}
            handleNavigation={() => setScrollPosition("LEFT")}
          />
          {intermediate && <img src={MEDIAS.rightArrow} className="arrow" />}
          {intermediate && (
            <TitlePage
              text={intermediate.name}
              allowNavigation={true}
              handleNavigation={() => setScrollPosition("CENTER")}
              isActive={scrollPosition === "CENTER"}
              scrollTarget={"CENTER"}
            />
          )}
        </div>
        <div className="buttons-container">
          {scrollPosition === "LEFT" && (
            <Button
              key={"exportCsv"}
              text={"Export CSV"}
              bgColor={COLORS.greyButton}
              styleText={"light"}
              icon={MEDIAS.download}
              onClick={downloadCsvExport}
            />
          )}
          {scrollPosition === "LEFT" && isReadWrite() && (
            <Button
              key={"new-intermediate"}
              text={"Intermediaire"}
              bgColor={COLORS.greenButton}
              styleText={"light"}
              icon={MEDIAS.crossAdd}
              onClick={() => {
                setIntermediate(undefined);
                setIntermediateModuleOpen(true);
              }}
            />
          )}
          {scrollPosition === "CENTER" && isReadWrite() && (
            <Button
              text={"Modifier"}
              bgColor={COLORS.white}
              styleText={"dark"}
              icon={MEDIAS.editorBlack}
              onClick={() => setIntermediateModuleOpen(true)}
            />
          )}
          {scrollPosition === "CENTER" && isReadWrite() && (
            <Button
              key={"new-intermediate-account"}
              text={"Compte utilisateur"}
              bgColor={COLORS.greenButton}
              styleText={"light"}
              icon={MEDIAS.crossAdd}
              onClick={() => {
                setIntermediateAccount(undefined);
                setIntermediateAccountModuleOpen(true);
              }}
            />
          )}
          {scrollPosition === "CENTER" && isDocumentReadWrite() && (
            <Button
              text={"Document"}
              bgColor={COLORS.greenButton}
              styleText={"light"}
              icon={MEDIAS.crossAdd}
              onClick={() => {
                setDocument(undefined);
                setDocumentModuleOpen(true);
                setMandatoryDefaultType(undefined);
              }}
            />
          )}
        </div>
      </div>
      {/* Content */}
      <div className="content-container hidden-scrollbar">
        <div
          ref={datatablelRef}
          className="section-container full-page-datatable"
        >
          <IntermediateDatatable
            exportRef={exportRef}
            data={intermediateList}
            selectItem={handleIntermediateSelection}
          />
        </div>
        <div ref={detailRef} className="section-container">
          {intermediate && (
            <IntermediateDetail
              intermediate={intermediate}
              selectIntermediateAccount={(account) => {
                if (isReadWrite()) {
                  setIntermediateAccount(account);
                  setIntermediateAccountModuleOpen(true);
                }
              }}
              openDocumentModal={(document, defaultType) => {
                setDocument(document);
                setDocumentModuleOpen(true);
                setMandatoryDefaultType(defaultType);
              }}
            />
          )}
        </div>
      </div>

      {/* Modules */}
      <ManageIntermediate
        intermediate={intermediate}
        isOpen={intermediateModuleOpen}
        handleClose={() => setIntermediateModuleOpen(false)}
        handleCreation={createIntermediate}
        handleUpdate={updateIntermediate}
        handleDelete={() => confirmIntermediaiteDeletion(intermediate)}
        handleScopeUpdate={updateIntermediateScope}
        loading={loading}
      />

      <ManageIntermediateAccount
        intermediate={intermediate}
        intermediateAccount={intermediateAccount}
        isOpen={intermediateAccountModuleOpen}
        handleClose={() => setIntermediateAccountModuleOpen(false)}
        handleCreate={createIntermediateAccount}
        handleUpdate={updateIntermediateAccount}
        handleDelete={() =>
          confirmIntermediaiteAccountDeletion(intermediateAccount)
        }
        loading={loading}
      />

      <ManageDocument
        loading={loading}
        document={document}
        options={CONSTANTS.INTERMEDIATE_DOCUMENTS}
        mandatoryType={mandatoryDefaultType || "other"}
        isOpen={documentModuleOpen}
        handleClose={() => setDocumentModuleOpen(false)}
        uploadDocument={uploadDocument}
        updateDocument={(documentForm) =>
          updateDocument(document, documentForm)
        }
        deleteDocument={() => confirmDocumentDeletion(document)}
        isReadWrite={isDocumentReadWrite()}
      />
    </div>
  );
}

export default IntermediatePage;
