import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { privateFetch, refreshAccesToken } from "../../utils/apiHelper";
import { useAccountContext } from "../../contexts/AccountContext";
import { useAuthorizationsContext } from "../../contexts/AuthorizationsContext";

function AppLoader() {
  const { accountContext, setAccountContext } = useAccountContext();
  const { authorizationsContext, initScopes } = useAuthorizationsContext();
  const navigate = useNavigate();

  const fetchAccountDetail = async () => {
    await privateFetch("GET", `/me/`).then((response) => {
      setAccountContext(response);
    });
  };

  const fetchAccountAuthorizations = async () => {
    await privateFetch("GET", `/me/authorizations/`).then((response) => {
      initScopes(response);
    });
  };

  const refreshSession = async () => {
    return await refreshAccesToken();
  };

  useEffect(() => {
    refreshSession().then((isSessionValid) => {
      if (!isSessionValid) {
        navigate("/login");
      } else {
        fetchAccountDetail();
        fetchAccountAuthorizations();
      }
    });
  }, []);

  useEffect(() => {
    if (accountContext && authorizationsContext) {
      navigate("/home");
    }
  }, [accountContext, authorizationsContext]);

  return <div className="app-loader-container">Chargement...</div>;
}

export default AppLoader;
