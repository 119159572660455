import React, { useState, useEffect, useRef } from "react";
import { COLORS } from "../../../ressources/STYLING";
import { MEDIAS } from "../../../ressources/MEDIAS";
import { privateFetch } from "../../../utils/apiHelper";
import TitlePage from "../../../components/TitlePage";
import Button from "../../../components/Button";
import { ResidenceDatatable } from "../../../modules/residence/ResidenceDatatable";
import { ResidenceDetail } from "../../../modules/residence/ResidenceDetail";
import { useAuthorizationsContext } from "../../../contexts/AuthorizationsContext";
import { useAccountContext } from "../../../contexts/AccountContext";
import { confirmDialog } from "primereact/confirmdialog";
import ManageDocument from "../../../modules/managers/ManageDocument";
import ManageResidence from "../../../modules/managers/ManageResidence";
import ManageImage from "../../../modules/managers/ManageImage";
import "../../Pages.scss";

function ResidencePage({ hidden }) {
  const exportRef = useRef(null);
  const datatablelRef = useRef(null);
  const detailRef = useRef(null);

  const {
    authorizationsContext,
    getActiveScopesNames,
    getActiveScope,
    isOverviewMode,
    getSubappAuthorizationType,
  } = useAuthorizationsContext();

  const { accountContext } = useAccountContext();

  const [residenceList, setResidenceList] = useState([]);
  const [residence, setResidence] = useState(null);
  const [residenceModuleOpen, setResidenceModuleOpen] = useState(false);
  const [document, setDocument] = useState(null);
  const [documentModuleOpen, setDocumentModuleOpen] = useState(false);
  const [image, setImage] = useState(null);
  const [imageModuleOpen, setImageModuleOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [scrollPosition, setScrollPosition] = useState("LEFT");

  useEffect(() => {
    fetchResidenceList();
  }, []);

  useEffect(() => {
    fetchResidenceList();
    residence && fetchResidenceDetail(residence);
  }, [authorizationsContext]);

  useEffect(() => {
    if (scrollPosition === "CENTER") {
      detailRef.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "end",
      });
    } else {
      datatablelRef.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "end",
      });
    }
  }, [scrollPosition]);

  const isReadWrite = () => {
    if (isOverviewMode()) return false;
    return (
      getSubappAuthorizationType(getActiveScope().name, "PROJECT") ===
      "READ_WRITE"
    );
  };

  const handleResidenceSelection = async (targetResidence) => {
    if (!residence || targetResidence.id !== residence.id) {
      await fetchResidenceDetail(targetResidence);
    }
    setScrollPosition("CENTER");
  };

  const fetchResidenceList = async () => {
    await privateFetch(
      "GET",
      `/cu/${accountContext.id}/residence?scopes=${getActiveScopesNames()}`
    ).then((res) => {
      if (res) setResidenceList(res);
    });
  };

  const fetchResidenceDetail = async (targetResidence) => {
    await privateFetch(
      "GET",
      `/cu/${accountContext.id}/residence/${
        targetResidence.id
      }/?scopes=${getActiveScopesNames()}`
    ).then((res) => {
      if (res) setResidence(res);
    });
  };

  const createResidence = async (residenceForm) => {
    setLoading(true);
    await privateFetch(
      "POST",
      `/cu/${accountContext.id}/residence/`,
      "Une erreur est survenue lors de la création de la residence",
      "La residence a bien été créé.",
      JSON.stringify({
        ...residenceForm,
        linked_scopes: [getActiveScope().id],
      })
    ).then((response) => {
      if (response) {
        setResidenceModuleOpen(false);
        setLoading(false);
        fetchResidenceList();
        fetchResidenceDetail(response).then(() => setScrollPosition("CENTER"));
      }
    });
  };

  const updateResidence = async (residenceForm) => {
    setLoading(true);
    await privateFetch(
      "PUT",
      `/cu/${accountContext.id}/residence/${residence.id}/`,
      "Une erreur est survenue lors de la modification de la residence",
      "La residence a bien été modifié.",
      JSON.stringify(residenceForm)
    ).then((response) => {
      if (response) {
        setLoading(false);
        setResidenceModuleOpen(false);
        fetchResidenceList();
        fetchResidenceDetail(response);
      }
    });
  };

  const deleteResidence = async () => {
    setLoading(true);
    await privateFetch(
      "DELETE",
      `/cu/${accountContext.id}/residence/${residence.id}/`,
      "Une erreur est survenue lors de la suppression de la résidence.",
      "La résidence a bien été supprimée."
    ).then((response) => {
      if (response) {
        setLoading(false);
        setScrollPosition("LEFT");
        setResidence(undefined);
        fetchResidenceList();
      }
    });
  };

  const downloadCsvExport = () => {
    exportRef.current.exportCSV();
  };

  const uploadDocument = async (documentForm) => {
    setLoading(true);
    documentForm.append("linked_scope", getActiveScope().id);
    await privateFetch(
      "POST",
      `/cu/${accountContext.id}/residence/${residence.id}/document/`,
      "Une erreur est survenue lors de l'ajout de document.",
      "Le document a bien été ajoutée.",
      documentForm,
      false,
      true
    ).then((response) => {
      if (response) {
        setLoading(false);
        setDocumentModuleOpen(false);
        fetchResidenceDetail(residence);
      }
    });
  };

  const updateDocument = async (documentForm) => {
    setLoading(true);
    await privateFetch(
      "PUT",
      `/cu/${accountContext.id}/document/${document.id}/`,
      "Une erreur est survenue lors de la modification du document.",
      "Le document a bien été modifié.",
      documentForm,
      false,
      true
    ).then((response) => {
      if (response) {
        setLoading(false);
        setDocumentModuleOpen(false);
        fetchResidenceDetail(residence);
      }
    });
  };

  const deleteDocument = async () => {
    setLoading(true);
    await privateFetch(
      "DELETE",
      `/cu/${accountContext.id}/document/${document.id}/`,
      "Une erreur est survenue lors de la suppression du document.",
      "Le document a bien été supprimé."
    ).then((response) => {
      if (response) {
        setLoading(false);
        setDocumentModuleOpen(false);
        fetchResidenceDetail(residence);
      }
    });
  };

  const confirmDocumentDeletion = () => {
    setDocumentModuleOpen(false);
    confirmDialog({
      message: `Êtes-vous sûr de vouloir supprimer le document ${document.name} ?`,
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      acceptLabel: "Oui",
      rejectLabel: "Annuler",
      accept: () => deleteDocument(),
    });
  };

  const uploadImage = async (imageForm) => {
    setLoading(true);
    imageForm.append("linked_scope", getActiveScope().id);
    await privateFetch(
      "POST",
      `/cu/${accountContext.id}/residence/${residence.id}/image/`,
      "Une erreur est survenue lors de l'ajout d'image.",
      "L'image a bien été ajoutée.",
      imageForm,
      false,
      true
    ).then((response) => {
      if (response) {
        setLoading(false);
        setImageModuleOpen(false);
        fetchResidenceDetail(residence);
      }
    });
  };

  const updateImage = async (imageForm) => {
    setLoading(true);
    imageForm.append("linked_scope", getActiveScope().id);
    await privateFetch(
      "PUT",
      `/cu/${accountContext.id}/image/${image.id}/`,
      "Une erreur est survenue lors de la modification de l'image.",
      "L'image a bien été modifié.",
      imageForm,
      false,
      true
    ).then((response) => {
      if (response) {
        setLoading(false);
        setImageModuleOpen(false);
        fetchResidenceDetail(residence);
      }
    });
  };

  const deleteImage = async () => {
    setLoading(true);
    await privateFetch(
      "DELETE",
      `/cu/${accountContext.id}/image/${image.id}/`,
      "Une erreur est survenue lors de la suppression de l'image.",
      "L'image a bien été supprimée."
    ).then((response) => {
      if (response) {
        setLoading(false);
        setImage(undefined);
        setImageModuleOpen(false);
        fetchResidenceDetail(residence);
      }
    });
  };

  const confirmResidenceDeletion = () => {
    setResidenceModuleOpen(false);
    confirmDialog({
      message: `Êtes-vous sûr de vouloir supprimer la résidence ${residence.name} ?`,
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      acceptLabel: "Oui",
      rejectLabel: "Annuler",
      accept: () => deleteResidence(),
    });
  };

  return (
    <div className="page-wrapper" hidden={hidden}>
      {/* Header */}
      <div className="header-container">
        {/* Breadcrumb */}
        <div className="breadcrumb-container">
          <TitlePage
            text={"Résidences"}
            isActive={scrollPosition === "LEFT"}
            scrollTarget={"LEFT"}
            allowNavigation={true}
            handleNavigation={() => setScrollPosition("LEFT")}
          />
          {residence && <img src={MEDIAS.rightArrow} className="arrow" />}
          {residence && (
            <TitlePage
              text={`${residence.name}`}
              allowNavigation={true}
              handleNavigation={() => setScrollPosition("CENTER")}
              isActive={scrollPosition === "CENTER"}
              scrollTarget={"CENTER"}
            />
          )}
        </div>
        {/* Buttons */}
        <div className="buttons-container">
          {scrollPosition === "LEFT" && (
            <Button
              key={"exportCsv"}
              text={"Export CSV"}
              bgColor={COLORS.greyButton}
              styleText={"light"}
              icon={MEDIAS.download}
              onClick={downloadCsvExport}
            />
          )}
          {scrollPosition === "LEFT" && isReadWrite() && (
            <Button
              key={"new-residence"}
              text={"Nouvelle residence"}
              bgColor={COLORS.white}
              styleText={"dark"}
              icon={MEDIAS.addUserBlack}
              onClick={() => {
                setResidence(undefined);
                setResidenceModuleOpen(true);
              }}
            />
          )}
          {scrollPosition === "CENTER" && isReadWrite() && (
            <Button
              text={"Modifier"}
              bgColor={COLORS.white}
              styleText={"dark"}
              icon={MEDIAS.editorBlack}
              onClick={() => setResidenceModuleOpen(true)}
            />
          )}

          {scrollPosition === "CENTER" && isReadWrite() && (
            <Button
              text={"Image"}
              bgColor={COLORS.greenButton}
              styleText={"light"}
              icon={MEDIAS.crossAdd}
              onClick={() => {
                setImage(undefined);
                setImageModuleOpen(true);
              }}
            />
          )}

          {scrollPosition === "CENTER" && isReadWrite() && (
            <Button
              text={"Document"}
              bgColor={COLORS.greenButton}
              styleText={"light"}
              icon={MEDIAS.crossAdd}
              onClick={() => {
                setDocument(undefined);
                setDocumentModuleOpen(true);
              }}
            />
          )}
        </div>
      </div>
      {/* Content */}
      <div className="content-container hidden-scrollbar">
        <div
          ref={datatablelRef}
          className="section-container full-page-datatable"
        >
          <ResidenceDatatable
            exportRef={exportRef}
            data={residenceList}
            selectItem={handleResidenceSelection}
          />
        </div>
        <div ref={detailRef} className="section-container">
          {residence && (
            <ResidenceDetail
              residence={residence}
              isReadWrite={isReadWrite()}
              openDocumentModal={(document) => {
                setDocument(document);
                setDocumentModuleOpen(true);
              }}
              openImageModal={(image) => {
                setImage(image);
                setImageModuleOpen(true);
              }}
            />
          )}
        </div>
      </div>

      {/* Modules */}
      <ManageResidence
        loading={loading}
        residence={residence}
        isOpen={residenceModuleOpen}
        handleClose={() => setResidenceModuleOpen(false)}
        handleCreation={createResidence}
        handleUpdate={updateResidence}
        handleDelete={confirmResidenceDeletion}
      />

      <ManageDocument
        loading={loading}
        document={document}
        isOpen={documentModuleOpen}
        isReadWrite={isReadWrite()}
        handleClose={() => setDocumentModuleOpen(false)}
        uploadDocument={uploadDocument}
        updateDocument={updateDocument}
        deleteDocument={confirmDocumentDeletion}
      />

      <ManageImage
        image={image}
        loading={loading}
        isOpen={imageModuleOpen}
        onClose={() => {
          setImage(null);
          setImageModuleOpen(false);
        }}
        onCreate={uploadImage}
        onUpdate={updateImage}
        onDelete={deleteImage}
      />
    </div>
  );
}

export default ResidencePage;
