import React, { useState, useEffect, useRef } from "react";
import { COLORS } from "../../../ressources/STYLING";
import { MEDIAS } from "../../../ressources/MEDIAS";
import { privateFetch, publicFetch } from "../../../utils/apiHelper";
import TitlePage from "../../../components/TitlePage";
import Button from "../../../components/Button";
import { BeneficiaryDatatable } from "../../../modules/beneficiary/BeneficiaryDatatable";
import { BeneficiaryDetail } from "../../../modules/beneficiary/BeneficiaryDetail";
import { ProjectDetail } from "../../../modules/project/ProjectDetail";
import { useAuthorizationsContext } from "../../../contexts/AuthorizationsContext";
import { useAccountContext } from "../../../contexts/AccountContext";
import ManageDocument from "../../../modules/managers/ManageDocument";
import ManageImage from "../../../modules/managers/ManageImage";
import "../../Pages.scss";
import ManageBeneficiary from "../../../modules/managers/ManageBeneficiary";
import ManageProject from "../../../modules/managers/ManageProject";
import { confirmDialog } from "primereact/confirmdialog";

function ProjectPage({ hidden }) {
  const exportRef = useRef(null);
  const datatablelRef = useRef(null);
  const beneficiaryDetailRef = useRef(null);
  const projectDetailRef = useRef(null);

  const {
    authorizationsContext,
    getActiveScopesNames,
    getActiveScope,
    isOverviewMode,
    getSubappAuthorizationType,
  } = useAuthorizationsContext();

  const { accountContext } = useAccountContext();
  const [productionTypeList, setProductionTypeList] = useState([]);
  const [beneficiaryList, setBeneficiaryList] = useState([]);
  const [beneficiary, setBeneficiary] = useState(null);
  const [beneficiaryModuleOpen, setBeneficiaryModuleOpen] = useState(false);
  const [project, setProject] = useState(null);
  const [projectModuleOpen, setProjectModuleOpen] = useState(false);
  const [document, setDocument] = useState(null);
  const [documentModuleOpen, setDocumentModuleOpen] = useState(false);
  const [image, setImage] = useState(null);
  const [imageModuleOpen, setImageModuleOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [datatableLoading, setDatatableLoading] = useState(true);
  const [scrollPosition, setScrollPosition] = useState("LEFT");

  useEffect(() => {
    fetchBeneficiaryList();
    fetchProductionTypes();
  }, []);

  useEffect(() => {
    fetchBeneficiaryList();
    beneficiary && fetchBeneficiaryDetail(beneficiary);
  }, [authorizationsContext]);

  useEffect(() => {
    if (!beneficiary) {
      setProject(undefined);
    } else {
      setProject(beneficiary.project);
    }
  }, [beneficiary]);

  useEffect(() => {
    beneficiary && fetchBeneficiaryDetail(beneficiary);
  }, [beneficiaryList]);

  const isReadWrite = () => {
    if (isOverviewMode()) return false;
    return (
      getSubappAuthorizationType(getActiveScope().name, "PROJECT") ===
      "READ_WRITE"
    );
  };

  useEffect(() => {
    if (scrollPosition === "CENTER") {
      beneficiaryDetailRef.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "end",
      });
    } else if (scrollPosition === "RIGHT") {
      projectDetailRef.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "end",
      });
    } else {
      datatablelRef.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "end",
      });
    }
  }, [scrollPosition]);

  const handleBeneficiarySelection = async (targetBeneficiary) => {
    if (!beneficiary || targetBeneficiary.id !== beneficiary.id) {
      await fetchBeneficiaryDetail(targetBeneficiary);
    }
    setScrollPosition("CENTER");
  };

  const fetchProductionTypes = async () => {
    await publicFetch("GET", `/production_types/`).then((res) => {
      if (res) setProductionTypeList(res);
    });
  };

  const fetchBeneficiaryList = async () => {
    await privateFetch(
      "GET",
      `/cu/${accountContext.id}/beneficiary?scopes=${getActiveScopesNames()}`
    ).then((res) => {
      if (res) setBeneficiaryList(res);
      setDatatableLoading(false);
    });
  };

  const fetchBeneficiaryDetail = async (targetBeneficiary) => {
    await privateFetch(
      "GET",
      `/cu/${accountContext.id}/beneficiary/${
        targetBeneficiary.id
      }/?scopes=${getActiveScopesNames()}`
    ).then((res) => {
      if (res) setBeneficiary(res);
    });
  };

  const createBeneficiary = async (beneficiaryForm) => {
    setLoading(true);
    await privateFetch(
      "POST",
      `/cu/${accountContext.id}/beneficiary/`,
      "Une erreur est survenue lors de la création du bénéficiaire",
      "Le bénéficiaire a bien été créé.",
      JSON.stringify(beneficiaryForm)
    ).then((response) => {
      if (response) {
        setBeneficiaryModuleOpen(false);
        setLoading(false);
        fetchBeneficiaryList();
        fetchBeneficiaryDetail(response).then(() =>
          setScrollPosition("CENTER")
        );
      }
    });
  };

  const updateBeneficiary = async (beneficiaryForm) => {
    setLoading(true);
    await privateFetch(
      "PUT",
      `/cu/${accountContext.id}/beneficiary/${beneficiary.id}/`,
      "Une erreur est survenue lors de la modification du bénéficiaire",
      "Le bénéficiaire a bien été modifié.",
      JSON.stringify(beneficiaryForm)
    ).then((response) => {
      if (response) {
        setLoading(false);
        setBeneficiaryModuleOpen(false);
        fetchBeneficiaryList();
      }
    });
  };

  const createProject = async (projectForm) => {
    setLoading(true);
    await privateFetch(
      "POST",
      `/cu/${accountContext.id}/beneficiary/${beneficiary.id}/project/`,
      "Une erreur est survenue lors de la création du projet.",
      "Le projet a bien été ajoutée.",
      JSON.stringify(projectForm)
    ).then((response) => {
      if (response) {
        setLoading(false);
        setProjectModuleOpen(false);
        fetchBeneficiaryList();
        setScrollPosition("RIGHT");
      }
    });
  };

  const updateProject = async (projectForm) => {
    setLoading(true);
    await privateFetch(
      "PUT",
      `/cu/${accountContext.id}/beneficiary/${beneficiary.id}/project/${project.id}/`,
      "Une erreur est survenue lors de la modification du projet.",
      "Le projet a bien été modifiée.",
      JSON.stringify(projectForm)
    ).then((response) => {
      if (response) {
        setLoading(false);
        setProjectModuleOpen(false);
        fetchBeneficiaryList();
      }
    });
  };

  const deleteProject = async () => {
    setLoading(true);
    await privateFetch(
      "DELETE",
      `/cu/${accountContext.id}/beneficiary/${beneficiary.id}/project/${project.id}/`,
      "Une erreur est survenue lors de la suppression de la project.",
      "La project a bien été supprimée."
    ).then((response) => {
      if (response) {
        setLoading(false);
        setProjectModuleOpen(false);
        fetchBeneficiaryList();
      }
    });
  };

  const downloadCsvExport = () => {
    exportRef.current.exportCSV();
  };

  const uploadDocument = async (documentForm) => {
    setLoading(true);
    documentForm.append("linked_scope", getActiveScope().id);
    await privateFetch(
      "POST",
      `/cu/${accountContext.id}/beneficiary/${beneficiary.id}/project/${project.id}/document/`,
      "Une erreur est survenue lors de l'ajout de document.",
      "Le document a bien été ajoutée.",
      documentForm,
      false,
      true
    ).then((response) => {
      if (response) {
        setLoading(false);
        setDocumentModuleOpen(false);
        fetchBeneficiaryDetail(beneficiary);
      }
    });
  };

  const updateDocument = async (documentForm) => {
    setLoading(true);
    await privateFetch(
      "PUT",
      `/cu/${accountContext.id}/document/${document.id}/`,
      "Une erreur est survenue lors de la modification du document.",
      "Le document a bien été modifié.",
      documentForm,
      false,
      true
    ).then((response) => {
      if (response) {
        setLoading(false);
        setDocumentModuleOpen(false);
        fetchBeneficiaryDetail(beneficiary);
      }
    });
  };

  const deleteDocument = async () => {
    setLoading(true);
    await privateFetch(
      "DELETE",
      `/cu/${accountContext.id}/document/${document.id}/`,
      "Une erreur est survenue lors de la suppression du document.",
      "Le document a bien été supprimé."
    ).then((response) => {
      if (response) {
        setLoading(false);
        setDocumentModuleOpen(false);
        fetchBeneficiaryDetail(beneficiary);
      }
    });
  };

  const confirmDocumentDeletion = () => {
    setDocumentModuleOpen(false);
    confirmDialog({
      message: `Êtes-vous sûr de vouloir supprimer le document ${document.name} ?`,
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      acceptLabel: "Oui",
      rejectLabel: "Annuler",
      accept: () => deleteDocument(),
    });
  };

  const uploadBeneficiaryImage = async (imageForm) => {
    setLoading(true);
    await privateFetch(
      "POST",
      `/cu/${accountContext.id}/beneficiary/${beneficiary.id}/image/`,
      "Une erreur est survenue lors de l'ajout d'image.",
      "L'image a bien été ajoutée au bénéficiaire.",
      imageForm,
      false,
      true
    ).then((response) => {
      if (response) {
        setLoading(false);
        setImageModuleOpen(false);
        fetchBeneficiaryDetail(beneficiary);
      }
    });
  };

  const uploadProjectImage = async (imageForm) => {
    setLoading(true);
    await privateFetch(
      "POST",
      `/cu/${accountContext.id}/beneficiary/${beneficiary.id}/project/${project.id}/image/`,
      "Une erreur est survenue lors de l'ajout d'image.",
      "L'image a bien été ajoutée au projet.",
      imageForm,
      false,
      true
    ).then((response) => {
      if (response) {
        setLoading(false);
        setImageModuleOpen(false);
        fetchBeneficiaryDetail(beneficiary);
      }
    });
  };

  const updateImage = async (imageForm) => {
    setLoading(true);
    imageForm.append("linked_scope", getActiveScope().id);
    await privateFetch(
      "PUT",
      `/cu/${accountContext.id}/image/${image.id}/`,
      "Une erreur est survenue lors de la modification de l'image.",
      "L'image a bien été modifié.",
      imageForm,
      false,
      true
    ).then((response) => {
      if (response) {
        setLoading(false);
        setImageModuleOpen(false);
        fetchBeneficiaryDetail(beneficiary);
      }
    });
  };

  const deleteImage = async () => {
    setLoading(true);
    await privateFetch(
      "DELETE",
      `/cu/${accountContext.id}/image/${image.id}/`,
      "Une erreur est survenue lors de la suppression de l'image.",
      "L'image a bien été supprimée."
    ).then((response) => {
      if (response) {
        setLoading(false);
        setImage(undefined);
        setImageModuleOpen(false);
        fetchBeneficiaryDetail(beneficiary);
      }
    });
  };

  const openDownloadedDocument = async (response) => {
    const blob = await response.blob();
    try {
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl, "_blank");
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className="page-wrapper" hidden={hidden}>
      {/* Header */}
      <div className="header-container">
        {/* Breadcrumb */}
        <div className="breadcrumb-container">
          <TitlePage
            text={"Projets"}
            isActive={scrollPosition === "LEFT"}
            scrollTarget={"LEFT"}
            allowNavigation={true}
            handleNavigation={() => setScrollPosition("LEFT")}
          />
          {beneficiary && <img src={MEDIAS.rightArrow} className="arrow" />}
          {beneficiary && (
            <TitlePage
              text={`${beneficiary.name} ${beneficiary.first_name}`}
              allowNavigation={true}
              handleNavigation={() => setScrollPosition("CENTER")}
              isActive={scrollPosition === "CENTER"}
              scrollTarget={"CENTER"}
            />
          )}
          {project && <img src={MEDIAS.rightArrow} className="arrow" />}
          {project && (
            <TitlePage
              text={`${project.reference}`}
              allowNavigation={true}
              handleNavigation={() => setScrollPosition("RIGHT")}
              isActive={scrollPosition === "RIGHT"}
              scrollTarget={"RIGHT"}
            />
          )}
        </div>
        {/* Buttons */}
        <div className="buttons-container">
          {scrollPosition === "LEFT" && (
            <Button
              key={"exportCsv"}
              text={"Export CSV"}
              bgColor={COLORS.greyButton}
              styleText={"light"}
              icon={MEDIAS.download}
              onClick={downloadCsvExport}
            />
          )}
          {scrollPosition === "LEFT" && isReadWrite() && (
            <Button
              key={"new-beneficiary"}
              text={"Nouveau bénéficiaire"}
              bgColor={COLORS.white}
              styleText={"dark"}
              icon={MEDIAS.addUserBlack}
              onClick={() => {
                setBeneficiary(undefined);
                setBeneficiaryModuleOpen(true);
              }}
            />
          )}
          {scrollPosition === "CENTER" && isReadWrite() && (
            <Button
              text={"Modifier"}
              bgColor={COLORS.white}
              styleText={"dark"}
              icon={MEDIAS.editorBlack}
              onClick={() => setBeneficiaryModuleOpen(true)}
            />
          )}
          {!project && scrollPosition === "CENTER" && isReadWrite() && (
            <Button
              text={"Projet"}
              bgColor={COLORS.greenButton}
              styleText={"light"}
              icon={MEDIAS.crossAdd}
              onClick={() => setProjectModuleOpen(true)}
            />
          )}

          {scrollPosition === "CENTER" && isReadWrite() && (
            <Button
              text={"Image"}
              bgColor={COLORS.greenButton}
              styleText={"light"}
              icon={MEDIAS.crossAdd}
              onClick={() => {
                setImage(undefined);
                setImageModuleOpen(true);
              }}
            />
          )}
          {scrollPosition === "RIGHT" && isReadWrite() && (
            <Button
              text={"Modifier"}
              bgColor={COLORS.white}
              styleText={"dark"}
              icon={MEDIAS.editorBlack}
              onClick={() => setProjectModuleOpen(true)}
            />
          )}
          {scrollPosition === "RIGHT" && isReadWrite() && (
            <Button
              text={"Document"}
              bgColor={COLORS.greenButton}
              styleText={"light"}
              icon={MEDIAS.crossAdd}
              onClick={() => {
                setDocument(undefined);
                setDocumentModuleOpen(true);
              }}
            />
          )}
          {scrollPosition === "RIGHT" && isReadWrite() && (
            <Button
              text={"Cartographie"}
              bgColor={COLORS.greenButton}
              styleText={"light"}
              icon={MEDIAS.crossAdd}
              onClick={() => {
                setImage(undefined);
                setImageModuleOpen(true);
              }}
            />
          )}
        </div>
      </div>
      {/* Content */}
      <div className="content-container hidden-scrollbar">
        <div
          ref={datatablelRef}
          className="section-container full-page-datatable"
        >
          <BeneficiaryDatatable
            loading={datatableLoading}
            exportRef={exportRef}
            data={beneficiaryList}
            selectItem={handleBeneficiarySelection}
          />
        </div>
        <div ref={beneficiaryDetailRef} className="section-container">
          {beneficiary && (
            <BeneficiaryDetail
              beneficiary={beneficiary}
              isReadWrite={isReadWrite()}
              openImageModal={(image) => {
                setImage(image);
                setImageModuleOpen(true);
              }}
              onProjectClick={() => {
                setScrollPosition("RIGHT");
              }}
            />
          )}
        </div>
        <div ref={projectDetailRef} className="section-container">
          {project && (
            <ProjectDetail
              project={project}
              isReadWrite={isReadWrite()}
              openDocumentModal={(document) => {
                setDocument(document);
                setDocumentModuleOpen(true);
              }}
              openImageModal={(image) => {
                setImage(image);
                setImageModuleOpen(true);
              }}
            />
          )}
        </div>
      </div>

      {/* Modules */}
      <ManageBeneficiary
        loading={loading}
        productionTypeList={productionTypeList}
        beneficiary={beneficiary}
        isOpen={beneficiaryModuleOpen}
        handleClose={() => setBeneficiaryModuleOpen(false)}
        handleCreation={createBeneficiary}
        handleUpdate={updateBeneficiary}
      />

      <ManageDocument
        loading={loading}
        document={document}
        isOpen={documentModuleOpen}
        isReadWrite={isReadWrite()}
        handleClose={() => setDocumentModuleOpen(false)}
        uploadDocument={uploadDocument}
        updateDocument={updateDocument}
        deleteDocument={confirmDocumentDeletion}
      />

      <ManageProject
        loading={loading}
        project={project}
        isOpen={projectModuleOpen}
        handleClose={() => {
          setProjectModuleOpen(false);
        }}
        handleCreation={createProject}
        handleUpdate={updateProject}
      />

      <ManageImage
        image={image}
        loading={loading}
        isOpen={imageModuleOpen}
        onClose={() => {
          setImage(null);
          setImageModuleOpen(false);
        }}
        onCreate={
          scrollPosition === "CENTER"
            ? uploadBeneficiaryImage
            : uploadProjectImage
        }
        onUpdate={updateImage}
        onDelete={deleteImage}
      />
    </div>
  );
}

export default ProjectPage;
