import React, { useState } from "react";
import { Sidebar } from "primereact/sidebar";
import TitlePage from "../../components/TitlePage";
import { Button } from "primereact/button";
import { validateEmail } from "../../utils/stringHelper";
import { InputTextarea } from "primereact/inputtextarea";
import FormInput from "../../components/Form/FormInput";
import { InputText } from "primereact/inputtext";
import "./ManageItem.scss";

function ManageSignUp({ isOpen, handleClose, handleSignUp, loading }) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [company, setCompany] = useState("");
  const [message, setMessage] = useState("");

  const onValidate = () => {
    handleSignUp({
      name,
      email,
      phone,
      company,
      message,
    });
  };

  const emailIsValid = validateEmail(email);
  const formIsValid = emailIsValid && name.length;

  const footerButtons = (
    <Button
      key={"validate"}
      label={`Valider la demande`}
      onClick={onValidate}
      severity="success"
      loading={loading}
      disabled={!formIsValid}
    />
  );

  return (
    <Sidebar
      style={{ width: 600, paddingLeft: 10, paddingRight: 10 }}
      header={<TitlePage text={`Demande de création de compte`} />}
      visible={isOpen}
      maskStyle={{ backgroundColor: "#00000080" }}
      onHide={() => handleClose()}
      position="right"
    >
      <div className="sidebar-content-container">
        <div className="form-wrapper">
          <FormInput label={"Prénom & nom"} required isValid={name.length}>
            <InputText
              id="name"
              value={name}
              placeholder="Veuillez saisir votre prénom et nom"
              onChange={(e) => setName(e.target.value)}
            />
          </FormInput>
          <FormInput label={"Email"} required isValid={emailIsValid}>
            <InputText
              id="email"
              value={email}
              placeholder="Veuillez saisir votre email"
              onChange={(e) => setEmail(e.target.value)}
            />
          </FormInput>
          <FormInput label={"Téléphone"}>
            <InputText
              id="phone"
              value={phone}
              placeholder="Veuillez saisir votre numéro de téléphone"
              onChange={(e) => setPhone(e.target.value)}
            />
          </FormInput>
          <FormInput label={"Société"}>
            <InputText
              id="company"
              value={company}
              placeholder="Veuillez saisir le nom de votre société"
              onChange={(e) => setCompany(e.target.value)}
            />
          </FormInput>

          <FormInput label={"Commentaire"}>
            <InputTextarea
              rows={3}
              autoResize
              value={message}
              placeholder="Quelque chose à ajouter ?"
              onChange={(e) => setMessage(e.target.value)}
            />
          </FormInput>
        </div>
        {footerButtons}
      </div>
    </Sidebar>
  );
}

export default ManageSignUp;
