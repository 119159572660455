import React from "react";
import { MEDIAS } from "../ressources/MEDIAS";
import { CONSTANTS } from "../ressources/CONSTANTS";

function DocumentItem({ document, isMissing, onClick }) {
  const documentNameOrState = () => {
    if (!isMissing && document.type === "other")
      return (
        <span className="text-sm font-medium text-gray-500">
          {document.name}
        </span>
      );
    else
      return (
        <div
          className={`flex flex-row gap-x-2 items-center font-medium text-sm ${isMissing ? "text-custom-red" : document.validated ? "text-custom-green-dark" : "text-custom-orange"}`}
        >
          <i
            className={`pi ${isMissing ? " pi-times-circle" : document.validated ? "pi-verified" : "pi-clock"}`}
          />
          <span>
            {isMissing
              ? "Fichier manquant"
              : document.validated
                ? "Fichier validé"
                : "En attente de validation"}
          </span>
        </div>
      );
  };

  return (
    <div
      className="flex flex-row gap-x-3 items-center rounded border-2 bg-white min-w-64 p-2 pr-5 hover:shadow cursor-pointer"
      onClick={onClick}
    >
      <img
        src={MEDIAS.document}
        className={`h-10 w-10 ${isMissing ? "opacity-30" : "opacity-60"}`}
      />
      <div className="flex flex-col gap-y-1">
        <p className="font-medium text-sm text-gray-700">
          {CONSTANTS.DOCUMENTS.find((d) => d.type === document.type)?.label}
        </p>
        {documentNameOrState()}
      </div>
    </div>
  );
}

export default DocumentItem;
