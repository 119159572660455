import React, { useEffect, useState, useRef } from "react";
import { COLORS } from "../../../ressources/STYLING";
import { MEDIAS } from "../../../ressources/MEDIAS";
import { privateFetch } from "../../../utils/apiHelper";
import TitlePage from "../../../components/TitlePage";
import Button from "../../../components/Button";
import { useAccountContext } from "../../../contexts/AccountContext";
import { useAuthorizationsContext } from "../../../contexts/AuthorizationsContext";
import "../../Pages.scss";
import { formatDate } from "../../../utils/dateHelper";
import { TransactionDatatable } from "../../../modules/transaction/TransactionDatatable";
import moment from "moment";
import { TransactionDetail } from "../../../modules/transaction/TransactionDetail";

function ArchivePage() {
  const exportRef = useRef(null);
  const datatablelRef = useRef(null);
  const detailRef = useRef(null);
  const { authorizationsContext, getActiveScopesNames } =
    useAuthorizationsContext();
  const { accountContext } = useAccountContext();

  const [transactionList, setTransactionList] = useState([]);
  const [transaction, setTransaction] = useState(null);
  const [scrollPosition, setScrollPosition] = useState("LEFT");

  useEffect(() => {
    fetchTransactionList();
    transaction && fetchTransactionDetail(transaction);
  }, [authorizationsContext]);

  useEffect(() => {
    if (scrollPosition === "CENTER" && transaction) {
      detailRef.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "end",
      });
    } else {
      datatablelRef.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "end",
      });
    }
  }, [scrollPosition]);

  const fetchTransactionList = async () => {
    await privateFetch(
      "GET",
      `/ia/${accountContext.id}/transaction/?scopes=${getActiveScopesNames()}`
    ).then((res) => {
      if (res) setTransactionList(res);
    });
  };

  const fetchTransactionDetail = async (transaction) => {
    await privateFetch(
      "GET",
      `/ia/${accountContext.id}/transaction/${
        transaction.id
      }/?scopes=${getActiveScopesNames()}`
    ).then((res) => {
      if (res) {
        setTransaction(res);
      } else {
        setScrollPosition("LEFT");
        setTransaction(undefined);
      }
    });
  };

  const selectTransaction = (transaction) => {
    fetchTransactionDetail(transaction).then(() => setScrollPosition("CENTER"));
  };

  const downloadCsvExport = () => {
    exportRef.current.exportCSV();
  };

  return (
    <div className="page-wrapper">
      {/* Header */}
      <div className="header-container">
        <div className="breadcrumb-container">
          <TitlePage
            text={"Historique des transactions"}
            isActive={scrollPosition === "LEFT"}
            scrollTarget={"LEFT"}
            allowNavigation={true}
            handleNavigation={() => setScrollPosition("LEFT")}
          />
          {transaction && <img src={MEDIAS.rightArrow} className="arrow" />}
          {transaction && (
            <TitlePage
              text={`${transaction.transaction_type.ref} - ${formatDate(transaction.execution_date)}`}
              allowNavigation={true}
              handleNavigation={() => setScrollPosition("CENTER")}
              isActive={scrollPosition === "CENTER"}
              scrollTarget={"CENTER"}
            />
          )}
        </div>
        <div className="buttons-container">
          {scrollPosition === "LEFT" && (
            <Button
              key={"exportCsv"}
              text={"Export CSV"}
              bgColor={COLORS.greyButton}
              styleText={"light"}
              icon={MEDIAS.download}
              onClick={downloadCsvExport}
            />
          )}
        </div>
      </div>
      {/* Content */}
      <div className="content-container hidden-scrollbar">
        <div
          ref={datatablelRef}
          className="section-container full-page-datatable"
        >
          <TransactionDatatable
            exportRef={exportRef}
            exportFileName={`export_transactions_${moment().format("DD/MM/YYYY")}`}
            data={transactionList}
            selectTransaction={selectTransaction}
            showInvestorName
          />
        </div>
        <div ref={detailRef} className="section-container">
          {transaction && <TransactionDetail transaction={transaction} />}
        </div>
      </div>
    </div>
  );
}

export default ArchivePage;
