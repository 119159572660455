import React, { useState } from "react";
import moment from "moment";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { FilterMatchMode } from "primereact/api";
import { useAuthorizationsContext } from "../../contexts/AuthorizationsContext";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import {
  applyCurrencyFormat,
  applyDateFormat,
} from "../../utils/datatableHelper";
import Tag from "../../components/Tag";
import { useAccountContext } from "../../contexts/AccountContext";

export const InvestorDatatable = ({ loading, data, selectItem, exportRef }) => {
  const { isOverviewMode, getScopeFromId } = useAuthorizationsContext();
  const { isIntermediate } = useAccountContext();

  const [filters, setFilters] = useState(null);

  const intermediateBodyTemplate = (rowData) => {
    if (!rowData.intermediate) return "";
    return <Tag value={rowData.intermediate.name} />;
  };

  const scopesBodyTemplate = (rowData) => {
    return (
      <div className="flex flex-nowrap">
        {rowData.linked_scopes.map((lsId) => (
          <Tag
            key={lsId}
            value={getScopeFromId(lsId).label}
            bgColor={getScopeFromId(lsId).main_color}
          />
        ))}
      </div>
    );
  };

  const stateBodyTemplate = (rowData) => {
    return (
      <Tag
        value={rowData.is_active ? "Actif" : "Inactif"}
        bgColor={rowData.is_active && "bg-custom-green"}
      />
    );
  };

  const footerGroup = (
    <ColumnGroup>
      <Row>
        <Column footer="Totaux" frozen />
        <Column footer={`${data.total_investor} investisseurs`} frozen />
        <Column footer={`(${data.total_active_investor} actifs)`} />
        <Column />
        <Column />
        <Column footer={`${applyCurrencyFormat(data.total_balance)}`} />
        <Column footer={`${applyCurrencyFormat(data.total_ps_balance)}`} />
        <Column footer={`${data.total_ps_share_balance}`} />
        <Column footer={`${applyCurrencyFormat(data.total_tp_balance)}`} />
        <Column footer={`${data.total_tp_share_balance}`} />
        <Column footer={`${applyCurrencyFormat(data.total_cc_balance)}`} />
        <Column />
        <Column />
        <Column />
        <Column />
      </Row>
    </ColumnGroup>
  );

  return (
    <DataTable
      ref={exportRef}
      value={data.investor_list}
      emptyMessage={"Pas d'investisseur à afficher"}
      removableSort
      scrollable={true}
      loading={loading}
      paginator
      rows={25}
      rowsPerPageOptions={[25, 50, 100]}
      filters={filters}
      onFilter={(e) => setFilters(e.filters)}
      filterDisplay="row"
      selectionMode="single"
      onRowSelect={(e) => selectItem(e.data)}
      exportFilename={`export_investisseurs_${moment().format("DD/MM/YYYY")}`}
      csvSeparator=";"
      footerColumnGroup={loading ? null : footerGroup}
    >
      <Column
        sortable
        className="font-bold"
        field="name"
        header="Nom"
        frozen
        filter
        filterMatchMode={FilterMatchMode.CONTAINS}
        showFilterMenu={false}
      ></Column>
      <Column
        sortable
        className="font-bold"
        field="first_name"
        frozen
        header="Prénom"
        filter
        filterMatchMode={FilterMatchMode.CONTAINS}
        showFilterMenu={false}
      ></Column>
      <Column field="state" header="État" body={stateBodyTemplate}></Column>
      <Column sortable field="college" header="Collège"></Column>
      <Column sortable field="profile" header="Profil"></Column>
      {/* Hidden columns, for CSV export*/}
      <Column hidden field="gender" header="Civilité"></Column>
      <Column hidden field="city" header="Ville"></Column>
      <Column hidden field="zip_code" header="Code postal"></Column>
      <Column hidden field="address" header="Adresse"></Column>
      <Column hidden field="email" header="Email"></Column>
      <Column hidden field="phone" header="Téléphone"></Column>
      <Column hidden field="birth_place" header="Lieu de naissance"></Column>
      <Column hidden field="birth_dt" header="Date de naissance"></Column>
      {/* End of Hidden columns*/}
      <Column
        dataType="numeric"
        sortable
        field="balance"
        header="Solde Total"
        body={(rowData) => applyCurrencyFormat(rowData.balance)}
      ></Column>
      <Column
        dataType="numeric"
        sortable
        field="ps_balance"
        header="Solde PS"
        body={(rowData) => applyCurrencyFormat(rowData.ps_balance)}
      ></Column>
      <Column
        dataType="numeric"
        sortable
        field="ps_share_balance"
        header="Nb PS"
      ></Column>
      <Column
        dataType="numeric"
        sortable
        field="tp_balance"
        header="Solde TP"
        body={(rowData) => applyCurrencyFormat(rowData.tp_balance)}
      ></Column>
      <Column
        dataType="numeric"
        sortable
        field="tp_share_balance"
        header="Nb TP"
      ></Column>
      <Column
        dataType="numeric"
        sortable
        field="cc_balance"
        header="Solde CC"
        body={(rowData) => applyCurrencyFormat(rowData.cc_balance)}
      ></Column>
      <Column
        sortable
        field="intermediate.name"
        header="Intermédiaire"
        body={intermediateBodyTemplate}
        filter
        filterMatchMode={FilterMatchMode.CONTAINS}
        showFilterMenu={false}
      ></Column>
      <Column
        sortable
        body={(rowData) => applyDateFormat(rowData.first_investment_dt)}
        field="first_investment_dt"
        header="Premier invest."
        dataType="date"
      ></Column>
      <Column
        sortable
        body={(rowData) => applyDateFormat(rowData.last_investment_dt)}
        field={"last_investment_dt"}
        header="Dernier invest."
        dataType="date"
      ></Column>
      {isOverviewMode() && (
        <Column
          field="linked_scopes"
          header="Périmètre(s)"
          body={scopesBodyTemplate}
        ></Column>
      )}
      <Column sortable field="id" header="ID"></Column>
    </DataTable>
  );
};
