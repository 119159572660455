import React from "react";
import { Tooltip } from "primereact/tooltip";
import { COLORS } from "../../ressources/STYLING";

function FormInput({
  label,
  required,
  isValid,
  annotation,
  children,
  labelClassName,
}) {
  return (
    <div className="flex flex-col gap-y-2">
      <div className="flex flex-row w-100 justify-between items-center">
        <div className="row-gap">
          <label
            className={`font-medium text-gray-500 text-sm ${labelClassName}`}
          >
            {label}
          </label>
          {required && (
            <span
              data-pr-tooltip="Champs obligatoire"
              className={`pi custom-label ${
                isValid ? "pi-check-circle" : "pi-info-circle"
              }`}
              style={{
                color: isValid ? COLORS.greenModal : COLORS.orangeButton,
              }}
            ></span>
          )}
        </div>
        {annotation && (
          <span className="text-sm text-gray-500 font-medium">
            {annotation}
          </span>
        )}
      </div>
      {children}
      <Tooltip target=".custom-label" />
    </div>
  );
}

export default FormInput;
