import React from "react";
import ReactDOM from "react-dom";

import AppRouter from "./Router";
import { PrimeReactProvider, addLocale } from "primereact/api";
import { HashRouter } from "react-router-dom";
import { ConfirmDialog } from "primereact/confirmdialog";

import AppContexts from "./contexts/Contexts";
import { locale } from "primereact/api";

import "primeicons/primeicons.css";
import "./App.scss";

import "./assets/css/index.css";
import "./assets/css/dashboard.css";
import "./assets/theme/theme.css";
import { localeJson } from "./assets/theme/fr";

addLocale("fr", localeJson.fr);
locale("fr");

function App() {
  return (
    <React.Fragment>
      <React.Fragment>
        <PrimeReactProvider>
          <AppContexts>
            <ConfirmDialog />
            <HashRouter>
              <AppRouter />
            </HashRouter>
          </AppContexts>
        </PrimeReactProvider>
      </React.Fragment>
    </React.Fragment>
  );
}

export default App;
