import React, { useState } from "react";
import {
  applyAreaFormat,
  applyCurrencyFormat,
  applyHectareFormatFromArea,
  applyPercentage,
} from "../utils/datatableHelper";
import { formatDate } from "../utils/dateHelper";

import "./Data.scss";

function Data({ label, value, large, bgColor, type, multiline }) {
  const [copied, setCopied] = useState(false);

  const handleCopy = async () => {
    setCopied(true);
    // Copy to clipboard
    await navigator.clipboard.writeText(value);
    setTimeout(() => {
      setCopied(false);
    }, 2500);
  };

  const formatValue = () => {
    if (isEmpty) return ` - `;
    switch (type) {
      case "date":
        return formatDate(value);
      case "currency":
        return applyCurrencyFormat(value);
      case "area":
        return applyAreaFormat(value);
      case "hectare":
        return applyHectareFormatFromArea(value);
      case "percentage":
        return applyPercentage(value);
      default:
        return value;
    }
  };

  const isEmpty = value === undefined || value === null || value === "";

  return (
    <div
      onClick={handleCopy}
      className={`flex flex-col gap-y-1 cursor-pointer data-container ${
        multiline ? "w-auto" : large ? "w-[340px]" : "w-40"
      }`}
    >
      <div className="w-full flex flex-row justify-between items-center">
        <span className="text-slate-500 text-sm">{label}</span>
        <div
          className={`flex flex-row ${copied ? "text-green-500" : "text-slate-300"}`}
        >
          <i
            className={`pi pi-copy copy-icon ${copied ? "opacity-1" : "opacity-0"}`}
          ></i>
        </div>
      </div>
      <div
        className={`relative ${!isEmpty ? "" : "text-slate-400 italic font-extralight"}`}
      >
        <p
          className={`${multiline ? "whitespace-normal" : "overflow-hidden whitespace-nowrap text-ellipsis"} text-lg`}
        >
          {!isEmpty ? formatValue() : " - "}
        </p>
        {!multiline && (
          <p
            className={`absolute top-0 left-0 bg-white absolute-value-display whitespace-nowrap pr-2 z-20 data-content text-lg`}
          >
            {!isEmpty ? formatValue() : " - "}
          </p>
        )}
      </div>
    </div>
  );
}

export default Data;
