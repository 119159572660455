import React, { useState } from "react";
import moment from "moment";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { FilterMatchMode } from "primereact/api";
import { applyDateFormat } from "../../utils/datatableHelper";
import { useAuthorizationsContext } from "../../contexts/AuthorizationsContext";
import Tag from "../../components/Tag";

function IntermediateDatatable({ data, selectItem, exportRef }) {
  const { isOverviewMode, getScopeFromId } = useAuthorizationsContext();
  const [filters, setFilters] = useState(null);

  const percentageTemplate = (rowData) => {
    return `${rowData.max_fee_rate * 100}%`;
  };

  const scopesBodyTemplate = (rowData) => {
    return (
      <div className="cell-container row-gap-xs">
        {rowData.linked_scopes.map((ls) => (
          <Tag
            key={ls.id}
            value={getScopeFromId(ls.id).label}
            bgColor={getScopeFromId(ls.id).main_color}
          />
        ))}
      </div>
    );
  };

  const accountsBodyTemplate = (accounts) => {
    if (!accounts.length) return <Tag value={"Aucun"} />;
    return (
      <Tag
        value={`${accounts.length} compte${accounts.length > 1 ? "s" : ""}`}
        bgColor={"bg-custom-green"}
      />
    );
  };

  return (
    <DataTable
      ref={exportRef}
      emptyMessage={"Pas d'intermédiaire à afficher"}
      value={data}
      removableSort
      scrollable={true}
      paginator
      rows={25}
      rowsPerPageOptions={[25, 50, 100]}
      filters={filters}
      onFilter={(e) => setFilters(e.filters)}
      filterDisplay="row"
      selectionMode="single"
      onRowSelect={(e) => selectItem(e.data)}
      exportFilename={`export_intermediaire_${moment().format("DD/MM/YYYY")}`}
      csvSeparator=";"
    >
      <Column
        sortable
        field="name"
        header="Nom"
        className="font-bold"
        filter
        filterMatchMode={FilterMatchMode.CONTAINS}
        showFilterMenu={false}
      ></Column>
      <Column sortable field="investor_nb" header="Nb d'investisseurs"></Column>
      <Column
        sortable
        field="accounts"
        header="Nb de comptes"
        body={(rowData) => accountsBodyTemplate(rowData.accounts)}
      ></Column>
      <Column
        sortable
        field="max_fee_rate"
        header="Taux max."
        body={percentageTemplate}
        dataType="numeric"
      ></Column>
      <Column
        sortable
        field="creation_date"
        header="Date de création"
        body={(rowData) => applyDateFormat(rowData.creation_date)}
        dataType="date"
      ></Column>
      {isOverviewMode() && (
        <Column
          field="linked_scopes"
          header="Périmètre(s)"
          body={scopesBodyTemplate}
        ></Column>
      )}
      <Column sortable field="id" header="ID"></Column>
    </DataTable>
  );
}

export default IntermediateDatatable;
