import React from "react";
import moment from "moment";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import { applyCurrencyFormat } from "../../utils/datatableHelper";
import { CONSTANTS } from "../../ressources/CONSTANTS";

export const DashboardTransactionDatatable = ({ loading, data }) => {
  const footerGroup = (
    <ColumnGroup>
      <Row>
        <Column footer="Totaux" />
        <Column footer={`${data.total_count} transactions`} />
        <Column footer={`${applyCurrencyFormat(data.total_balance)}`} />
        <Column footer={`${applyCurrencyFormat(data.total_cc_balance)}`} />
        <Column footer={`${applyCurrencyFormat(data.total_ps_balance)}`} />
        <Column footer={`${applyCurrencyFormat(data.total_tp_balance)}`} />
      </Row>
    </ColumnGroup>
  );

  return (
    <DataTable
      value={data.data}
      emptyMessage={"Pas de transaction à afficher"}
      loading={loading}
      exportFilename={`export_dashboard_${moment().format("DD/MM/YYYY")}`}
      csvSeparator=";"
      footerColumnGroup={loading ? null : footerGroup}
    >
      <Column className="font-bold" field="year" header="Année"></Column>
      <Column field="count" header="Nb transactions"></Column>
      <Column
        dataType="numeric"
        field="balance"
        header={`${CONSTANTS.DASHBOARD[data.type].columnLabel}`}
        body={(rowData) => applyCurrencyFormat(rowData.balance)}
      ></Column>
      <Column
        dataType="numeric"
        field="cc_balance"
        header={`${CONSTANTS.DASHBOARD[data.type].columnLabel} CC`}
        body={(rowData) => applyCurrencyFormat(rowData.cc_balance)}
      ></Column>
      <Column
        dataType="numeric"
        field="ps_balance"
        header={`${CONSTANTS.DASHBOARD[data.type].columnLabel} PS`}
        body={(rowData) => applyCurrencyFormat(rowData.ps_balance)}
      ></Column>
      <Column
        dataType="numeric"
        field="tp_balance"
        header={`${CONSTANTS.DASHBOARD[data.type].columnLabel} TP`}
        body={(rowData) => applyCurrencyFormat(rowData.tp_balance)}
      ></Column>
    </DataTable>
  );
};
