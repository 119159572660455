import React from "react";

function MobileCatcher() {
  return (
    <div className="h-screen w-screen flex flex-col justify-center items-center gap-y-5 text-slate-50 bg-slate-800 text-center p-5">
      <span className="text-3xl font-medium">
        Cet outil web ne supporte encore l'utilisation depuis un téléhpone
        portable.
      </span>
      <span className="text-xl">
        Merci de vous connecter depuis un ordinateur.
      </span>
    </div>
  );
}

export default MobileCatcher;
