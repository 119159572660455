import moment from "moment";

export const generateYearList = (startYear) => {
  let currentYear = moment().year();
  let yearList = [];
  for (let i = startYear; i <= currentYear; i++) {
    yearList.unshift(i);
  }
  return yearList;
};

export const getDateOrUndefined = (dateString) => {
  return dateString ? new Date(dateString) : undefined;
};

export const getDateStringOrUndefined = (date) => {
  return date ? moment(date).format("YYYY-MM-DD") : undefined;
};

export const formatDate = (date) => {
  return date ? moment(date).format("DD/MM/YYYY") : "";
};
