import React, { useState, useEffect } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { useNavigate, useParams } from "react-router-dom";
import "./SignIn.scss";
import { MEDIAS } from "../../ressources/MEDIAS";
import { CONSTANTS } from "../../ressources/CONSTANTS";
import { publicFetch } from "../../utils/apiHelper";
import FormInput from "../../components/Form/FormInput";
import { ProgressSpinner } from "primereact/progressspinner";
import { Dialog } from "primereact/dialog";
import { validateEmail } from "../../utils/stringHelper";

function SetPassword() {
  const [account, setAccount] = useState(undefined);
  const [password, setPassword] = useState("");
  const [passwordRepeat, setPasswordRepeat] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { token } = useParams();

  useEffect(() => {
    if (token) {
      console.log(token);
      fetchAccountDetail(token);
    } else {
      console.log("Error: token not found");
    }
  }, []);

  const fetchAccountDetail = async (token) => {
    await publicFetch("GET", `/user/${token}/`).then((res) => {
      setAccount(res);
    });
  };

  const handleSetPassword = async () => {
    setLoading(true);
    await publicFetch(
      "PUT",
      `/user/${token}/`,
      null,
      JSON.stringify({
        password: password,
      })
    ).then((response) => {
      if (!response) {
        setLoading(false);
      } else {
        setDialogOpen(true);
        setTimeout(() => {
          navigate("/");
        }, 5000);
      }
    });
  };

  return (
    <div className="h-screen w-screen bg-slate-700 text-slate-50 relative">
      <img
        src={MEDIAS.signinImage}
        className="absolute object-contain top-0 right-0 h-full"
      />

      <div className="h-full flex flex-col justify-between p-5">
        <div className="flex flex-row gap-5 items-center">
          <img
            src={MEDIAS.generalLogo}
            alt="Logo de Crescendo Imvest"
            className="h-12 w-12"
          />
          <span className=" text-2xl font-semibold">Crescendo Imvest</span>
        </div>

        <div className="flex flex-col gap-y-8 ml-36 w-[500px] mb-8">
          <span className="text-5xl font-semibold mb-8">
            Mot de passe oublié
          </span>
          <FormInput
            label={"Adresse mail"}
            required
            isValid={validateEmail(account?.email)}
            labelClassName={"text-slate-200"}
          >
            <InputText
              id="email"
              type="text"
              placeholder="Entrez votre adresse email"
              className="w-full mb-3"
              value={account?.email}
              disabled
              readOnly
            />
          </FormInput>

          <FormInput
            label={"Mot de passe"}
            required
            isValid={password.length > 7}
          >
            <InputText
              id="password"
              type="password"
              placeholder="Entrez votre mot de passe"
              className="w-full mb-3"
              required
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </FormInput>
          <FormInput
            label={"Répeter le mot de passe"}
            required
            isValid={passwordRepeat.length > 7 && passwordRepeat === password}
          >
            <InputText
              id="passwordRepeat"
              type="password"
              placeholder="Entrez à nouveau votre mot de passe"
              className="w-full mb-3"
              required
              value={passwordRepeat}
              onChange={(e) => setPasswordRepeat(e.target.value)}
            />
          </FormInput>

          <Button
            label="Valider le mot de passe"
            icon="pi pi-user"
            loading={loading}
            disabled={password !== passwordRepeat || password.length < 8}
            onClick={handleSetPassword}
          />
        </div>

        <div className="footer-container">
          <span>{`Version ${CONSTANTS.VERSION}`}</span>
          <span>-</span>
          <span>
            {`Conçu, développé et maintenu par le `}{" "}
            <a href="https://collectifnode.fr" target="_blank">
              Collectif Node
            </a>
          </span>
        </div>
      </div>

      <Dialog
        header="Configuration terminée"
        visible={dialogOpen}
        style={{ width: "30vw" }}
        draggable={false}
        closable={false}
      >
        <div className="dialog-container">
          <div style={{ width: 30, marginRight: 15 }}>
            <ProgressSpinner style={{ width: "30px", height: "30px" }} />
          </div>
          <p>Vous allez être redirigé vers la page de connexion...</p>
        </div>
      </Dialog>
    </div>
  );
}

export default SetPassword;
