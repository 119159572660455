import React, { useState, useEffect } from "react";
import { Sidebar } from "primereact/sidebar";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import FormInput from "../../components/Form/FormInput";

function ManagePassword({ isOpen, handleClose, updatePassword, loading }) {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordRepeat, setNewPasswordRepeat] = useState("");

  useEffect(() => {
    setOldPassword("");
    setNewPassword("");
    setNewPasswordRepeat("");
  }, [isOpen]);

  const onValidate = () => {
    const passwordForm = {
      old_password: oldPassword,
      new_password: newPassword,
    };
    updatePassword(passwordForm);
  };

  const formIsValid =
    newPassword.length > 7 && newPassword === newPasswordRepeat;

  const footerButtons = (
    <Button
      key={"validate"}
      label={`Changer mon mot de passe`}
      loading={loading}
      disabled={!formIsValid}
      onClick={onValidate}
      severity="success"
    />
  );

  return (
    <Sidebar
      style={{ width: 600, paddingLeft: 10, paddingRight: 10 }}
      header={<h2 className="text-slate-800">Changer de mot de passe</h2>}
      visible={isOpen}
      maskStyle={{ backgroundColor: "#00000080" }}
      onHide={() => handleClose()}
      position="right"
    >
      <div className="h-full flex flex-col justify-between pt-3">
        <div className="flex flex-col gap-y-5">
          <FormInput
            key="oldPassword"
            label="Ancien mot de passe"
            required
            isValid={oldPassword.length > 7}
            tooltipMessage={"Au moins 8 caractères"}
          >
            <InputText
              value={oldPassword}
              type="password"
              placeholder="Veuillez saisir le mot de passe actuel"
              onChange={(e) => setOldPassword(e.target.value)}
            />
          </FormInput>
          <FormInput
            key="newPassword"
            label="Nouveau mot de passe"
            required
            isValid={newPassword.length > 7}
            tooltipMessage={"Au moins 8 caractères"}
          >
            <InputText
              value={newPassword}
              type="password"
              placeholder="Veuillez saisir le nouveau mot de passe"
              onChange={(e) => setNewPassword(e.target.value)}
            />
          </FormInput>
          <FormInput
            key="newPasswordRepeat"
            label="Répeter le nouveau mot de passe"
            required
            isValid={
              newPassword.length > 7 && newPasswordRepeat === newPassword
            }
            tooltipMessage={"Au moins 8 caractères"}
          >
            <InputText
              value={newPasswordRepeat}
              type="password"
              placeholder="Veuillez répeter le mot de passe actuel"
              onChange={(e) => setNewPasswordRepeat(e.target.value)}
            />
          </FormInput>
        </div>
        {footerButtons}
      </div>
    </Sidebar>
  );
}

export default ManagePassword;
