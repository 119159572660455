import React from "react";
import ImageItem from "../../components/Image/ImageItem";
import { CONSTANTS } from "../../ressources/CONSTANTS";
import Data from "../../components/Data";
import DataTag from "../../components/DataTag";

export const BeneficiaryDetail = ({
  beneficiary,
  openImageModal,
  isReadWrite,
}) => {
  const displayImages = () => {
    if (beneficiary && beneficiary.photos && beneficiary.photos.length) {
      return (
        <div className="row-gap">
          {beneficiary.photos.map((image) => (
            <ImageItem
              key={image.id}
              image={image}
              onClick={() => isReadWrite && openImageModal(image)}
            />
          ))}
        </div>
      );
    } else
      return <div style={{ fontStyle: "italic" }}>Aucune image disponible</div>;
  };

  return (
    <div className="section-wrapper">
      <section>
        <h4>Informations</h4>
        <div className="data-section">
          <Data
            key="fullName"
            label={"Nom complet"}
            value={beneficiary.first_name + " " + beneficiary.name}
          />
          <Data
            key="project"
            label={"Référence projet"}
            value={beneficiary.project ? beneficiary.project.reference : "-"}
          />
        </div>
      </section>

      <section>
        <h4>Contact</h4>
        <div className="data-section">
          <Data key="email" label={"Email"} value={beneficiary.email} large />
          <Data key="phone" label={"Téléphone"} value={beneficiary.phone} />
          <Data
            key="address"
            label={"Adresse"}
            value={
              beneficiary.address +
              ", " +
              beneficiary.zip_code +
              " " +
              beneficiary.city
            }
            large
          />
        </div>
      </section>

      <section>
        <h4>Exploitation</h4>
        <div className="data-section">
          <Data
            key="sau"
            label={"SAU Totale"}
            value={beneficiary.total_surface}
            type="area"
          />
          <DataTag
            key="qualityApproach"
            label={"Démarche qualité"}
            value={beneficiary.quality_approach}
          />
          <DataTag
            key="productionType"
            label={"Production"}
            value={
              CONSTANTS.AGRICULTURE_TYPES.find(
                (at) => at.value === beneficiary.is_organic
              ).label
            }
          />
          <Data
            key="production"
            label={"Types de production"}
            value={beneficiary.production_types
              .map((pt) => pt.label)
              .join(", ")}
            large
          />
        </div>
      </section>

      <section>
        <h4>Autres informations</h4>
        <div className="data-section">
          <Data
            key="description"
            label={"Notes internes"}
            value={beneficiary.private_description}
            large
            multiline
          />
        </div>
      </section>

      <section>
        <h4>Photos</h4>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginTop: "1vh",
            overflowX: "auto",
            scrollbarWidth: "thin",
          }}
        >
          {displayImages()}
        </div>
      </section>
    </div>
  );
};
