import React from "react";
import DocumentItem from "./DocumentItem";

function DocumentList({
  documents,
  handleExistingDocument,
  requiredDocuments = [],
  handleMissingDocument,
}) {
  const filteredRequiredDocuments = requiredDocuments.filter(
    (rd) => rd.required && !documents.find((d) => d.type === rd.type)
  );
  if (!filteredRequiredDocuments.length && !documents.length)
    return (
      <span className="empty-section-message">
        Aucun document à afficher...
      </span>
    );
  return (
    <div className="flex flex-row flex-wrap gap-x-5 gap-y-5">
      {filteredRequiredDocuments.map((d, i) => (
        <DocumentItem
          key={i}
          document={d}
          isMissing
          onClick={() => handleMissingDocument(d)}
        />
      ))}
      {documents.map((d) => (
        <DocumentItem
          key={d.id}
          document={d}
          onClick={() => handleExistingDocument(d)}
        />
      ))}
    </div>
  );
}

export default DocumentList;
