import React, { useEffect, useState } from "react";
import { DIMENSIONS } from "../../../ressources/STYLING";
import { MEDIAS } from "../../../ressources/MEDIAS";
import { CONSTANTS } from "../../../ressources/CONSTANTS";
import { useNavigate } from "react-router-dom";
import InvestorsPage from "../common/InvestorsPage";
import ToolboxPage from "./ToolboxPage";
import AccountPage from "../common/AccountPage";
import ArchivePage from "./ArchivePage";
import NavIconButton from "../../../components/NavIconButton";
import AppDropdown from "../../../components/AppDropdown";
import { useAuthorizationsContext } from "../../../contexts/AuthorizationsContext";
import { useNavigationContext } from "../../../contexts/NavigationContext";
import { saveSession } from "../../../utils/sessionHelper";

import "../../Home.scss";
import "../../Pages.scss";
import "../../Datatables.scss";
import Tag from "../../../components/Tag";

function IntermediateRouter() {
  const {
    authorizationsContext,
    getAuthorizedScopeList,
    selectScope,
    selectAllScopes,
  } = useAuthorizationsContext();
  const { navigationContext, changeSubapp, isPreproduction } =
    useNavigationContext();
  const navigate = useNavigate();
  const [activeScope, setActiveScope] = useState(null);
  const [inactiveScopes, setInactiveScopes] = useState(null);

  useEffect(() => {
    computeActiveScopes();
  }, [authorizationsContext]);

  const computeActiveScopes = () => {
    const authorizedScopeList = getAuthorizedScopeList();
    // User has more than one scope
    if (authorizedScopeList.length > 1) {
      const generalScope = {
        id: 0,
        label: "Général",
        logo: MEDIAS.generalLogo,
        main_color: "bg-custom-blue",
      };
      const allScopes = [generalScope, ...authorizedScopeList];
      const activeScope = !authorizedScopeList.some((scope) => !scope.isActive)
        ? generalScope
        : authorizedScopeList.find((scope) => scope.isActive);
      setActiveScope(activeScope);
      setInactiveScopes(allScopes.filter((s) => s !== activeScope));
    } else {
      setActiveScope(authorizedScopeList[0]);
      setInactiveScopes(null);
    }
  };

  const handleLogout = () => {
    saveSession(null);
    navigate("/");
  };

  const handleAppSelection = (scope) => {
    // Terrafine, Residens
    if (scope.id) selectScope(scope);
    // General
    else selectAllScopes();
  };

  const unauthorizedDisplay = (
    <div className="app-container">
      <div className="unauthorized-page bg-custom-violet">
        {
          "Votre compte existe mais ne possède pas les autorisations suffisantes pour utiliser cet outil. Merci de contacter les administrateurs."
        }
      </div>
    </div>
  );

  const subappMenu = () => (
    <div className="navbar-container">
      <div className="navbar-menu-container">
        {activeScope && (
          <AppDropdown
            activeScope={activeScope}
            inactiveScopes={inactiveScopes}
            handleAppSelection={handleAppSelection}
          />
        )}
        <div className="navbar-submenu-container">
          <NavIconButton
            key={"investor"}
            index={"investor"}
            text={"Investisseurs"}
            src={MEDIAS.thirdParty}
            onClick={() => changeSubapp("investor")}
            isActive={navigationContext.currentSubapp === "investor"}
          />
          <NavIconButton
            key={"archive"}
            index={"archive"}
            text={"Historique"}
            src={MEDIAS.archive}
            onClick={() => changeSubapp("archive")}
            isActive={navigationContext.currentSubapp === "archive"}
          />
          <NavIconButton
            key={"toolbox"}
            index={"toolbox"}
            text={"Boîte à outils"}
            src={MEDIAS.toolbox}
            onClick={() => changeSubapp("toolbox")}
            isActive={navigationContext.currentSubapp === "toolbox"}
          />
        </div>

        <div className="navbar-submenu-container">
          <div className="navbar-submenu-label">Paramètres</div>
          <NavIconButton
            key={"account"}
            index={"account"}
            text={"Mon compte"}
            src={MEDIAS.account}
            onClick={() => changeSubapp("account")}
            isActive={navigationContext.currentSubapp === "account"}
          />
        </div>
      </div>
    </div>
  );

  const displaySubapp = () => {
    switch (navigationContext.currentSubapp) {
      case "investor":
        return <InvestorsPage />;
      case "toolbox":
        return <ToolboxPage />;
      case "account":
        return <AccountPage />;
      case "archive":
        return <ArchivePage />;
      default:
        return (
          <div className="unauthorized-page">
            <p> {"Veuillez sélectionner un onglet dans le menu à gauche."}</p>
          </div>
        );
    }
  };

  // Account has no authorizations
  if (!getAuthorizedScopeList().length) return unauthorizedDisplay;

  return (
    <div className="app-container">
      <div
        className="navbar-vertical-container"
        style={{
          width: DIMENSIONS.navBarWidth,
        }}
      >
        <div className="navbar-container">
          {subappMenu()}

          <div className="bottom-container">
            {isPreproduction && (
              <Tag value={"Env. démo"} bgColor={"bg-orange-700 mb-2"} />
            )}
            <div
              onClick={handleLogout}
              className="logout"
            >{`Se déconnecter`}</div>
            <div className="version">{`Version ${CONSTANTS.VERSION}`}</div>
          </div>
        </div>
      </div>
      {/* Page content */}
      <div
        style={{
          height: DIMENSIONS.pageHeight,
          width: DIMENSIONS.pageWidth,
        }}
      >
        {displaySubapp()}
      </div>
    </div>
  );
}

export default IntermediateRouter;
