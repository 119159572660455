import React from "react";
import { AccountContextProvider } from "./AccountContext";
import { NavigationContextProvider } from "./NavigationContext";
import { AuthorizationsContextProvider } from "./AuthorizationsContext";
import { ToastContextProvider } from "./ToastContext";

const AppContexts = ({ children }) => (
  <AccountContextProvider>
    <AuthorizationsContextProvider>
      <ToastContextProvider>
        <NavigationContextProvider>{children}</NavigationContextProvider>
      </ToastContextProvider>
    </AuthorizationsContextProvider>
  </AccountContextProvider>
);

export default AppContexts;
