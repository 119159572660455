import React from "react";
import { DashboardTransactionDatatable } from "./DashboardTransactionDatatable";
import { DashboardProjectDatatable } from "./DashboardProjectDatatable";

function DashboardDetail({ indicatorData }) {
  return (
    <div className="indicator-datatable-container">
      <h4>{indicatorData ? indicatorData.label : ""}</h4>
      {indicatorData && (
        <div style={{ marginTop: 20, height: 550 }}>
          {indicatorData.type === "PROJECT" ? (
            <DashboardProjectDatatable data={indicatorData} />
          ) : (
            <DashboardTransactionDatatable data={indicatorData} />
          )}
        </div>
      )}
    </div>
  );
}

export default DashboardDetail;
