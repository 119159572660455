import React, { useState, useEffect, useRef } from "react";
import { COLORS } from "../../../ressources/STYLING";
import { MEDIAS } from "../../../ressources/MEDIAS";
import { privateFetch } from "../../../utils/apiHelper";
import TitlePage from "../../../components/TitlePage";
import Button from "../../../components/Button";
import { InvestorDatatable } from "../../../modules/investor/InvestorDatatable";
import { InvestorDetail } from "../../../modules/investor/InvestorDetail";
import { useAuthorizationsContext } from "../../../contexts/AuthorizationsContext";
import { useAccountContext } from "../../../contexts/AccountContext";
import { confirmDialog } from "primereact/confirmdialog";
import ManageInvestor from "../../../modules/managers/ManageInvestor";
import ManageDocument from "../../../modules/managers/ManageDocument";
import ManageTransaction from "../../../modules/managers/ManageTransaction";
import ManageReport from "../../../modules/managers/ManageReport";
import { formatDate, generateYearList } from "../../../utils/dateHelper";
import { Dialog } from "primereact/dialog";
import { ProgressSpinner } from "primereact/progressspinner";
import "../../Pages.scss";
import { TransactionDetail } from "../../../modules/transaction/TransactionDetail";
import { CONSTANTS } from "../../../ressources/CONSTANTS";

function InvestorsPage({ hidden }) {
  const exportRef = useRef(null);
  const datatablelRef = useRef(null);
  const investorDetailRef = useRef(null);
  const transactionDetailRef = useRef(null);

  const {
    authorizationsContext,
    getActiveScopesNames,
    getActiveScope,
    isOverviewMode,
    getSubappAuthorizationType,
  } = useAuthorizationsContext();

  const { accountContext, isIntermediate } = useAccountContext();
  const [investorList, setInvestorList] = useState([]);
  const [investor, setInvestor] = useState(null);
  const [investorModuleOpen, setInvestorModuleOpen] = useState(false);
  const [transaction, setTransaction] = useState(null);
  const [transactionModuleOpen, setTransactionModuleOpen] = useState(false);
  const [reportModuleOpen, setReportModuleOpen] = useState(false);
  const [document, setDocument] = useState(null);
  const [documentType, setDocumentType] = useState(undefined);
  const [documentModuleOpen, setDocumentModuleOpen] = useState(false);
  const [transactionDocument, setTransactionDocument] = useState(null);
  const [transactionDocumentModuleOpen, setTransactionDocumentModuleOpen] =
    useState(false);
  const [transactionDocumentType, setTransactionDocumentType] =
    useState(undefined);
  const [loading, setLoading] = useState(false);
  const [datatableLoading, setDatatableLoading] = useState(true);
  const [documentGenerationLoading, setDocumentGenerationLoading] =
    useState(false);
  const [scrollPosition, setScrollPosition] = useState("LEFT");

  useEffect(() => {
    fetchInvestorList();
    investor && fetchInvestorDetail(investor);
  }, [authorizationsContext]);

  useEffect(() => {
    if (!investor) {
      setTransaction(undefined);
    } else if (transaction) {
      setTransaction(
        investor.transactions.find((t) => t.id === transaction.id)
      );
    }
  }, [investor]);

  useEffect(() => {
    if (scrollPosition === "CENTER" && investor) {
      investorDetailRef.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "end",
      });
    } else if (scrollPosition === "RIGHT") {
      transactionDetailRef.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "end",
      });
    } else {
      datatablelRef.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "end",
      });
    }
  }, [scrollPosition]);

  const isReadWrite = () => {
    if (isOverviewMode()) return false;
    return (
      getSubappAuthorizationType(getActiveScope().name, "INVESTOR") ===
      "READ_WRITE"
    );
  };

  const isTransactionReadWrite = () => {
    /* TODO : switch blocks
    if (
      isOverviewMode() ||
      ["validated", "refused"].includes(transaction.status)
    )
      return false;
    */
    if (isOverviewMode()) return false;
    return (
      getSubappAuthorizationType(getActiveScope().name, "INVESTOR") ===
      "READ_WRITE"
    );
  };

  const isDocumentReadWrite = () => {
    if (document && isIntermediate() && document.validated) return false;
    // If User has READ_WRITE auth for at least one scope
    return authorizationsContext.some(
      (s) => getSubappAuthorizationType(s.name, "INVESTOR") === "READ_WRITE"
    );
  };

  const isTransactionDocumentReadWrite = () => {
    if (isOverviewMode()) return false;
    if (
      transactionDocument &&
      isIntermediate() &&
      transactionDocument.validated &&
      transactionDocumentType
    )
      return false;

    return (
      getSubappAuthorizationType(getActiveScope().name, "INVESTOR") ===
      "READ_WRITE"
    );
  };

  const handleInvestorSelection = async (targetInvestor) => {
    if (!investor || targetInvestor.id !== investor.id) {
      await fetchInvestorDetail(targetInvestor);
    }
    setScrollPosition("CENTER");
  };

  const fetchInvestorList = async () => {
    await privateFetch(
      "GET",
      `/cu/${accountContext.id}/investor?scopes=${getActiveScopesNames()}`
    ).then((res) => {
      if (res) setInvestorList(res);
      setDatatableLoading(false);
    });
  };

  const fetchInvestorDetail = async (targetInvestor) => {
    await privateFetch(
      "GET",
      `/cu/${accountContext.id}/investor/${
        targetInvestor.id
      }/?scopes=${getActiveScopesNames()}`,
      `Cet investisseur n'est pas lié à l'app sélectionnée.`
    ).then((res) => {
      if (res) {
        setInvestor(res);
      } else {
        setScrollPosition("LEFT");
        setInvestor(undefined);
      }
    });
  };

  const createInvestor = async (investorForm) => {
    setLoading(true);
    await privateFetch(
      "POST",
      `/cu/${accountContext.id}/investor/`,
      "Une erreur est survenue lors de la création de l'investisseur",
      "L'investisseur a bien été créé.",
      JSON.stringify({
        ...investorForm,
        linked_scopes: [getActiveScope().id],
      })
    ).then((response) => {
      setLoading(false);
      setInvestorModuleOpen(false);
      fetchInvestorList();
      if (response) {
        fetchInvestorDetail(response).then(() => setScrollPosition("CENTER"));
      }
    });
  };

  const updateInvestor = async (investorForm) => {
    setLoading(true);
    await privateFetch(
      "PUT",
      `/cu/${accountContext.id}/investor/${
        investor.id
      }/?scopes=${getActiveScopesNames()}`,
      "Une erreur est survenue lors de la modification de l'investisseur",
      "L'investisseur a bien été modifié.",
      JSON.stringify(investorForm)
    ).then(() => {
      setLoading(false);
      setInvestorModuleOpen(false);
      fetchInvestorList();
      fetchInvestorDetail(investor);
    });
  };

  const updateInvestorScope = async (investor) => {
    setLoading(true);
    await privateFetch(
      "PUT",
      `/cu/${accountContext.id}/investor/${investor.id}/scope/?scope=${
        getActiveScope().name
      }`,
      "Une erreur est survenue lors de la modification de l'investisseur",
      "L'investisseur a bien été modifié.",
      JSON.stringify({
        ...investor,
        linked_scopes: [
          ...investor.linked_scopes.map((ls) => ls.id),
          getActiveScope().id,
        ],
      })
    ).then(() => {
      setLoading(false);
      setInvestorModuleOpen(false);
      fetchInvestorList();
      fetchInvestorDetail(investor).then(() => setScrollPosition("CENTER"));
    });
  };

  const createTransaction = async (transactionForm) => {
    setLoading(true);
    await privateFetch(
      "POST",
      `/cu/${accountContext.id}/investor/${investor.id}/transaction/`,
      "Une erreur est survenue lors de la création de la transaction.",
      "La transaction a bien été ajoutée.",
      JSON.stringify({
        ...transactionForm,
        linked_scope: getActiveScope().id,
      })
    ).then(() => {
      setLoading(false);
      setTransactionModuleOpen(false);
      fetchInvestorList();
      fetchInvestorDetail(investor);
    });
  };

  const updateTransaction = async (transactionForm) => {
    setLoading(true);
    await privateFetch(
      "PUT",
      `/cu/${accountContext.id}/investor/${investor.id}/transaction/${transaction.id}/`,
      "Une erreur est survenue lors de la modification de la transaction.",
      "La transaction a bien été modifiée.",
      JSON.stringify(transactionForm)
    ).then(() => {
      setLoading(false);
      setTransactionModuleOpen(false);
      fetchInvestorList();
      fetchInvestorDetail(investor);
    });
  };

  const handleTransactionSelection = (transaction) => {
    setTransaction(transaction);
    setScrollPosition("RIGHT");
  };

  const downloadCsvExport = () => {
    exportRef.current.exportCSV();
  };

  const uploadDocument = async (documentForm) => {
    setLoading(true);
    await privateFetch(
      "POST",
      `/cu/${accountContext.id}/investor/${investor.id}/document/`,
      "Une erreur est survenue lors de l'ajout de document.",
      "Le document a bien été ajoutée.",
      documentForm,
      false,
      true
    ).then(() => {
      setLoading(false);
      setDocumentModuleOpen(false);
      fetchInvestorDetail(investor);
    });
  };

  const uploadTransactionDocument = async (documentForm) => {
    setLoading(true);
    await privateFetch(
      "POST",
      `/cu/${accountContext.id}/investor/${investor.id}/transaction/${transaction.id}/document/`,
      "Une erreur est survenue lors de l'ajout de document.",
      "Le document a bien été ajoutée.",
      documentForm,
      false,
      true
    ).then(() => {
      setLoading(false);
      setTransactionDocumentModuleOpen(false);
      fetchInvestorDetail(investor);
    });
  };

  const updateDocument = async (document, documentForm) => {
    setLoading(true);
    await privateFetch(
      "PUT",
      `/cu/${accountContext.id}/document/${document.id}/`,
      "Une erreur est survenue lors de la modification du document.",
      "Le document a bien été modifié.",
      documentForm,
      false,
      true
    ).then(() => {
      setLoading(false);
      setDocument(undefined);
      setDocumentModuleOpen(false);
      setTransactionDocumentModuleOpen(false);
      fetchInvestorDetail(investor);
    });
  };

  const deleteDocument = async (document) => {
    setLoading(true);
    await privateFetch(
      "DELETE",
      `/cu/${accountContext.id}/document/${document.id}/`,
      "Une erreur est survenue lors de la suppression du document.",
      "Le document a bien été supprimé."
    ).then(() => {
      setLoading(false);
      setDocument(undefined);
      setDocumentModuleOpen(false);
      setTransactionDocumentModuleOpen(false);
      fetchInvestorDetail(investor);
    });
  };

  const openDownloadedDocument = async (response) => {
    const blob = await response.blob();
    try {
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl, "_blank");
    } catch (e) {
      console.log(e);
    }
  };

  const generateDocument = async (type, investor, yearList, toDownload) => {
    return await privateFetch(
      "POST",
      `/cu/${accountContext.id}/investor/${investor.id}/${type}/?scope=${
        getActiveScope().name
      }`,
      `Une erreur est survenue lors de la génération du document pour l'investisseur ${investor.name}`,
      null,
      JSON.stringify({
        year_list: yearList,
        to_download: toDownload,
      }),
      true
    ).then((response) => {
      if (toDownload) {
        openDownloadedDocument(response);
      }
      return response;
    });
  };

  const handleDocumentGeneration = async (payload) => {
    setDocumentGenerationLoading(true);
    setReportModuleOpen(false);
    let success = true;
    const yearList = payload.selectedYear
      ? [payload.selectedYear]
      : generateYearList(2016);
    if (payload.selectedInvestor) {
      const response = await generateDocument(
        payload.type,
        payload.selectedInvestor,
        yearList,
        payload.toDownload
      );
      if (!response) success = false;
    } else {
      await Promise.all(
        investorList.investor_list.map(async (investor) => {
          const response = await generateDocument(
            payload.type,
            investor,
            yearList,
            payload.toDownload
          );
          if (!response) success = false;
        })
      );
    }
    window.PrimeToast.show({
      severity: "success",
      summary: "L'opération s'est bien déroulée !",
    });
    setTimeout(() => {
      setDocumentGenerationLoading(false);
    }, 500);
  };

  const confirmDocumentDeletion = (document) => {
    setDocumentModuleOpen(false);
    confirmDialog({
      message: `Êtes-vous sûr de vouloir supprimer le document ${document.name} ?`,
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      acceptLabel: "Oui",
      rejectLabel: "Annuler",
      accept: () => deleteDocument(document),
    });
  };

  return (
    <div className="page-wrapper" hidden={hidden}>
      {/* Header */}
      <div className="header-container">
        {/* Breadcrumb */}
        <div className="breadcrumb-container">
          <TitlePage
            text={"Investisseurs"}
            isActive={scrollPosition === "LEFT"}
            scrollTarget={"LEFT"}
            allowNavigation={true}
            handleNavigation={() => setScrollPosition("LEFT")}
          />
          {investor && <img src={MEDIAS.rightArrow} className="arrow" />}
          {investor && (
            <TitlePage
              text={`${investor.name} ${investor.first_name}`}
              allowNavigation={true}
              handleNavigation={() => setScrollPosition("CENTER")}
              isActive={scrollPosition === "CENTER"}
              scrollTarget={"CENTER"}
            />
          )}
          {transaction && <img src={MEDIAS.rightArrow} className="arrow" />}
          {transaction && (
            <TitlePage
              text={`${transaction.transaction_type.ref} - ${formatDate(transaction.execution_date)} (${transaction.id})`}
              allowNavigation={true}
              handleNavigation={() => setScrollPosition("RIGHT")}
              isActive={scrollPosition === "RIGHT"}
              scrollTarget={"RIGHT"}
            />
          )}
        </div>
        {/* Buttons */}
        <div className="buttons-container">
          {scrollPosition !== "RIGHT" && isReadWrite() && (
            <Button
              key={"sendReport"}
              text={"Générer"}
              bgColor={COLORS.greyButton}
              styleText={"light"}
              icon={MEDIAS.news}
              onClick={() => setReportModuleOpen(true)}
            />
          )}
          {scrollPosition === "LEFT" && (
            <Button
              key={"exportCsv"}
              text={"Export CSV"}
              bgColor={COLORS.greyButton}
              styleText={"light"}
              icon={MEDIAS.download}
              onClick={downloadCsvExport}
            />
          )}
          {scrollPosition === "LEFT" && isReadWrite() && (
            <Button
              key={"new-investor"}
              text={"Investisseur"}
              bgColor={COLORS.white}
              styleText={"dark"}
              icon={MEDIAS.addUserBlack}
              onClick={() => {
                setInvestor(undefined);
                setInvestorModuleOpen(true);
              }}
            />
          )}
          {scrollPosition === "CENTER" && isReadWrite() && (
            <Button
              text={"Modifier"}
              bgColor={COLORS.white}
              styleText={"dark"}
              icon={MEDIAS.editorBlack}
              onClick={() => setInvestorModuleOpen(true)}
            />
          )}
          {scrollPosition === "CENTER" && isReadWrite() && (
            <Button
              text={"Transaction"}
              bgColor={COLORS.greenButton}
              styleText={"light"}
              icon={MEDIAS.crossAdd}
              onClick={() => {
                setTransaction(undefined);
                setTransactionModuleOpen(true);
              }}
            />
          )}
          {scrollPosition === "CENTER" && isDocumentReadWrite() && (
            <Button
              text={"Document"}
              bgColor={COLORS.greenButton}
              styleText={"light"}
              icon={MEDIAS.crossAdd}
              onClick={() => {
                setDocument(undefined);
                setDocumentModuleOpen(true);
                setDocumentType(undefined);
              }}
            />
          )}
          {scrollPosition === "RIGHT" && isTransactionReadWrite() && (
            <Button
              text={"Modifier"}
              bgColor={COLORS.white}
              styleText={"dark"}
              icon={MEDIAS.editorBlack}
              onClick={() => setTransactionModuleOpen(true)}
            />
          )}
          {scrollPosition === "RIGHT" && isReadWrite() && (
            <Button
              text={"Document"}
              bgColor={COLORS.greenButton}
              styleText={"light"}
              icon={MEDIAS.crossAdd}
              onClick={() => {
                setTransactionDocument(undefined);
                setTransactionDocumentModuleOpen(true);
                setTransactionDocumentType(undefined);
              }}
            />
          )}
        </div>
      </div>
      {/* Content */}
      <div className="content-container hidden-scrollbar">
        <div
          ref={datatablelRef}
          className="section-container full-page-datatable"
        >
          <InvestorDatatable
            loading={datatableLoading}
            exportRef={exportRef}
            data={investorList}
            selectItem={handleInvestorSelection}
          />
        </div>
        <div ref={investorDetailRef} className="section-container">
          {investor && (
            <InvestorDetail
              investor={investor}
              openDocumentModal={(document, mandatoryType) => {
                setDocument(document);
                setDocumentModuleOpen(true);
                setDocumentType(mandatoryType);
              }}
              selectTransaction={handleTransactionSelection}
            />
          )}
        </div>
        <div ref={transactionDetailRef} className="section-container">
          {transaction && (
            <TransactionDetail
              transaction={transaction}
              openDocumentModal={(document, mandatoryType) => {
                setTransactionDocument(document);
                setTransactionDocumentType(mandatoryType);
                setTransactionDocumentModuleOpen(true);
              }}
            />
          )}
        </div>
      </div>

      {/* Modules */}
      <ManageInvestor
        loading={loading}
        investor={investor}
        isOpen={investorModuleOpen}
        handleClose={() => setInvestorModuleOpen(false)}
        handleCreation={createInvestor}
        handleUpdate={updateInvestor}
        handleScopeUpdate={updateInvestorScope}
      />

      <ManageDocument
        key={"investor"}
        loading={loading}
        document={document}
        options={CONSTANTS.INVESTOR_DOCUMENTS.filter(
          (document) => !document.required
        )}
        mandatoryType={documentType}
        isOpen={documentModuleOpen}
        handleClose={() => {
          setDocumentModuleOpen(false);
          setDocument(undefined);
        }}
        uploadDocument={uploadDocument}
        updateDocument={(documentForm) =>
          updateDocument(document, documentForm)
        }
        deleteDocument={() => confirmDocumentDeletion(document)}
        isReadWrite={isDocumentReadWrite()}
      />

      <ManageDocument
        key={"transaction"}
        loading={loading}
        document={transactionDocument}
        options={
          transaction
            ? CONSTANTS.TRANSACTION_DOCUMENTS[transaction.transaction_type.type]
            : undefined
        }
        mandatoryType={transactionDocumentType || "other"}
        isOpen={transactionDocumentModuleOpen}
        handleClose={() => {
          setTransactionDocumentModuleOpen(false);
          setTransactionDocument(undefined);
        }}
        uploadDocument={uploadTransactionDocument}
        updateDocument={(documentForm) =>
          updateDocument(transactionDocument, documentForm)
        }
        deleteDocument={() => confirmDocumentDeletion(transactionDocument)}
        isReadWrite={isTransactionDocumentReadWrite()}
      />

      <ManageTransaction
        loading={loading}
        investor={investor}
        transaction={transaction}
        isOpen={transactionModuleOpen}
        handleClose={() => {
          setTransactionModuleOpen(false);
        }}
        handleCreation={createTransaction}
        handleUpdate={updateTransaction}
      />

      <ManageReport
        loading={loading}
        investor={investor}
        investorList={investorList ? investorList.investor_list : []}
        isOpen={reportModuleOpen}
        handleClose={() => setReportModuleOpen(false)}
        handleValidation={(payload) => {
          handleDocumentGeneration(payload);
        }}
      />

      <Dialog
        header="Génération des documents en cours..."
        visible={documentGenerationLoading}
        style={{ width: "30vw" }}
        draggable={false}
        closable={false}
      >
        <div className="dialog-container">
          <div style={{ width: 30, marginRight: 15 }}>
            <ProgressSpinner style={{ width: "30px", height: "30px" }} />
          </div>
          <p>Merci de ne pas fermer la fenêtre</p>
        </div>
      </Dialog>
    </div>
  );
}

export default InvestorsPage;
