import React from "react";
import { IntermediateAccountDatatable } from "./IntermediateAccountDatatable";
import Data from "../../components/Data";
import DataMultipleTags from "../../components/DataMultipleTags";
import { IntermediateTransactionDatatable } from "./IntermediateTransactionDatatable";
import DocumentList from "../../components/DocumentList";
import { CONSTANTS } from "../../ressources/CONSTANTS";

function IntermediateDetail({
  intermediate,
  selectIntermediateAccount,
  openDocumentModal,
}) {
  const handleExistingDocument = (d) => {
    openDocumentModal(d);
  };

  const handleMissingDocument = (d) => {
    openDocumentModal(undefined, d.type);
  };

  const scopeTags = intermediate.linked_scopes.map((ls) => ({
    value: ls.label,
    bgColor: ls.main_color,
  }));

  return (
    <div className="section-wrapper">
      <section>
        <h4>Informations</h4>
        <div className="data-section">
          <Data key="name" label={"Nom"} value={intermediate.name} large />
          <Data
            key="maxFeeRate"
            label={"Taux max."}
            value={intermediate.max_fee_rate}
            type={"percentage"}
          />
          <DataMultipleTags label={"Périmètres"} tags={scopeTags} large />
          <Data key="id" label={"ID"} value={intermediate.id} large />
        </div>
      </section>

      <section>
        <h4>Contact</h4>
        <div className="data-section">
          <Data key="email" label={"Email"} value={intermediate.email} large />
          <Data key="phone" label={"Téléphone"} value={intermediate.phone} />
          <Data
            key="address"
            label={"Adresse"}
            value={intermediate.address}
            large
          />
        </div>
      </section>

      <section>
        <h4>Comptes associés</h4>
        <IntermediateAccountDatatable
          data={intermediate.accounts}
          selectItem={selectIntermediateAccount}
        />
      </section>

      <section>
        <h4>Transactions associées</h4>
        <IntermediateTransactionDatatable data={intermediate.transactions} />
      </section>

      <section>
        <h4>Documents</h4>
        <DocumentList
          handleMissingDocument={handleMissingDocument}
          handleExistingDocument={handleExistingDocument}
          requiredDocuments={CONSTANTS.INTERMEDIATE_DOCUMENTS}
          documents={intermediate.documents}
        />
      </section>
    </div>
  );
}

export default IntermediateDetail;
