import React from "react";
import { MEDIAS } from "../../ressources/MEDIAS";
import { useAuthorizationsContext } from "../../contexts/AuthorizationsContext";
import { CONSTANTS } from "../../ressources/CONSTANTS";
import Data from "../../components/Data";
import DataTag from "../../components/DataTag";
import DocumentList from "../../components/DocumentList";

export const TransactionDetail = ({ transaction, openDocumentModal }) => {
  const { getScopeFromId } = useAuthorizationsContext();

  const handleExistingDocument = (d) => {
    openDocumentModal(d, d.type);
  };

  const handleMissingDocument = (d) => {
    openDocumentModal(undefined, d.type);
  };

  return (
    <div className="flex flex-col p-5 gap-y-8">
      <section>
        <h4>Informations</h4>
        <div className="flex flex-row gap-x-5">
          <Data
            label={"Investisseur"}
            value={`${transaction.investor.first_name} ${transaction.investor.name}`}
            large
          />
          <DataTag
            label={"Périmètre"}
            value={getScopeFromId(transaction.linked_scope).label}
            bgColor={getScopeFromId(transaction.linked_scope).main_color}
          />
          <DataTag
            label={"Statut"}
            value={
              CONSTANTS.TRANSACTION_STATUS.find(
                (ts) => ts.value === transaction.status
              ).label
            }
            bgColor={
              CONSTANTS.TRANSACTION_STATUS.find(
                (ts) => ts.value === transaction.status
              ).color
            }
          />
          <DataTag
            label={"Nature"}
            value={
              CONSTANTS.TRANSACTION_NATURE_TYPES.find(
                (nt) => nt.value === transaction.nature
              ).label
            }
            bgColor={
              CONSTANTS.TRANSACTION_NATURE_TYPES.find(
                (nt) => nt.value === transaction.nature
              ).color
            }
          />
          <DataTag
            label={"Type"}
            value={transaction.transaction_type.label}
            large
          />
          <Data
            label={"Date d'éxecution"}
            value={transaction.execution_date}
            type={"date"}
          />
          <Data label={"ID"} value={transaction.id} />
        </div>
      </section>

      <section>
        <h4>Montants</h4>
        <div className="flex flex-row gap-x-5">
          <Data
            label={"Montant"}
            value={transaction.amount}
            type={"currency"}
          />
          <Data label={"Nb de parts"} value={transaction.share_nb} />
          <Data
            label={"Frais (en %)"}
            value={transaction.fee_rate}
            type={"percentage"}
          />
          <Data
            label={"Frais (en €)"}
            value={
              transaction.fee_rate !== null
                ? transaction.amount * transaction.fee_rate
                : undefined
            }
            type={"currency"}
          />
        </div>
      </section>

      <section>
        <h4>Intermediaire</h4>

        <div className="flex flex-row gap-x-5">
          <DataTag
            label={"Intermédiaire"}
            value={transaction.intermediate?.name || ""}
            large
          />
          <DataTag
            icon={MEDIAS.userWhite}
            label={"Compte intermédiaire"}
            value={transaction.intermediate_account?.alias || ""}
            large
          />
        </div>
      </section>

      <section>
        <h4>Documents</h4>
        <DocumentList
          handleMissingDocument={handleMissingDocument}
          handleExistingDocument={handleExistingDocument}
          requiredDocuments={
            CONSTANTS.TRANSACTION_DOCUMENTS[transaction.transaction_type.type]
          }
          documents={transaction.documents}
        />
      </section>
    </div>
  );
};
