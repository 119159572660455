import React, { useState, useEffect, useRef } from "react";
import { Sidebar } from "primereact/sidebar";
import { InputText } from "primereact/inputtext";
import TitlePage from "../../components/TitlePage";
import "./ManageItem.scss";
import { CONSTANTS } from "../../ressources/CONSTANTS";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import FormInput from "../../components/Form/FormInput";
import { InputNumber } from "primereact/inputnumber";
import { InputTextarea } from "primereact/inputtextarea";
import { RadioButton } from "primereact/radiobutton";
import { AutoComplete } from "primereact/autocomplete";
import slugify from "react-slugify";

function ManageBeneficiary({
  isOpen,
  handleClose,
  handleCreation,
  handleUpdate,
  loading,
  beneficiary,
  productionTypeList,
}) {
  const autocompleteRef = useRef(null);

  // Mandatory
  const [name, setName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [region, setRegion] = useState(CONSTANTS.REGIONS[0]);

  // Optionnal
  const [city, setCity] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [address, setAddress] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [qualityApproach, setQualityApproach] = useState(undefined);
  const [totalSurface, setTotalSurface] = useState(undefined);
  const [internalNotes, setInternalNotes] = useState("");
  const [productionTypes, setProductionTypes] = useState([]);
  const [isOrganic, setIsOrganic] = useState(false);

  const [suggestions, setSuggestions] = useState([]);

  useEffect(() => {
    if (beneficiary) {
      setName(beneficiary.name);
      setFirstName(beneficiary.first_name);
      setRegion(beneficiary.region);
      setCity(beneficiary.city);
      setZipCode(beneficiary.zip_code);
      setAddress(beneficiary.address);
      setEmail(beneficiary.email);
      setPhone(beneficiary.phone);
      setQualityApproach(beneficiary.quality_approach);
      setTotalSurface(beneficiary.total_surface);
      setInternalNotes(beneficiary.private_description);
      setProductionTypes(beneficiary.production_types.map((pt) => pt.label));
      setIsOrganic(beneficiary.is_organic);
    } else {
      setName("");
      setFirstName("");
      setRegion(CONSTANTS.REGIONS[0]);
      setCity("");
      setZipCode("");
      setAddress("");
      setEmail("");
      setPhone("");
      setQualityApproach(undefined);
      setTotalSurface(undefined);
      setInternalNotes("");
      setProductionTypes([]);
      setIsOrganic(false);
    }
  }, [beneficiary]);

  const handleAutocomplete = (e) => {
    if (e.code === "Enter") {
      const input = autocompleteRef.current.getInput();
      if (input.value.length && !suggestions.length) {
        setProductionTypes([...productionTypes, input.value]);
        input.value = "";
        setSuggestions(productionTypeList.map((pt) => pt.label));
        autocompleteRef.current.hide();
      }
    }
  };

  const handleSearch = (event) => {
    if (!event.query) setSuggestions(productionTypeList.map((pt) => pt.label));
    else {
      const filteredProductionList = productionTypeList.filter((pt) =>
        slugify(pt.label).includes(slugify(event.query))
      );
      setSuggestions(filteredProductionList.map((pt) => pt.label));
    }
  };

  const onValidate = () => {
    const beneficiaryForm = {
      name,
      first_name: firstName,
      region,
      city,
      zip_code: zipCode,
      address,
      email,
      phone,
      quality_approach: qualityApproach,
      total_surface: totalSurface,
      private_description: internalNotes,
      production_types: productionTypes,
      is_organic: isOrganic,
    };
    beneficiary
      ? handleUpdate(beneficiaryForm)
      : handleCreation(beneficiaryForm);
  };

  const footerButtons = (
    <div className="manage-footer">
      <Button
        key={"validate"}
        label={`Valider ${beneficiary ? "la modification" : "l'ajout"}`}
        loading={loading}
        disabled={!(name.length > 2 && firstName.length > 2)}
        onClick={onValidate}
        severity="success"
      />
    </div>
  );
  return (
    <Sidebar
      style={{ width: 600, paddingLeft: 10, paddingRight: 10 }}
      header={
        <TitlePage
          text={`${
            beneficiary ? `${name} ${firstName}` : "Ajouter un bénéficiaire"
          }`}
        />
      }
      visible={isOpen}
      maskStyle={{ backgroundColor: "#00000080" }}
      onHide={() => handleClose()}
      position="right"
    >
      <div className="sidebar-content-container">
        <div className="form-wrapper">
          <FormInput label={"Nom"} required isValid={name.length > 2}>
            <InputText
              value={name}
              placeholder="Veuillez saisir le nom du bénéficiaire"
              onChange={(e) => setName(e.target.value)}
            />
          </FormInput>
          <FormInput label={"Prénom"} required isValid={firstName.length > 2}>
            <InputText
              value={firstName}
              placeholder="Veuillez saisir le prénom du bénéficiaire"
              onChange={(e) => setFirstName(e.target.value)}
            />
          </FormInput>
          <FormInput label={"Région"} required isValid={region}>
            <Dropdown
              value={region}
              onChange={(e) => setRegion(e.value)}
              options={CONSTANTS.REGIONS}
              placeholder="Veuillez sélectionner une région"
            />
          </FormInput>

          <FormInput label={"Agriculture"} required isValid>
            <div className="flex gap-x-5">
              {CONSTANTS.AGRICULTURE_TYPES.map((pt, i) => (
                <div key={i} className="flex gap-x-2">
                  <RadioButton
                    inputId={pt.label}
                    name={pt.label}
                    value={pt.value}
                    onChange={(e) => setIsOrganic(e.target.value)}
                    checked={isOrganic === pt.value}
                  />
                  <label htmlFor={pt.label}>{pt.label}</label>
                </div>
              ))}
            </div>
          </FormInput>

          <FormInput label={"Adresse"}>
            <InputText
              value={address}
              placeholder="Veuillez saisir l'adresse"
              onChange={(e) => setAddress(e.target.value)}
            />
          </FormInput>

          <FormInput label={"Ville"}>
            <InputText
              value={city}
              placeholder="Veuillez saisir la ville"
              onChange={(e) => setCity(e.target.value)}
            />
          </FormInput>

          <FormInput label={"Code postal"}>
            <InputText
              value={zipCode}
              placeholder="Veuillez saisir le code postal"
              onChange={(e) => setZipCode(e.target.value)}
            />
          </FormInput>

          <FormInput label={"Adresse email"}>
            <InputText
              value={email}
              placeholder="Veuillez saisir l'adresse email"
              onChange={(e) => setEmail(e.target.value)}
            />
          </FormInput>

          <FormInput label={"N° de téléphone"}>
            <InputText
              value={phone}
              placeholder="Veuillez saisir le numéro"
              onChange={(e) => setPhone(e.target.value)}
            />
          </FormInput>

          <FormInput label={"SAU Totale en m²"}>
            <InputNumber
              value={totalSurface}
              placeholder="Veuillez saisir la surface totale"
              onChange={(e) => setTotalSurface(e.value)}
              min={0}
              suffix=" m²"
              locale="fr-FR"
            />
          </FormInput>
          <FormInput label={"Types de production"}>
            <AutoComplete
              ref={autocompleteRef}
              value={productionTypes}
              suggestions={suggestions}
              inputClassName="autocomplete-input"
              emptyMessage='Appuyez sur la touche "Entrer" pour créer un nouveau type de production'
              showEmptyMessage
              completeMethod={handleSearch}
              onChange={(e) => setProductionTypes(e.value)}
              onKeyPress={(e) => handleAutocomplete(e)}
              placeholder="Veuillez renseigner des types de production"
              multiple
              dropdown
            />
          </FormInput>

          <FormInput label={"Démarche qualité"}>
            <Dropdown
              value={qualityApproach}
              onChange={(e) => setQualityApproach(e.value)}
              options={CONSTANTS.QUALITY_APPROACH_TYPES}
              placeholder="Veuillez sélectionner une démarche qualité"
              showClear
            />
          </FormInput>

          <FormInput label={"Description privée"}>
            <InputTextarea
              rows={3}
              autoResize
              value={internalNotes}
              onChange={(e) => setInternalNotes(e.target.value)}
            />
          </FormInput>
        </div>

        {footerButtons}
      </div>
    </Sidebar>
  );
}

export default ManageBeneficiary;
