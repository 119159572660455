import React from "react";
import "./Data.scss";
import Tag from "./Tag";

function DataTag({ label, value, bgColor, onClick, large, icon }) {
  const emptyValue = (
    <p className="text-slate-400 italic font-extralight">{" - "}</p>
  );

  return (
    <div
      className={`flex flex-col gap-y-1 data-container ${large ? "w-[340px]" : "w-40"}`}
    >
      <span className="text-gray-500 text-sm">{label}</span>
      <div className="flex">
        {value ? (
          <Tag value={value} bgColor={bgColor} onClick={onClick} icon={icon} />
        ) : (
          emptyValue
        )}
      </div>
    </div>
  );
}

export default DataTag;
